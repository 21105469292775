import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { compareAsc, subMinutes } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import api from '../services/api';
import { decrypt, encodeBase64, encrypt } from '../utils/crypto';

const AuthContext = createContext({});

interface AuthProviderInterface {
  children: ReactNode;
}


export function AuthProvider({ children }: AuthProviderInterface) {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@SnxPayment:token');

    if (token) {
      return {
        token,
        user: undefined,
      };
    }

    return {};
  });

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  const clearLocalStorage = useCallback(() => {
    localStorage.clear();
    clearCacheData();

    setData({});
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const token = localStorage.getItem('@SnxPayment:token');
      if (token) {
        const jwt = token.split('.');
        if (jwt.length === 3) {
          const jwtPayload = token.split('.')[1];
          const payload = JSON.parse(atob(jwtPayload));
          const expirationTime = subMinutes(new Date(payload.exp * 1000), 5);
          if (compareAsc(expirationTime, new Date()) === -1) {
            console.info('Refreshing token ...');
            api.refreshToken();
          }
        }
      }
    }, 120000); // 2 minutes

    return () => clearInterval(interval);
  }, []);

  const decryptCPF = useCallback((cpf) => {
    const decryptedCPF = cpf ? decrypt(cpf) : "";
    return decryptedCPF;
  }, [])

  const signIn = useCallback(async ({ login, password }) => {
    const newSessionId = await uuidv4();
    const response = await api.post('login', { login, password, newSessionId });
    const { token, user, company, processor, passwordExpired, env, isPch, sessionId, hasSocialName, allowAccess } = response;

    const newToken = token.token;
    const newRefreshToken = token.refreshToken;
    user.identity = processor.identity;
    user.cpf = decryptCPF(user.cpf);

    await clearLocalStorage();

    if (newToken) {
      localStorage.setItem('@SnxPayment:token', newToken);
    }

    if (company) {
      const ciphercompany = encrypt(JSON.stringify(company));
      const encodedCompany = encodeBase64('@SnxPayment:company');
      localStorage.setItem(encodedCompany, ciphercompany);
    }


    localStorage.setItem('@SnxPayment:env', JSON.stringify(env));
    localStorage.setItem('@SnxPayment:identity', processor.identity);
    localStorage.setItem('@SnxPayment:refreshToken', newRefreshToken);
    localStorage.setItem('@SnxPayment:passwordExpired', JSON.stringify(passwordExpired));
    localStorage.setItem('@SnxPayment:isPch', JSON.stringify(isPch));
    localStorage.setItem('@SnxPayment:sessionId', sessionId);
    localStorage.setItem('@SnxPayment:hasSocialName', JSON.stringify(hasSocialName));
    localStorage.setItem('@SnxPayment:allowAccess', JSON.stringify(allowAccess));

    await setData({ token, user });
  }, []);



  const signOut = useCallback(async ( login ) => {
    await api.post('logout', { login });
    clearLocalStorage();
    clearCacheData();
  }, [clearLocalStorage]);

  const updateUser = useCallback((user) => {
    setData((state) => ({
      ...state,
      user: {
        ...user,
        cpf: decryptCPF(user.cpf),
      },
    }));
  }, []);

  const fetchMyUser = useCallback(async () => {
    try {
      const response = await api.get('get_my_user');
      updateUser(response.user);
    } catch (error) {
      console.error('Erro ao buscar informações do usuário', error);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('@SnxPayment:token');
    if(token){
      fetchMyUser();
    }
  }, []);

  const value = useMemo(
    () => ({
      user: data.user,
      signIn,
      signOut,
      updateUser,
      clearLocalStorage,
    }),
    [
      clearLocalStorage,
      data.user,
      signIn,
      signOut,
      updateUser,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
