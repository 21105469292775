import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  DeleteOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  PhoneFilled,
  SaveOutlined,
} from "@ant-design/icons";
import { Alert, Modal, Popover, Select, Tooltip } from "antd";
import { format, parse } from "date-fns";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { isEmpty } from "lodash";
import { CgCreditCard } from "react-icons/cg";
import { FaRegBuilding, FaSpinner, FaUser } from "react-icons/fa";
import {
  MdPhoneCallback,
  MdDeleteOutline,
  MdPhone,
  MdInsertComment,
  MdOutlineAddToHomeScreen,
  MdOutlineKeyboardArrowDown,
  MdOutlineDescription,
  MdOutlineEmail,
  MdModeEdit,
  MdLocationPin,
  MdOutlineLocationOn,
  MdOutlineChat,
  MdInfoOutline,
  MdLockOpen,
  MdLockOutline,
} from "react-icons/md";
import ReactInputMask from "react-input-mask";
import * as Yup from "yup";

import PhoneLinkPlus from "../../../assets/PhoneLinkPlus";
import { usePermissions } from "../../../Context/permissionContext";
import {
  schemaResidencial,
  schemaResidencialAlternativo,
  schemaResidencialComercial,
  schemaResidencialComercialAlternativo
} from "../../../helpers/schemaEndereco";
import { schemaTitular } from "../../../helpers/schemaTitular";
import { ufs } from "../../../helpers/ufs";
import { useClient } from "../../../hooks/ClientProvider";
import api from "../../../services/api";
import ModalBiometricRequest from "../../../services/BiometricFace/ModalBiometricRequest";
import { cardProcessorService } from "../../../services/cardProcessorService";
import { capitalize } from "../../../utils/capitalize";
import { customerStatus } from "../../../utils/customerStatus";
import { formatPhone } from "../../../utils/formatPhone";
import { formatCPF, formatCelular } from "../../../utils/formatter";
import { getFaceValidation } from "../../../utils/getPremium";
import getValidationErrors from "../../../utils/getValidationErros";
import { notifyError, notifySucces } from "../../../utils/notifications";
import useCheckPermission from "../../../utils/useCheckPermission";
import { validaDDD } from "../../../utils/validations";
import Can from "../../Can"
import Loading from "../../Loading";
import ModalActiveFaturaDigital from "../../ModalActiveFaturaDigital";
import ModalActiveFaturaDigitalSms from "../../ModalActiveFaturaDigitalSms";
import ModalCancelInvoiceSms from "../../ModalCancelInvoiceSms";
import ModalChangeFaturaDigitalSms from "../../ModalChangeFaturaDigitalSms";
import ModalSituacao from "../../ModalSituacao";
import ModalEndAlternativo from "../ModalEndAlternativo";
import ModalLockAccount from "../ModalLocks/ModalLockAccount";
import ModalUnlockAccount from "../ModalLocks/ModalUnlockAccount";
import * as Style from "../style";

const { Option } = Select;

function TabTitular({ rendaValue }) {
  const formRef = useRef(null);
  const { client, updateClient } = useClient();
  const clientId = client?.client?.id;

  const hasPermission = useCheckPermission("edit_personal_data");
  const hasPermissionPhone = useCheckPermission("add_phone_number");
  const hasPermissionView = useCheckPermission("view_personal_data");
  const hasPermissionDigital = useCheckPermission("view_digital_invoice");
  const hasPermissionViewNumeroBonificado = useCheckPermission(
    "view_number_subsidized"
  );
  const hasPermissionDeletePhone = useCheckPermission("delete_phoneNumber");

  const [isBlockedUser, setIsBlockedUser] = useState(() => {
    if (client.client.statusCliente === "A") {
      return true;
    }
    return false;
  });
  const [modalUnlockAccountOpen, setModalUnlockAccountOpen] = useState(false);
  const [modalLockAccountOpen, setModalLockAccountOpen] = useState(false);

  // ESTADOS DO CONTATOS DO TITULAR:
  const [loading, setLoading] = useState(false);
  const [phones, setPhones] = useState([]);
  const [phone_sms_atual, setPhoneSmsAtual] = useState("");
  const [parameters, setParameters] = useState([]);
  const [newPhoneContact, setNewPhoneContact] = useState(null);
  const [auxPhoneSelected, setPhoneSelected] = useState({});
  const [disabledButtonModal, setDisabledButtonModal] = useState(false);
  const [newAdressAlternative, setNewAdressAlternative] = useState("R");

  const [titleFaturaHabitadaSms, setTitleFaturaHabitadaSms] = useState(
    "Habilitado fatura por SMS"
  );
  const [titleFaturaActiveSms, setTitleFaturaActiveSms] = useState(
    "Ativar fatura por SMS"
  );
  const [descriptionFunc, setDescriptionFunc] = useState("");
  const [bodyActiveSMSPhone, setBodyActiveSMSPhone] = useState(false);
  const [modalAlternativoAddress, setModalAlternativoAddress] = useState(false);
  const [modalActiveBiometricsFace, setModalActiveBiometricsFace] =
    useState(false);
  const [modalCancelInvoiceSms, setModalCancelInvoiceSms] = useState(false);
  const [modalActiveInvoiceSms, setModalActiveInvoiceSms] = useState(false);
  const [oldNumberFormat, setOldNumberFormat] = useState("");
  const [modalDeletePhone, setModalDeletePhone] = useState(false);
  const [modalConfirmActiveInvoiceSms, setModalConfirmActiveInvoiceSms] =
    useState(false);
  const [infoAuxClient, setInfoAuxClient] = useState({
    clientId: "",
    telId: "",
    telArea: "",
    telNumero: "",
  });
  const [numberFormat, setNumberFormat] = useState("");

  const [infosEnderecoAlternativo, setInfosEnderecoAlternativo] = useState({
    logradouro: "",
    numero: "",
    cep: "",
    complemento: "",
    bairro: "",
    localidade: "",
    uf: undefined,
  });

  const [isHiddenComercialAdress, setIsHiddenComercialAdress] = useState(false);
  const [isHiddenAlternativeAdress, setIsHiddenAlternativeAdress] = useState(false);

  const { permissions } = usePermissions();

  const [showMoreItems, setShowMoreItems] = useState(false);
  const [isOpenModalPersonalData, setIsOpenModalPersonalData] = useState(false);
  const [isOpenModalPersonalAdress, setIsOpenModalPersonalAdress] = useState(false);
  const [isOpenModalPersonalContacts, setIsOpenModalPersonalContacts] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [statusFaturaDigital, setStatusFaturaDigital] = useState();
  const [statusModalDesactiveFatura, setStatusModalDesactiveFatura] =
    useState(false);
  const [statusModalActiveFatura, setStatusModalActiveFatura] = useState(false);
  const [loadingEnderecoAlternativo, setLoadingEnderecoAlternativo] =
    useState(false);
  const isPch = JSON.parse(
    localStorage.getItem("@SnxPayment:isPch")
  );


  const toggleModalPersonalData = () => {
        setIsOpenModalPersonalData(!isOpenModalPersonalData);
  };

  const toggleModalPersonalAdress = () => {
    setIsOpenModalPersonalAdress(!isOpenModalPersonalAdress);
  };
  const toggleModalPersonalContacts = () => {
    setIsOpenModalPersonalContacts(!isOpenModalPersonalContacts);
  };



  function formatDate(valueDate) {
    const date = new Date(valueDate);
    date.setDate(date.getDate() + 1);

    return date;
  }

  const handleAlternativeAdress = () =>{
    setIsHiddenAlternativeAdress(true);

  }
  const handleComercialAdress = () =>{
    setIsHiddenComercialAdress(true);

  }

  const handleSubmitFormModal = async (values) => {
    let splitDate;
    let dateFormated;
    setDisabledButtonModal(true);

    if (values.birthday) {
      splitDate = values.birthday.split("/");
      dateFormated = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
    }

    let splitDateEmissao;
    let dateFormatedEmissao;

    if (values.dataEmissao) {
      splitDateEmissao = values.dataEmissao.split("/");
      dateFormatedEmissao = `${splitDateEmissao[2]}-${splitDateEmissao[1]}-${splitDateEmissao[0]}`;
    }

    let finalValue;

    if (isEmpty(client?.client?.endereco_alternativo)) {
      finalValue = {
        nome: values.nome,
        email: values.email,
        birthday: dateFormated,
        dataNascimento: dateFormated,
        rg: {
          rg: values.rg,
          dataEmissao: dateFormatedEmissao,
          orgaoEmissor: values.orgaoEmissor,
          unidadeFederativa: values.unidadeFederativaRG,
        },
      };
    } else {
      finalValue = {
        nome: values.nome,
        email: values.email,
        birthday: dateFormated,
        dataNascimento: dateFormated,
        rg: {
          rg: values.rg,
          dataEmissao: dateFormatedEmissao,
          orgaoEmissor: values.orgaoEmissor,
          unidadeFederativa: values.unidadeFederativaRG,
        },
      };
    }

    if (finalValue) {
      await api
        .patch(`client?client_id=${clientId}&identity=COND`, finalValue)
        .then(() => {
          updateClient({
            ...client,
            client: {
              ...client.client,
              name: finalValue.nome,
              email: finalValue.email,
              birthday: finalValue.birthday,
              rg: {
                rg: finalValue.rg.rg,
                dataEmissao: finalValue.rg.dataEmissao,
              },
              orgaoEmissorRg: `${finalValue.rg.orgaoEmissor}${finalValue.rg.unidadeFederativa}`,
              dataNascimento: finalValue.dataNascimento,
            },
          });
          notifySucces("Titular atualizado");
          api.post("timeline", {
            description: `Alteração Dados Pessoais`,
            client_id: clientId,
            type: "general",
          });
        })
        .catch((err) => {
          notifyError("Erro ao atualizar titular", err.message);
        })
        .finally(() => {
          toggleModalPersonalData();
          setDisabledButtonModal(false);
        });
    }
  };



  const handleSubmitFormModalAdress = async (values) => {
    setDisabledButtonModal(true);

    const finalValue = {
      enderecoResidencial: {
        logradouro: values.logradouro,
        complemento: values.complemento,
        numero: values.numero,
        bairro: values.bairro,
        cep: values.cep.replace("-", ""),
        uf: values.uf,
        localidade: values.localidade,
      }
    };


    const keysAlternative = Object.keys(values).reduce((acc, key) => {
      if (key.endsWith('_adicional')) {
        acc[key] = values[key];
      }
      return acc;
    }, {});
    const isFilledAlternative = Object.values(keysAlternative).every(value => value);

    if (isFilledAlternative) {
      finalValue.enderecoAlternativo = {
        logradouro: keysAlternative.logradourotitular_adicional,
        complemento: keysAlternative.complementotitular_adicional,
        numero: keysAlternative.numerotitular_adicional,
        bairro: keysAlternative.bairrotitular_adicional,
        cep: keysAlternative.ceptitular_adicional.replace("-", ""),
        localidade: keysAlternative.localidadetitular_adicional,
        uf: keysAlternative.uftitular_adicional,
        tipo: keysAlternative.tipotitular_adicional,
      };
    }

    const keysComercial = Object.keys(values).reduce((acc, key) => {
      if (key.endsWith('_comercial')) {
        acc[key] = values[key];
      }
      return acc;
    }, {});
    const isFilledComercial = Object.values(keysComercial).every(value => value);

    if (isFilledComercial) {
      finalValue.enderecoComercial = {
        bairro: keysComercial.bairrotitular_comercial,
        logradouro: keysComercial.logradourotitular_comercial,
        cep: keysComercial.ceptitular_comercial.replace("-", ""),
        complemento: keysComercial.complementotitular_comercial,
        localidade: keysComercial.localidadetitular_comercial,
        numero: keysComercial.numerotitular_comercial,
        uf: keysComercial.uftitular_comercial,
        departamento: keysComercial.departamentotitular_comercial,
        empresa: keysComercial.empresatitular_comercial,
      };
    }


    if (finalValue) {
      await api.patch(`client?client_id=${clientId}&identity=COND`, finalValue)
        .then(() => {
          updateClient({
            ...client,
            client: {
              ...client.client,
              endereco_residencial: {
                cep: finalValue.enderecoResidencial.cep.replace("-", ""),
                logradouro: finalValue.enderecoResidencial.logradouro,
                bairro: finalValue.enderecoResidencial.bairro,
                localidade: finalValue.enderecoResidencial.localidade,
                uf: finalValue.enderecoResidencial.uf,
                numero: finalValue.enderecoResidencial.numero,
                complemento: finalValue.enderecoResidencial.complemento,
              },
              endereco_alternativo: finalValue?.enderecoAlternativo
                ? {
                    cep: finalValue.enderecoAlternativo.cep.replace("-", ""),
                    logradouro: finalValue.enderecoAlternativo.logradouro,
                    bairro: finalValue.enderecoAlternativo.bairro,
                    localidade: finalValue.enderecoAlternativo.localidade,
                    uf: finalValue.enderecoAlternativo.uf,
                    numero: finalValue.enderecoAlternativo.numero,
                    complemento: finalValue.enderecoAlternativo.complemento,
                    tipo: finalValue.enderecoAlternativo.tipo,
                  }
                : undefined,
                endereco_comercial: finalValue?.enderecoAlternativo
                ? {
                    cep: finalValue.enderecoComercial.cep.replace("-", ""),
                    logradouro: finalValue.enderecoComercial.logradouro,
                    bairro: finalValue.enderecoComercial.bairro,
                    localidade: finalValue.enderecoComercial.localidade,
                    uf: finalValue.enderecoComercial.uf,
                    numero: finalValue.enderecoComercial.numero,
                    complemento: finalValue.enderecoComercial.complemento,
                    tipo: finalValue.enderecoComercial.tipo,
                    empresa: finalValue.enderecoComercial.empresa,
                    departamento: finalValue.enderecoComercial.departamento,
                  }
                : undefined,
            },
          });
          notifySucces("Endereços atualizados");
          api.post("timeline", {
            description: `Alteração endereços do titular`,
            client_id: clientId,
            type: "general",
          });
        })
        .catch((err) => {
          notifyError("Erro ao atualizar endereços", err.message);
        })
        .finally(() => {
          toggleModalPersonalAdress();
          setDisabledButtonModal(false);
        });
    }
  };


  useEffect(async () => {
    setStatusFaturaDigital(client?.information?.statusFaturaDigital);
    try {
      const response = await cardProcessorService.getParameters();
      setParameters(response);
    } catch (err) {
      console.error("Error to get the parameters", err);
    }
  }, [client]);


  const getCep = async (e, setFieldValue, nameValue) => {
    const cep = e.target.value.replace(/\.|\-/g, "");
    setFieldValue(nameValue, e.target.value);

    if (cep.length === 8) {
      setLoadingCep(true);

      let errorFieldPrefix;
       if(nameValue === "cep" ){
        errorFieldPrefix = "";
       }else if(nameValue === "ceptitular_comercial"){
        errorFieldPrefix = 'titular_comercial';
       }else if(nameValue === "ceptitular_adicional"){
        errorFieldPrefix = 'titular_adicional';
       }

      try {
        const res = await api.get("get_cep", { cep });

        if (res.data && res.data.erro) {
          notifyError("CEP Inválido.");
          setFieldValue(`localidade${errorFieldPrefix}`, "");
          setFieldValue(`bairro${errorFieldPrefix}`, "");
          setFieldValue(`logradouro${errorFieldPrefix}`, "");
          setFieldValue(`uf${errorFieldPrefix}`, "");
        } else if (res.data) {
          setFieldValue(`localidade${errorFieldPrefix}`, res.data.localidade);
          setFieldValue(`bairro${errorFieldPrefix}`, res.data.bairro);
          setFieldValue(`logradouro${errorFieldPrefix}`, res.data.logradouro);
          setFieldValue(`uf${errorFieldPrefix}`, res.data.uf);
        }

        setTimeout(() => {
          setLoadingCep(false);
        }, 500);
      } catch (error) {
        setTimeout(() => {
          setLoadingCep(false);
        }, 500);
        notifyError("CEP Inválido.");
      }
    }
  };

  // FUNÇÕES DO CONTATOS DO TITULAR:

  const closeModalDeletePhone = useCallback(() => {
    setModalDeletePhone(false);
  }, []);

  const openModalDeletePhone = useCallback(() => {
    setModalDeletePhone(true);
  }, []);

  const handleAddPhones = useCallback(() => {
    if (newPhoneContact) {
      notifyError("Preencha o novo contato.");
    } else {
      setNewPhoneContact({
        area: "",
        id: "",
        isRecebeFaturaSms: false,
        numero: "",
        ramal: null,
        tipo: "",
        utilizadoFaturaDigital: false,
        utilizadoServicoSms: false,
      });
    }
  }, [newPhoneContact]);

  const handlePhoneChange = useCallback(
    (event) => {
      setNewPhoneContact({
        ...newPhoneContact,
        numero: event.target.value,
      });
    },
    [newPhoneContact]
  );

  const handlePhoneType = useCallback(
    (value) => {
      setNewPhoneContact({
        ...newPhoneContact,
        tipo: value,
      });
    },
    [newPhoneContact]
  );

  const handleRemovePhone = useCallback(() => {
    setNewPhoneContact(null);
  }, []);

  const handleDeletePhone = useCallback(
    (index) => {
      if (phones.length <= 1) {
        notifyError("Não é possível excluir todos os telefones");
        return false;
      }
      const values = [...phones];
      values[index].index = index;

      setPhoneSelected(values[index]);
      openModalDeletePhone();
      return true;
    },
    [openModalDeletePhone, phones]
  );

  const handleDeletePhoneIndividual = useCallback(
    (value) => {
      if (phones.length <= 1) {
        notifyError("Não é possível excluir todos os telefones");
        return false;
      }
      setPhoneSelected(value);
      openModalDeletePhone();
      return true;
    },
    [openModalDeletePhone, phones]
  );

  const deletePhone = useCallback(async () => {
    setLoading(true);
    if (
      auxPhoneSelected.tipo === "CELULAR" &&
      auxPhoneSelected.isRecebeFaturaSms
    ) {
      await api
        .patch("client_cancel_phone_pdf_sms", {
          identity: "COND",
          client_id: clientId,
          phone_id: auxPhoneSelected.id,
        })
        .catch((error) => {
          setLoading(false);
          closeModalDeletePhone();
          const stringError = error.message;
          // Verifica se a string contém a palavra "detail"
          if (stringError.includes("detail")) {
            const splitArray = stringError.split("detail:");
            const detailText = splitArray[1].split(",")[0].trim();
            return detailText
              ? notifyError(detailText)
              : notifyError("Erro ao deletar telefone");
          }
          const splitArray = stringError.split(",");
          const basicError = splitArray[0].trim();
          return basicError
            ? notifyError(basicError)
            : notifyError("Erro ao deletar telefone");
        });
    }
    api
      .delete("client_phone", {
        identity: "COND",
        client_id: clientId,
        phone_id: auxPhoneSelected.id,
      })
      .then(() => {
        const values = [...phones];
        values.splice(auxPhoneSelected.index, 1);
        setPhones(values);
        notifySucces("Telefone deletado com sucesso");
        api.post("timeline", {
          description: `Telefone Excluído (${auxPhoneSelected.tipo}): (${auxPhoneSelected.area})  ${auxPhoneSelected.numero}`,
          client_id: clientId,
        });

        setLoading(false);
        closeModalDeletePhone();
      })
      .catch((error) => {
        setLoading(false);
        closeModalDeletePhone();
        const stringError = error.message;
        // Verifica se a string contém a palavra "detail"
        if (stringError.includes("detail")) {
          const splitArray = stringError.split("detail:");
          const detailText = splitArray[1].split(",")[0].trim();
          return detailText
            ? notifyError(detailText)
            : notifyError("Erro ao deletar telefone");
        }
        const splitArray = stringError.split(",");
        const basicError = splitArray[0].trim();
        return basicError
          ? notifyError(basicError)
          : notifyError("Erro ao deletar telefone");
      }).finally(() => {
        toggleModalPersonalContacts();
      });
  }, [auxPhoneSelected, clientId, closeModalDeletePhone, phones]);

  const handleSavePhone = useCallback(() => {
    if (newPhoneContact.tipo === "") {
      notifyError("Selecione o tipo do telefone");
      return;
    }

    if (newPhoneContact.numero === "") {
      notifyError("Preencha o Número");
      return;
    }

    const fullTel = newPhoneContact.numero.replace(/[()\s-]/g, "");
    const area = fullTel.substr(0, 2);
    const tel = fullTel.substr(2);

    const isValidArea = validaDDD(area);

    if (!isValidArea) {
      notifyError("O DDD do telefone é inválido.");
      return;
    }

    if (tel.length < 8) {
      notifyError("O Número deve possuir no mínimo 8 dígitos.");
      return;
    }

    if (tel.length > 10) {
      notifyError("O Número deve possuir no máximo 10 dígitos.");
      return;
    }

    const isValidPhoneTel = tel.at(0) && ["9", "8", "7", "6"].includes(tel[0]);

    if (newPhoneContact.tipo === "CELULAR" && !isValidPhoneTel) {
      notifyError(
        "Celulares devem começar com o dígito 9, 8, 7 ou 6.",
        "",
        false
      );
      return;
    }

    setLoading(true);

    const aux = {
      faturaDigital: newPhoneContact.utilizadoFaturaDigital,
      receberSMS: newPhoneContact.isRecebeFaturaSms,
      tipo: newPhoneContact.tipo,
      ramal: newPhoneContact.ramal,
      area,
      telefone: tel,
    };

    delete aux.identity;

    newPhoneContact.area = area;
    newPhoneContact.numero = tel;

    api
      .post(`client_phone?client_id=${clientId}&identity=COND`, aux)
      .then((response) => {
        newPhoneContact.id = response.data.id;
        const values = [...phones, newPhoneContact];
        setPhones(values);
        setNewPhoneContact(null);
        notifySucces("Telefone cadastrado com sucesso");
        setLoading(false);
        api.post("timeline", {
          description: `Telefone Adicionado (${newPhoneContact.tipo}): (${newPhoneContact.area}) ${newPhoneContact.numero}`,
          client_id: clientId,
        });
      })
      .catch((err) => {
        setLoading(false);
        setNewPhoneContact({ ...aux, area: "", numero: "", telefone: "" });
        const errorMessage =
          typeof err.message === "string" ? err.message.split(",")[0] : "";
        notifyError(`Erro ao cadastrar telefone: ${errorMessage}`);
      }).finally(() => {
        toggleModalPersonalContacts();
      });
  }, [newPhoneContact, client, phones]);

  const activeSMSPhone = useCallback(
    async (phoneID, phoneArea, phoneNumber, phone_sms) => {
      try {
        await api
          .patch("client/mark_phone_sms", {
            client_id: clientId,
            phone_id: phoneID,
            phone_area: phoneArea,
            phone_numero: phoneNumber,
            phone_sms_atual: phone_sms,
          })
          .then(() => {
            notifySucces("Alterado celular marcado para receber SMS.");
            api
              .get("client_contacts", { client_id: clientId })
              .then((response) => {
                setPhones(response.data);
                response.data.map((phone) => {
                  if (phone.utilizadoServicoSms === true) {
                    const masked_phone_number = formatPhone(
                      phone.area,
                      phone.numero
                    );
                    setPhoneSmsAtual({ ...phone, masked_phone_number });
                  }
                  return phone_sms_atual;
                });
              });
          });
      } catch (error) {
        notifyError(error.message);
        throw Error(error.response);
      }
    },
    [client]
  );



  const closeModalChangeInvoiceSms = useCallback(() => {
    setModalConfirmActiveInvoiceSms(false);
  }, []);

  const closeModalActiveBiometricsFace = useCallback(() => {
    setModalActiveBiometricsFace(false);
  }, []);

  const handleActivatePhone = useCallback(
    async (telId) => {
      setLoading(true);
      const phone = phones.find((phoneB) => phoneB.id === telId);

      await api
        .patch("client_mark_phone_bonus", {
          client_id: clientId,
          phone_id: telId,
        })
        .then(async () => {
          await api
            .get("client_contacts", { client_id: clientId })
            .then((response) => {
              setPhones(response.data);
              response.data.map((phone) => {
                if (phone.utilizadoServicoSms === true) {
                  const masked_phone_number = formatPhone(
                    phone.area,
                    phone.numero
                  );
                  setPhoneSmsAtual({ ...phone, masked_phone_number });
                }
                return phone_sms_atual;
              });
            });
          notifySucces("Número bonificado com sucesso");
          setLoading(false);
          await api.post("timeline", {
            description: `Número Bonificado (${phone.tipo}): (${phone.area}) ${phone.numero}`,
            client_id: clientId,
          });
        })
        .catch(() => {
          notifyError("Apenas celulares podem receber SMS");
        });
    },
    [client, phones]
  );

  const closeModalCancelInvoiceSms = useCallback(() => {
    setModalCancelInvoiceSms(false);
  }, []);

  const closeModalActiveInvoiceSms = useCallback(() => {
    setModalActiveInvoiceSms(false);
  }, []);

  const openModalCancelInvoiceSms = useCallback(
    async (clientId, tel) => {
      setInfoAuxClient({ clientId, telId: tel.id });

      const numberFormatted = await formatCelular(tel.numero);
      const numberActive = `(${tel.area}) ${numberFormatted}`;
      await setNumberFormat(numberActive);

      setModalCancelInvoiceSms(true);
    },
    [numberFormat, infoAuxClient]
  );

  const stepValidation = useCallback(
    async (phoneID, phoneArea, phoneNumber, phone_sms, descFunction) => {
      const validationPremium = await getFaceValidation(permissions);
      if (validationPremium) {
        await setDescriptionFunc(descFunction);
        setBodyActiveSMSPhone({ phoneID, phoneArea, phoneNumber, phone_sms });
        setModalActiveBiometricsFace(true);
      } else {
        await activeSMSPhone(phoneID, phoneArea, phoneNumber, phone_sms);
      }
    },
    [getFaceValidation, client]
  );

  const handleInvoiceSms = useCallback(
    async (type, clientId, telId, numberActive) => {
      await api
        .patch("patch_invoice_sms", {
          client_id: clientId,
          celular_id: telId,
          patch_type: type,
          numberActive,
        })
        .then(() => {
          setModalActiveInvoiceSms(true);
          setTimeout(() => {
            setModalActiveInvoiceSms(false);
          }, 5500);
          api
            .get("client_contacts", { client_id: clientId })
            .then((response) => {
              setPhones(response.data);
              response.data.map((phone) => {
                if (phone.utilizadoServicoSms === true) {
                  const masked_phone_number = formatPhone(
                    phone.area,
                    phone.numero
                  );
                  setPhoneSmsAtual({ ...phone, masked_phone_number });
                }
                return phone_sms_atual;
              });
            });
        })
        .catch((err) => {
          notifyError(err.message);
          console.error(
            "Erro ao aderir fatura digital pelo celular",
            err.message
          );
        });
    },
    [infoAuxClient, closeModalCancelInvoiceSms, client]
  );

  const activeInvoiceSms = useCallback(
    async (clientId, telId, telArea, telNumero, phoneList) => {
      await setInfoAuxClient({ clientId, telId, telArea, telNumero });

      const numberFormatted = await formatCelular(telNumero);
      const numberActive = `(${telArea}) ${numberFormatted}`;

      await setNumberFormat(numberActive);

      if (phoneList.filter((i) => i.isRecebeFaturaSms === true).length < 1) {
        handleInvoiceSms("adesao", clientId, telId, numberActive);
        closeModalCancelInvoiceSms();
      } else {
        await setOldNumberFormat(
          `(${telArea}) ${formatCelular(
            phoneList.filter((i) => i.isRecebeFaturaSms === true)[0].numero
          )}`
        );
        setModalConfirmActiveInvoiceSms(true).then(() => {
          api
            .get("client_contacts", { client_id: clientId })
            .then((response) => {
              setPhones(response.data);
              response.data.map((phone) => {
                if (phone.utilizadoServicoSms === true) {
                  const masked_phone_number = formatPhone(
                    phone.area,
                    phone.numero
                  );
                  setPhoneSmsAtual({ ...phone, masked_phone_number });
                }
                return phone_sms_atual;
              });
            });
        });
      }
    },
    [setModalActiveInvoiceSms, client]
  );

  const handleCloseModalActiveFaturaDigital = () => {
    setStatusModalActiveFatura(false);
  };

  const handleCloseModalDesactiveFaturaDigital = () => {
    setStatusModalDesactiveFatura(false);
  };

  const handleCancelFaturaDigital = useCallback(async () => {
    await api
      .post("cancel_invoice_digital", {
        client_id: client?.client?.id,
      })
      .then(() => {
        updateClient({
          ...client,
          information: {
            ...client.information,
            statusFaturaDigital: "N",
          },
        });
        setTimeout(() => {
          notifySucces("Fatura digital por e-mail desativada");
          handleCloseModalDesactiveFaturaDigital();
        }, 100);
      });
  }, [client]);

  const handleActiveFaturaDigital = useCallback(async () => {
    await api
      .post("active_invoice_digital", {
        client_id: client?.client?.id,
      })
      .then(() => {
        setStatusModalActiveFatura(true);
        updateClient({
          ...client,
          information: {
            ...client.information,
            statusFaturaDigital: "A",
          },
        });
      });
  }, [client]);

  useEffect(() => {
    if (clientId) {
      api.get("client_contacts", { client_id: clientId }).then((response) => {
        setPhones(response.data);

        response.data.map((phone) => {
          if (phone.utilizadoServicoSms === true) {
            const masked_phone_number = formatPhone(phone.area, phone.numero);

            setPhoneSmsAtual({ ...phone, masked_phone_number });
          }
          return phone_sms_atual;
        });
      });
    }

    if (!permissions.includes("add_or_cancel_invoice_by_Sms")) {
      setTitleFaturaHabitadaSms("");
      setTitleFaturaActiveSms("");
    }
  }, []);

  const handleCepEndereçoAlternativo = async (e) => {
    let cep = e.target.value;
    cep = cep.replace(/\.|\-/g, "");

    if (cep.length === 8) {
      setLoadingEnderecoAlternativo(true);
      await api
        .get("get_cep", {
          cep,
        })
        .then((res) => {
          if (res.data && !res.data.erro) {
            setInfosEnderecoAlternativo({
              ...infosEnderecoAlternativo,
              logradouro: res.data.logradouro,
              bairro: res.data.bairro,
              localidade: res.data.localidade,
              uf: res.data.uf,
            });
          } else {
            return notifyError("CEP Inválido.");
          }
          setTimeout(() => {
            setLoadingEnderecoAlternativo(false);
          }, 1000);
          return true;
        });
    }
  };

  const openModalUnlockAccount = useCallback(async () => {
    setModalUnlockAccountOpen(true);
  });

  const closeModalUnlockAccount = useCallback(
    () => setModalUnlockAccountOpen(false),
    []
  );

  const openModalLockAccount = useCallback(async () => {
    setModalLockAccountOpen(true);
  });

  const closeModalLockAccount = useCallback(
    () => setModalLockAccountOpen(false),
    []
  );

  const handleStateUser = () => {
    if (
      permissions &&
      permissions.includes("unlock_account") &&
      client.client.statusCliente === "B"
    ) {
      openModalUnlockAccount();
    } else if (
      permissions &&
      permissions.includes("lock_account") &&
      client.client.statusCliente === "A"
    ) {
      openModalLockAccount();
    }
  };

  const dataEmissaoClient =
    client?.client?.rg?.dataEmissao || client?.client?.dataEmissaoRg;

  const setValidation = () => {
    let validation;

    const isAlternativeEmpty = isEmpty(client?.client?.endereco_alternativo)  === false || isHiddenAlternativeAdress === true;
    const isComercialEmpty = isEmpty(client?.client?.endereco_comercial) === false || isHiddenComercialAdress === true;

    if (isAlternativeEmpty === false && isComercialEmpty === false ){
      validation = schemaResidencial;
    } else if (isAlternativeEmpty === true && isComercialEmpty === false){
      validation = schemaResidencialAlternativo;
    } else if (isComercialEmpty === true && isAlternativeEmpty === false){
      validation = schemaResidencialComercial;
    } else if (isAlternativeEmpty === true && isComercialEmpty === true ){
      validation = schemaResidencialComercialAlternativo;
    }

    return validation;
  };


  return (
    <>
      <div className="content-info tab1">
        <div className="content-info-tab1__left">
          <div className="client-id">
            <p>#{client && client.client.id}</p>
          </div>
          <div className="client-name">
            {client && client.client.name && (
              <p>{capitalize(client.client.name)}</p>
            )}
            {client && client.client.statusCliente && (
              <span
                className={`${customerStatus(
                  client.client.statusCliente
                ).toLocaleLowerCase()} status-new`}
              >
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isBlockedUser}
                    onChange={handleStateUser}
                  />
                  <span className="slider" />
                  <span className="knob">
                    {isBlockedUser ? (
                      <MdLockOpen color="#52C41A" />
                    ) : (
                      <MdLockOutline />
                    )}
                  </span>
                </label>
                {customerStatus(client.client.statusCliente)}
              </span>
            )}

            {client.client.ultimoMotivoBloqueioCliente && (
              <Tooltip title={client.client.ultimoMotivoBloqueioCliente}>
                <MdInfoOutline />
              </Tooltip>
            )}
          </div>

          <div className={`client-more-infos ${showMoreItems ? "active" : ""}`}>
            {permissions &&
              permissions.includes("view_income") &&
              rendaValue && (
                <p>
                  <span>Renda:</span>{" "}
                  {`${rendaValue.valor.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}`}
                </p>
              )}

            {hasPermissionView && client.client.birthday && (
              <p>
                <span>Nasc.:</span>{" "}
                {format(
                  parse(client.client.birthday, "yyyy-MM-dd", new Date()),
                  "dd/MM/yyyy"
                )}
              </p>
            )}

            {hasPermissionView && client.client.cpf && (
              <p>
                <span>CPF:</span>{" "}
                {client.client.cpf.replace(
                  /(\d{3})(\d{3})(\d{3})(\d{2})/,
                  "$1.$2.$3-$4"
                )}
              </p>
            )}

            {hasPermissionView && client && (client?.client?.rg || client?.client?.rg?.rg) && (
              <p>
                <span>RG:</span>{" "}
                {client?.client?.rg?.rg
                  ? client?.client?.rg?.rg
                  : client?.client?.rg}
              </p>
            )}
          </div>
          {client?.product?.desc_prod && (
            <div className="product-info-section">
              <span>Produto: </span>
             <div className="product">
                <CgCreditCard width={16}/>
                <h3>{client?.product?.desc_prod}</h3>
             </div>
            </div>
          )}
          {!!client?.client?.diasAtrasoTotal && client?.client?.estaEmAtraso === true  &&(
            <Alert type="warning"
              message={`Dias em atraso: ${client?.client?.diasAtrasoTotal} dias`}
              showIcon
              className="days-late-section" />
          )}
          <div className="client-separator show none">
            <hr />
          </div>
        </div>

        <div className="content-info-tab1__right">
          <div className="tab-right__item-left">
            {hasPermissionView && (

            <div className="client-phone">
              <div className="principal-phone">
                <p>
                  <MdPhoneCallback /> {phone_sms_atual?.masked_phone_number}
                </p>
              </div>

              <div className="list-icons-phone">
                {hasPermissionViewNumeroBonificado && (
                  <div className="list-icons-phone__icon">
                    {phone_sms_atual?.utilizadoTaxaBonificada ? (
                      <Tooltip title="Número Bonificado">
                        <button type="button" aria-label="icon">
                          <MdOutlineAddToHomeScreen color="#52c41a" />
                        </button>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Ativar Número bonificado">
                        <button
                          type="button"
                          aria-label="icon"
                          onClick={() =>
                            handleActivatePhone(phone_sms_atual?.id)
                          }
                        >
                          <MdOutlineAddToHomeScreen />
                        </button>
                      </Tooltip>
                    )}
                  </div>
                )}

                {phone_sms_atual?.utilizadoServicoSms ? (
                  <Tooltip title="Número ativo">
                    <div className="list-icons-phone__icon">
                      <button type="button" aria-label="icon">
                        <MdPhone color="#52c41a" />
                      </button>
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip title="Ativar Recebimento de sms">
                    <div className="list-icons-phone__icon">
                      <button type="button" aria-label="icon">
                        <MdPhone
                          onClick={
                            permissions &&
                            permissions.includes("main_contact") &&
                            (() =>
                              stepValidation(
                                phone_sms_atual?.id,
                                phone_sms_atual?.area,
                                phone_sms_atual?.numero,
                                phone_sms_atual.masked_phone_number,
                                "Alterar telefone principal"
                              ))
                          }
                        />
                      </button>
                    </div>
                  </Tooltip>
                )}

                {phone_sms_atual?.tipo === "CELULAR" &&
                  parameters.habilitarEnvioSms === true &&
                  (phone_sms_atual?.isRecebeFaturaSms ? (
                    <div className="list-icons-phone__icon">
                      <Tooltip title={titleFaturaHabitadaSms}>
                        <button type="button" aria-label="icon">
                          <MdInsertComment
                            color="#52c41a"
                            onClick={
                              permissions &&
                              permissions.includes(
                                "add_or_cancel_invoice_by_Sms"
                              ) &&
                              (() =>
                                openModalCancelInvoiceSms(
                                  clientId,
                                  phone_sms_atual
                                ))
                            }
                          />
                        </button>
                      </Tooltip>
                    </div>
                  ) : (
                    <Tooltip title={titleFaturaActiveSms}>
                      <div className="list-icons-phone__icon">
                        <button type="button" aria-label="icon">
                          <MdInsertComment
                            onClick={
                              permissions &&
                              permissions.includes(
                                "add_or_cancel_invoice_by_Sms"
                              ) &&
                              (() =>
                                activeInvoiceSms(
                                  clientId,
                                  phone_sms_atual?.id,
                                  phone_sms_atual?.area,
                                  phone_sms_atual?.numero,
                                  phones
                                ))
                            }
                          />
                        </button>
                      </div>
                    </Tooltip>
                  ))}
              </div>
            </div>
            )}

            <div className="client-separator show">
              <hr />
            </div>

            <div
              className={`client-info-email ${showMoreItems ? "active" : ""}`}
            >
              {hasPermissionView && (
                <>
                  <div className="client-info-email__address">
                    <span>
                      <MdOutlineEmail />
                    </span>
                    <p> {client?.client?.email}</p>
                  </div>
                  <div className="client-info-email__btn">
                    <Popover
                      placement="bottom"
                      content={
                        statusFaturaDigital === "A"
                          ? "Habilitado fatura digital"
                          : statusFaturaDigital === "P"
                          ? "Cliente com adesão pendente"
                          : "Ativar fatura digital"
                      }
                    >
                      <button
                        className={statusFaturaDigital}
                        type="button"
                        aria-label="btn-email"
                        onClick={() => {
                          if (
                            statusFaturaDigital === "A" &&
                            hasPermissionDigital
                          ) {
                            setStatusModalDesactiveFatura(true);
                          }

                          if (
                            statusFaturaDigital === "N" &&
                            hasPermissionDigital
                          ) {
                            handleActiveFaturaDigital();
                          }
                        }}
                      >
                        <MdOutlineDescription />
                      </button>
                    </Popover>
                  </div>
                </>
              )}
            </div>

            {hasPermissionView && client?.information?.lojaDeCadastro && (
              <>
                <div className="client-separator show">
                  <hr />
                </div>

                <div
                  className={`client-info-email ${
                    showMoreItems ? "active" : ""
                  }`}
                >
                  <div className="client-info-email__address">
                    <span className="business">
                      <FaRegBuilding />
                    </span>
                    <p> {client?.information?.lojaDeCadastro}</p>
                  </div>
                </div>
              </>
            )}

            <div
              className={`client-separator none  ${
                showMoreItems ? "show" : ""
              }`}
            >
              <hr />
            </div>
          </div>

          <div className="tab-right__item-right">
            <div
              className={`client-edit-options  ${
                showMoreItems ? "active" : ""
              }`}
            >
              <Can permission="view_personal_data">
                <button
                  type="button"
                  aria-label="btn-email"
                  className="edit"
                  onClick={toggleModalPersonalData}
                >
                  <span>
                    <MdModeEdit /> Dados Pessoais
                  </span>
                </button>

                <button
                  type="button"
                  aria-label="btn-email"
                  className="edit"
                  onClick={toggleModalPersonalContacts}
                >
                  <span>
                    <MdModeEdit /> Contatos
                  </span>
                </button>

                <button
                  type="button"
                  aria-label="btn-email"
                  className="edit"
                  onClick={toggleModalPersonalAdress}
                >
                  <span>
                    <MdModeEdit /> Endereços
                  </span>
                </button>
              </Can>
            </div>

          </div>
        </div>
        <div className="btn-show-more">
          <button
            type="button"
            aria-label="btn-show-more"
            onClick={() => setShowMoreItems(!showMoreItems)}
          >
            <p>
              <MdOutlineKeyboardArrowDown
                className={showMoreItems && "active"}
              />{" "}
              {showMoreItems ? "Mostrar menos" : "Mostrar mais"}
            </p>
          </button>
        </div>
      </div>

      <Modal
        title="Editar perfil"
        visible={isOpenModalPersonalData}
        onCancel={toggleModalPersonalData}
        width={600}
      >
        <Style.ModalTitular>
          <Formik
            initialValues={{
              nome: client?.client?.name,
              email: client?.client?.email,
              birthday: client?.client?.dataNascimento
                ? format(
                    parse(
                      client?.client?.dataNascimento,
                      "yyyy-MM-dd",
                      new Date()
                    ),
                    "dd/MM/yyyy"
                  )
                : format(
                    parse(client?.client?.birthday, "yyyy-MM-dd", new Date()),
                    "dd/MM/yyyy"
                  ),
              cpf: client?.client?.cpf ? formatCPF(client?.client?.cpf) : "",
              rg: client?.client?.rg?.rg
                ? client?.client?.rg?.rg
                : client?.client?.rg,
              orgaoEmissor: client?.client?.rg?.orgaoEmissor
                ? client?.client?.rg?.orgaoEmissor
                : client?.client?.orgaoEmissorRg?.slice(
                    0,
                    client?.client?.orgaoEmissorRg?.length - 2
                  ),
              dataEmissao: dataEmissaoClient
                ? format(formatDate(dataEmissaoClient), "dd/MM/yyyy")
                : "",
              unidadeFederativaRG: client?.client?.rg?.orgaoEmissor?.slice(-2)
                ? client?.client?.rg?.orgaoEmissor?.slice(-2)
                : client?.client?.orgaoEmissorRg?.slice(-2),
            }}
            validationSchema={schemaTitular}
            onSubmit={handleSubmitFormModal}
            enableReinitialize
          >
            {({ resetForm, setFieldValue }) => (
              <Form>
                <div className="modal-titular__left">
                  <div className="modal-titular__title">
                    <p>
                      <FaUser /> Dados pessoais do titular
                    </p>
                  </div>

                  <div className="modal-titular__content">
                    <div className="item-input">
                      <label htmlFor="nome">
                        Nome <span>*</span>
                      </label>
                      <Field name="nome">
                        {({ field, meta }) => (
                          <input
                            className={
                              meta.touched && meta.error ? "error-id" : null
                            }
                            type="text"
                            placeholder="Nome"
                            disabled={!hasPermission}
                            {...field}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="nome"
                        className="error"
                        component="span"
                      />
                    </div>

                    <div className="item-input">
                      <label htmlFor="email">
                        E-mail <span>*</span>
                      </label>
                      <Field name="email">
                        {({ field, meta }) => (
                          <input
                            className={
                              meta.touched && meta.error ? "error-id" : null
                            }
                            type="text"
                            placeholder="E-mail"
                            disabled={!hasPermission}
                            {...field}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="email"
                        className="error"
                        component="span"
                      />
                    </div>

                    {hasPermissionView && (
                    <div className="grid-2">
                        <div className="item-input">
                        <label htmlFor="birthday">
                          Data de nascimento <span>*</span>
                        </label>
                        <Field name="birthday">
                          {({ field, meta }) => (
                            <ReactInputMask
                              label="birthday"
                              placeholder="Data de nascimento"
                              mask="99/99/9999"
                              maskChar=""
                              autoComplete="none"
                              className={
                                meta.touched && meta.error ? "error-id" : null
                              }
                              disabled={!hasPermission}
                              {...field}
                              />
                          )}
                        </Field>
                        <ErrorMessage
                          name="birthday"
                          className="error"
                          component="span"
                          />
                      </div>

                      <div className="item-input">
                        <label htmlFor="cpf">
                          CPF <span>*</span>
                        </label>
                        <Field name="cpf">
                          {({ field, meta }) => (
                            <ReactInputMask
                              label="CPF"
                              placeholder="CPF"
                              readOnly
                              mask="999.999.999-99"
                              maskChar=""
                              autoComplete="none"
                              className={
                                meta.touched && meta.error ? "error-id" : null
                              }
                              disabled={!hasPermission}
                              {...field}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="cpf"
                          className="error"
                          component="span"
                        />
                      </div>
                    </div>
                    )}

                    {hasPermissionView && (
                    <div className="grid-2">
                      <div className="item-input">
                        <label htmlFor="rg">
                          RG <span>*</span>
                        </label>
                        <Field name="rg">
                          {({ field, meta }) => (
                            <input
                              className={
                                meta.touched && meta.error ? "error-id" : null
                              }
                              type="text"
                              placeholder="RG"
                              disabled={!hasPermission}
                              {...field}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="rg"
                          className="error"
                          component="span"
                        />
                      </div>
                      <div className="item-input">
                        <label htmlFor="dataEmissao">
                          Data de Emissão <span>*</span>
                        </label>
                        <Field name="dataEmissao">
                          {({ field, meta }) => (
                            <ReactInputMask
                              label="dataEmissao"
                              placeholder="Data de Emissão"
                              mask="99/99/9999"
                              maskChar=""
                              autoComplete="none"
                              className={
                                meta.touched && meta.error ? "error-id" : null
                              }
                              disabled={!hasPermission}
                              {...field}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="dataEmissao"
                          className="error"
                          component="span"
                        />
                      </div>
                    </div>
                    )}

                    {hasPermissionView && (
                    <div className="grid-2">
                      <div className="item-input">
                        <label htmlFor="orgaoEmissor">Orgão Emissor <span>*</span></label>
                        <Field name="orgaoEmissor">
                          {({ field, meta }) => (
                            <input
                              className={
                                meta.touched && meta.error ? "error-id" : null
                              }
                              type="text"
                              placeholder="Ex: SSP"
                              disabled={!hasPermission}
                              {...field}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="orgaoEmissor"
                          className="error"
                          component="span"
                        />
                      </div>

                      <div className="item-input">
                        <label htmlFor="unidadeFederativaRG">
                          UF Orgão Emissor <span>*</span>
                        </label>
                        <Field name="unidadeFederativaRG">
                          {({ field, meta }) => (
                            <Select
                              {...field}
                              value={field?.value || undefined}
                              disabled={!hasPermission}
                              onChange={(value) =>
                                setFieldValue("unidadeFederativaRG", value)
                              }
                              className={
                                meta.touched && meta.error ? "error-id" : null
                              }
                              showSearch
                              placeholder="Ex: SP"
                            >
                              {ufs.map((value) => (
                                <Option
                                  key={`tab-titular-uf-rg-${value}`}
                                  value={value}
                                >
                                  {value}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Field>
                        <ErrorMessage
                          name="unidadeFederativaRG"
                          className="error"
                          component="span"
                        />
                      </div>
                    </div>
                    )}
                  </div>
                </div>
                <Can permission="edit_personal_data">
                  <div className="footer-modal">
                    <button
                      type="button"
                      onClick={() => {
                        toggleModalPersonalData();
                        resetForm();
                      }}
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="submit"
                      disabled={disabledButtonModal}
                    >
                      Salvar
                    </button>
                  </div>
                </Can>
              </Form>
            )}
          </Formik>
        </Style.ModalTitular>
      </Modal>


      <Modal
        title="Editar endereços"
        visible={isOpenModalPersonalAdress}
        onCancel={toggleModalPersonalAdress}
        width={600}
      >
        <Style.ModalTitular>
          <Formik
            initialValues={{
              cep: client?.client?.endereco_residencial?.cep,
              logradouro: client?.client?.endereco_residencial?.logradouro,
              bairro: client?.client?.endereco_residencial?.bairro,
              localidade: client?.client?.endereco_residencial?.localidade,
              uf: client?.client?.endereco_residencial?.uf,
              numero: client?.client?.endereco_residencial?.numero,
              complemento: client?.client?.endereco_residencial?.complemento,
              cnpj: client?.client?.endereco_comercial?.cnpj,
              ceptitular_comercial: client?.client?.endereco_comercial?.cep,
              departamento: client?.client?.endereco_comercial?.departamento,
              logradourotitular_comercial:
                client?.client?.endereco_comercial?.logradouro,
              bairrotitular_comercial:
                client?.client?.endereco_comercial?.bairro,
              localidadetitular_comercial:
                client?.client?.endereco_comercial?.localidade,
              uftitular_comercial: client?.client?.endereco_comercial?.uf,
              numerotitular_comercial:
                client?.client?.endereco_comercial?.numero,
              complementotitular_comercial:
                client?.client?.endereco_comercial?.complemento,
              empresatitular_comercial: client?.client?.endereco_comercial?.empresa,
              departamentotitular_comercial: client?.client?.endereco_comercial?.departamento,
              ceptitular_adicional: client?.client?.endereco_alternativo?.cep,
              logradourotitular_adicional:
                client?.client?.endereco_alternativo?.logradouro,
              bairrotitular_adicional:
                client?.client?.endereco_alternativo?.bairro,
              localidadetitular_adicional:
                client?.client?.endereco_alternativo?.localidade,
              uftitular_adicional: client?.client?.endereco_alternativo?.uf,
              numerotitular_adicional:
                client?.client?.endereco_alternativo?.numero,
              complementotitular_adicional:
                client?.client?.endereco_alternativo?.complemento,
              tipotitular_adicional: client?.client?.endereco_alternativo?.tipo,

            }}
            validationSchema={setValidation}
            onSubmit={handleSubmitFormModalAdress}
            enableReinitialize
          >
             {({ resetForm, setFieldValue }) => (
              <Form>
                {hasPermissionView && (
                  <div className="modal-titular__left">
                    <div
                      className="modal-titular__title"
                      style={{ marginTop: "5px" }}
                    >
                      <p>
                        <MdOutlineLocationOn /> Endereço residencial do titular
                      </p>
                    </div>

                    <div className="item-input">
                      <label htmlFor="cep">CEP</label>
                      <Field name="cep">
                        {({ field, meta }) => (
                          <ReactInputMask
                            label="cep"
                            placeholder="CEP"
                            mask="99999-999"
                            maskChar=""
                            className={
                              meta.touched && meta.error ? "error-id" : null
                            }
                            disabled={!hasPermission}
                            {...field}
                            autoComplete="off"
                            onChange={(e) => getCep(e, setFieldValue, "cep")}
                          />
                        )}
                      </Field>

                      <div className={`loading ${loadingCep ? "active" : ""}`}>
                        <FaSpinner color="#faad14" />
                      </div>
                      <ErrorMessage
                        name="cep"
                        className="error"
                        component="span"
                      />
                    </div>

                    <div className="item-input">
                      <label htmlFor="logradouro">Logradouro</label>
                      <Field name="logradouro">
                        {({ field, meta }) => (
                          <input
                            className={
                              meta.touched && meta.error ? "error-id" : null
                            }
                            type="text"
                            placeholder="Logradouro"
                            disabled={!hasPermission}
                            {...field}
                            autoComplete="off"
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="logradouro"
                        className="error"
                        component="span"
                      />
                    </div>

                    <div className="item-input">
                      <label htmlFor="bairro">Bairro</label>
                      <Field name="bairro">
                        {({ field, meta }) => (
                          <input
                            className={
                              meta.touched && meta.error ? "error-id" : null
                            }
                            type="text"
                            placeholder="Bairro"
                            disabled={!hasPermission}
                            {...field}
                            autoComplete="off"
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="bairro"
                        className="error"
                        component="span"
                      />
                    </div>

                    <div className="grid-2">
                      <div className="item-input">
                        <label htmlFor="localidade">Cidade</label>
                        <Field name="localidade">
                          {({ field, meta }) => (
                            <input
                              className={
                                meta.touched && meta.error ? "error-id" : null
                              }
                              type="text"
                              placeholder="Cidade"
                              disabled={!hasPermission}
                              {...field}
                              autoComplete="off"
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="localidade"
                          className="error"
                          component="span"
                        />
                      </div>

                      <div className="item-input">
                        <label htmlFor="uf">UF</label>
                        <Field name="uf">
                          {({ field, meta }) => (
                            <Select
                              {...field}
                              value={field?.value || undefined}
                              disabled={!hasPermission}
                              onChange={(value) => setFieldValue("uf", value)}
                              className={
                                meta.touched && meta.error ? "error-id" : null
                              }
                              showSearch
                              placeholder="UF"
                            >
                              {ufs.map((value) => (
                                <Option
                                  key={`tab-titular-uf-${value}`}
                                  value={value}
                                >
                                  {value}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Field>
                        <ErrorMessage
                          name="uf"
                          className="error"
                          component="span"
                        />
                      </div>
                    </div>

                    <div className="grid-2">
                      <div className="item-input">
                        <label htmlFor="numero">Número</label>
                        <Field name="numero">
                          {({ field, meta }) => (
                            <input
                              className={
                                meta.touched && meta.error ? "error-id" : null
                              }
                              type="text"
                              placeholder="Número"
                              disabled={!hasPermission}
                              {...field}
                              autoComplete="off"
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="numero"
                          className="error"
                          component="span"
                        />
                      </div>

                      <div className="item-input">
                        <label htmlFor="complemento">Complemento</label>
                        <Field name="complemento">
                          {({ field, meta }) => (
                            <input
                              className={
                                meta.touched && meta.error ? "error-id" : null
                              }
                              type="text"
                              placeholder="Complemento"
                              disabled={!hasPermission}
                              {...field}
                              autoComplete="off"
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="complemento"
                          className="error"
                          component="span"
                        />
                      </div>
                    </div>

                    {isEmpty(client?.client?.endereco_comercial) === false || isHiddenComercialAdress === true ? (
                      <>
                        <div
                          className="modal-titular__title"
                          style={{ marginTop: "32px" }}
                        >
                          <p>
                            <MdOutlineLocationOn /> Endereço comercial do titular
                          </p>
                        </div>
                        <div className="grid-2">
                        <div className="item-input">
                          <label htmlFor="empresatitular_comercial">Empresa</label>
                          <Field name="empresatitular_comercial">
                            {({ field, meta }) => (
                              <ReactInputMask
                                className={
                                  meta.touched && meta.error ? "error-id" : null
                                }
                                label="empresa"
                                placeholder="Empresa"
                                disabled={!hasPermission}
                                {...field}
                                autoComplete="off"
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="empresatitular_comercial"
                            className="error"
                            component="span"
                          />
                        </div>

                        <div className="item-input">
                          <label htmlFor="departamentotitular_comercial">Departamento</label>
                          <Field name="departamentotitular_comercial">
                            {({ field, meta }) => (
                              <ReactInputMask
                                className={
                                  meta.touched && meta.error ? "error-id" : null
                                }
                                label="departamento"
                                placeholder="Departamento"
                                maskChar=""

                                disabled={!hasPermission}
                                {...field}
                                autoComplete="off"
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="departamentotitular_comercial"
                            className="error"
                            component="span"
                          />
                        </div>
                        </div>
                        <div className="item-input">
                          <label htmlFor="ceptitular_comercial">CEP</label>
                          <Field name="ceptitular_comercial">
                            {({ field, meta }) => (
                              <ReactInputMask
                                className={
                                  meta.touched && meta.error ? "error-id" : null
                                }
                                label="cep"
                                placeholder="CEP"
                                mask="99999-999"
                                maskChar=""
                                disabled={!hasPermission}
                                {...field}
                                autoComplete="off"
                                onChange={(e) =>
                                  getCep(
                                    e,
                                    setFieldValue,
                                    "ceptitular_comercial"
                                  )
                                }
                              />
                            )}
                          </Field>

                          <div
                            className={`loading ${loadingCep ? "active" : ""}`}
                          >
                            <FaSpinner color="#faad14" />
                          </div>
                          <ErrorMessage
                            name="ceptitular_comercial"
                            className="error"
                            component="span"
                          />
                        </div>

                        <div className="item-input">
                          <label htmlFor="logradourotitular_comercial">
                            Logradouro
                          </label>
                          <Field name="logradourotitular_comercial">
                            {({ field, meta }) => (
                              <input
                                className={
                                  meta.touched && meta.error ? "error-id" : null
                                }
                                type="text"
                                placeholder="Logradouro"
                                disabled={!hasPermission}
                                {...field}
                                autoComplete="off"
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="logradourotitular_comercial"
                            className="error"
                            component="span"
                          />
                        </div>

                        <div className="item-input">
                          <label htmlFor="bairrotitular_comercial">
                            Bairro
                          </label>
                          <Field name="bairrotitular_comercial">
                            {({ field, meta }) => (
                              <input
                                className={
                                  meta.touched && meta.error ? "error-id" : null
                                }
                                type="text"
                                placeholder="Bairro"
                                disabled={!hasPermission}
                                {...field}
                                autoComplete="off"
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="bairrotitular_comercial"
                            className="error"
                            component="span"
                          />
                        </div>

                        <div className="grid-2">
                          <div className="item-input">
                            <label htmlFor="localidadetitular_comercial">
                              Cidade
                            </label>
                            <Field name="localidadetitular_comercial">
                              {({ field, meta }) => (
                                <input
                                  className={
                                    meta.touched && meta.error
                                      ? "error-id"
                                      : null
                                  }
                                  type="text"
                                  placeholder="Cidade"
                                  disabled={!hasPermission}
                                  {...field}
                                  autoComplete="off"
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name="localidadetitular_comercial"
                              className="error"
                              component="span"
                            />
                          </div>

                          <div className="item-input">
                            <label htmlFor="uftitular_comercial">UF</label>
                            <Field name="uftitular_comercial">
                              {({ field, meta }) => (
                                <Select
                                  defaultValue=""
                                  {...field}
                                  disabled={!hasPermission}
                                  value={field?.value || undefined}
                                  onChange={(value) =>
                                    setFieldValue("uftitular_comercial", value)
                                  }
                                  className={
                                    meta.touched && meta.error
                                      ? "error-id"
                                      : null
                                  }
                                  showSearch
                                  placeholder="UF"
                                >
                                  {ufs.map((value) => (
                                    <Option
                                      key={`tab-titular-uf-titular-adicional-${value}`}
                                      value={value}
                                    >
                                      {value}
                                    </Option>
                                  ))}
                                </Select>
                              )}
                            </Field>
                            <ErrorMessage
                              name="uftitular_comercial"
                              className="error"
                              component="span"
                            />
                          </div>
                        </div>

                        <div className="grid-2">
                          <div className="item-input">
                            <label htmlFor="numerotitular_comercial">
                              Número
                            </label>
                            <Field name="numerotitular_comercial">
                              {({ field, meta }) => (
                                <input
                                  className={
                                    meta.touched && meta.error
                                      ? "error-id"
                                      : null
                                  }
                                  type="text"
                                  placeholder="Número"
                                  disabled={!hasPermission}
                                  {...field}
                                  autoComplete="off"
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name="numerotitular_comercial"
                              className="error"
                              component="span"
                            />
                          </div>

                          <div className="item-input">
                            <label htmlFor="complementotitular_comercial">
                              Complemento
                            </label>
                            <Field name="complementotitular_comercial">
                              {({ field, meta }) => (
                                <input
                                  className={
                                    meta.touched && meta.error
                                      ? "error-id"
                                      : null
                                  }
                                  type="text"
                                  placeholder="Complemento"
                                  disabled={!hasPermission}
                                  {...field}
                                  autoComplete="off"
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name="complementotitular_comercial"
                              className="error"
                              component="span"
                            />
                          </div>
                        </div>
                      </>
                    ): (
                      <>
                        <Style.BtnAddress>
                          <Style.AddButton
                            type="button"
                            onClick={handleComercialAdress}
                          >
                            {hasPermission
                              ? "Adicionar um endereço comercia"
                              : "Usuário sem permissão para adicionar endereços"}
                          </Style.AddButton>

                          <div className="bg-btn" />
                        </Style.BtnAddress>
                        <p style={{ textAlign: "center" }}>
                          <strong>Sem endereço comercial</strong>
                        </p>
                      </>
                    )}

                    {isEmpty(client?.client?.endereco_alternativo) === false || isHiddenAlternativeAdress === true ? (
                      <>
                        <div
                          className="modal-titular__title"
                          style={{ marginTop: "32px" }}
                        >
                          <p>
                            <MdOutlineLocationOn /> Endereço alternativo do titular
                          </p>
                        </div>
                        <div className="item-input">
                          <label htmlFor="ceptitular_adicional">CEP</label>
                          <Field name="ceptitular_adicional">
                            {({ field, meta }) => (
                              <ReactInputMask
                                label="cep"
                                placeholder="CEP"
                                mask="99999-999"
                                maskChar=""
                                className={
                                  meta.touched && meta.error ? "error-id" : null
                                }
                                disabled={!hasPermission}
                                {...field}
                                autoComplete="off"
                                onChange={(e) =>
                                  getCep(
                                    e,
                                    setFieldValue,
                                    "ceptitular_adicional"
                                  )
                                }
                              />
                            )}
                          </Field>

                          <div
                            className={`loading ${loadingCep ? "active" : ""}`}
                          >
                            <FaSpinner color="#faad14" />
                          </div>
                          <ErrorMessage
                            name="ceptitular_adicional"
                            className="error"
                            component="span"
                          />
                        </div>

                        <div className="item-input">
                          <label htmlFor="logradourotitular_adicional">
                            Logradouro
                          </label>
                          <Field name="logradourotitular_adicional">
                            {({ field, meta }) => (
                              <input
                                className={
                                  meta.touched && meta.error ? "error-id" : null
                                }
                                type="text"
                                placeholder="Logradouro"
                                disabled={!hasPermission}
                                {...field}
                                autoComplete="off"
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="logradourotitular_adicional"
                            className="error"
                            component="span"
                          />
                        </div>

                        <div className="item-input">
                          <label htmlFor="bairrotitular_adicional">
                            Bairro
                          </label>
                          <Field name="bairrotitular_adicional">
                            {({ field, meta }) => (
                              <input
                                className={
                                  meta.touched && meta.error ? "error-id" : null
                                }
                                type="text"
                                placeholder="Bairro"
                                disabled={!hasPermission}
                                {...field}
                                autoComplete="off"
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="bairrotitular_adicional"
                            className="error"
                            component="span"
                          />
                        </div>

                        <div className="grid-2">
                          <div className="item-input">
                            <label htmlFor="localidadetitular_adicional">
                              Cidade
                            </label>
                            <Field name="localidadetitular_adicional">
                              {({ field, meta }) => (
                                <input
                                  className={
                                    meta.touched && meta.error
                                      ? "error-id"
                                      : null
                                  }
                                  type="text"
                                  placeholder="Cidade"
                                  disabled={!hasPermission}
                                  {...field}
                                  autoComplete="off"
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name="localidadetitular_adicional"
                              className="error"
                              component="span"
                            />
                          </div>

                          <div className="item-input">
                            <label htmlFor="uftitular_adicional">UF</label>
                            <Field name="uftitular_adicional">
                              {({ field, meta }) => (
                                <Select
                                  defaultValue=""
                                  {...field}
                                  disabled={!hasPermission}
                                  value={field?.value || undefined}
                                  onChange={(value) =>
                                    setFieldValue("uftitular_adicional", value)
                                  }
                                  className={
                                    meta.touched && meta.error
                                      ? "error-id"
                                      : null
                                  }
                                  showSearch
                                  placeholder="UF"
                                >
                                  {ufs.map((value) => (
                                    <Option
                                      key={`tab-titular-uf-titular-adicional-${value}`}
                                      value={value}
                                    >
                                      {value}
                                    </Option>
                                  ))}
                                </Select>
                              )}
                            </Field>
                            <ErrorMessage
                              name="uftitular_adicional"
                              className="error"
                              component="span"
                            />
                          </div>
                        </div>

                        <div className="grid-2">
                          <div className="item-input">
                            <label htmlFor="numerotitular_adicional">
                              Número
                            </label>
                            <Field name="numerotitular_adicional">
                              {({ field, meta }) => (
                                <input
                                  className={
                                    meta.touched && meta.error
                                      ? "error-id"
                                      : null
                                  }
                                  type="text"
                                  placeholder="Número"
                                  disabled={!hasPermission}
                                  {...field}
                                  autoComplete="off"
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name="numerotitular_adicional"
                              className="error"
                              component="span"
                            />
                          </div>

                          <div className="item-input">
                            <label htmlFor="complementotitular_adicional">
                              Complemento
                            </label>
                            <Field name="complementotitular_adicional">
                              {({ field, meta }) => (
                                <input
                                  className={
                                    meta.touched && meta.error
                                      ? "error-id"
                                      : null
                                  }
                                  type="text"
                                  placeholder="Complemento"
                                  disabled={!hasPermission}
                                  {...field}
                                  autoComplete="off"
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name="complementotitular_adicional"
                              className="error"
                              component="span"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <Style.BtnAddress>
                          <Style.AddButton
                            type="button"
                            onClick={handleAlternativeAdress}
                          >
                            {hasPermission
                              ? "Adicionar um endereço alternativo"
                              : "Usuário sem permissão para adicionar endereços"}
                          </Style.AddButton>

                          <div className="bg-btn" />
                        </Style.BtnAddress>
                        <p style={{ textAlign: "center" }}>
                          <strong>Sem endereço alternativo</strong>
                        </p>
                      </>
                    )}
                  </div>
                )}
                <Can permission="edit_add_address" >
                  <div className="footer-modal">
                    <button
                      type="button"
                      onClick={() => {
                        toggleModalPersonalAdress();
                        resetForm();
                      }}
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="submit"
                    >
                      Salvar
                    </button>
                  </div>
                </Can>
              </Form>
            )}
          </Formik>
        </Style.ModalTitular>
      </Modal>

      <Modal
        title="Editar Contatos"
        visible={isOpenModalPersonalContacts}
        onCancel={toggleModalPersonalContacts}
        width={600}
      >
        <Style.ModalTitular>
          <div className="modal-titular__left">
            <div className="modal-titular__content">
              {hasPermissionView && (
                <div className="modal-titular__right">
                    <div className="modal-titular__title">
                        <p>
                            <MdPhoneCallback /> Contatos do titular
                        </p>
                    </div>
                    {phones.map((tel, index) => (
                        <Style.Inputs key={JSON.stringify(tel)}>
                            <Style.InputsContent>
                                <Style.InputsLabel>
                                    <span>{tel.tipo}</span>

                                    {/* <Tooltip
                          title={`Recebe fatura: ${tel.utilizadoFaturaDigital ? 'sim' : 'não'
                            }. Recebe pacote sms: ${tel.utilizadoServicoSms ? 'sim' : 'não'
                            }`}
                        >
                          <InfoCircleOutlined />
                        </Tooltip> */}
                                </Style.InputsLabel>
                                <Style.InputsRow>
                                    <ReactInputMask
                                        id={`focus-${index}`}
                                        disabled={!!tel.id}
                                        name="telephone"
                                        value={tel.area + tel.numero}
                                        onChange={(event) =>
                                            handlePhoneChange(index, event)
                                        }
                                        mask={
                                            tel.tipo === "CELULAR" ||
                                                tel.tipo === "REFERENCIA" ||
                                                tel.tipo === "COMERCIAL"
                                                ? "(99) 99999-9999"
                                                : "(99) 9999-9999"
                                        }
                                    />
                                </Style.InputsRow>
                            </Style.InputsContent>
                            <Style.TelephoneTypeContent>
                                {loading && tel.id === "" ? (
                                    <Loading />
                                ) : (
                                    <Style.TelephoneTrash>
                                        {tel.id ? (
                                            <> {
                                                (isPch === false || isPch === "" || isPch === null) &&
                                                <DeleteOutlined
                                                    title="Deletar"
                                                    onClick={() => {
                                                        if (hasPermissionDeletePhone === true)
                                                            return handleDeletePhone(index);
                                                    }}
                                                    className={
                                                        hasPermissionDeletePhone === false
                                                            ? "off"
                                                            : ""
                                                    }
                                                />
                                            }
                                                {hasPermissionViewNumeroBonificado && (
                                                    <>
                                                        {tel.utilizadoTaxaBonificada ? (
                                                            <Tooltip title="Número Bonificado">
                                                                <PhoneLinkPlus className="on" />
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip title="Ativar Número bonificado">
                                                                <PhoneLinkPlus
                                                                    className="off"
                                                                    onClick={() =>
                                                                        handleActivatePhone(tel.id)
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    </>
                                                )}
                                                {tel.utilizadoServicoSms ? (
                                                    <Tooltip title="Número ativo">
                                                        <PhoneFilled className="on" />
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title="Ativar Recebimento de sms">
                                                        <PhoneFilled
                                                            className="off"
                                                            onClick={
                                                                permissions &&
                                                                permissions.includes(
                                                                    "main_contact"
                                                                ) &&
                                                                (() =>
                                                                    stepValidation(
                                                                        tel.id,
                                                                        tel.area,
                                                                        tel.numero,
                                                                        phone_sms_atual.masked_phone_number,
                                                                        "Alterar telefone principal"
                                                                    ))
                                                            }
                                                        />
                                                    </Tooltip>
                                                )}
                                                {tel.tipo === "CELULAR" &&
                                                    parameters.habilitarEnvioSms === true &&
                                                    (tel.isRecebeFaturaSms ? (
                                                        <Tooltip title={titleFaturaHabitadaSms}>
                                                            <MdOutlineChat
                                                                className="on"
                                                                onClick={
                                                                    permissions &&
                                                                    permissions.includes(
                                                                        "add_or_cancel_invoice_by_Sms"
                                                                    ) &&
                                                                    (() =>
                                                                        openModalCancelInvoiceSms(
                                                                            clientId,
                                                                            tel
                                                                        ))
                                                                }
                                                            />
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title={titleFaturaActiveSms}>
                                                            <MdOutlineChat
                                                                className="off"
                                                                onClick={
                                                                    permissions &&
                                                                    permissions.includes(
                                                                        "add_or_cancel_invoice_by_Sms"
                                                                    ) &&
                                                                    (() =>
                                                                        activeInvoiceSms(
                                                                            clientId,
                                                                            tel?.id,
                                                                            tel?.area,
                                                                            tel?.numero,
                                                                            phones
                                                                        ))
                                                                }
                                                            />
                                                        </Tooltip>
                                                    ))}
                                            </>
                                        ) : (
                                            <>
                                                <SaveOutlined
                                                    onClick={() => handleSavePhone(index)}
                                                    title="Salvar"
                                                />
                                                <DeleteOutlined
                                                    title="Deletar"
                                                    onClick={() => handleRemovePhone(index)}
                                                />
                                            </>
                                        )}
                                    </Style.TelephoneTrash>
                                )}
                            </Style.TelephoneTypeContent>
                        </Style.Inputs>
                    ))}

                    {newPhoneContact && newPhoneContact ? (
                        <Style.Inputs>
                            <Style.InputsContent>
                                <Style.InputsLabel>
                                    <span>Tipo</span>
                                </Style.InputsLabel>
                                <Style.InputsRow>
                                    <Select
                                        defaultValue={newPhoneContact.tipo}
                                        onChange={(value) => handlePhoneType(value)}
                                    >
                                        <Option value="">Selecione o tipo</Option>
                                        <Option value="CELULAR">CELULAR</Option>
                                        <Option value="RESIDENCIAL">RESIDENCIAL</Option>
                                        <Option value="COMERCIAL">COMERCIAL</Option>
                                        <Option value="REFERENCIA">REFERENCIA</Option>
                                        <Option value="OUTRO">OUTRO</Option>
                                    </Select>
                                </Style.InputsRow>
                            </Style.InputsContent>

                            <Style.InputsContent>
                                <Style.InputsLabel>
                                    <span>Número</span>
                                </Style.InputsLabel>
                                <Style.InputsRow>
                                    <ReactInputMask
                                        name="telephone"
                                        value={
                                            newPhoneContact.area + newPhoneContact.numero
                                        }
                                        onChange={(event) => handlePhoneChange(event)}
                                        mask={
                                            newPhoneContact.tipo === "CELULAR" ||
                                                newPhoneContact.tipo === "REFERENCIA" ||
                                                newPhoneContact.tipo === "COMERCIAL"
                                                ? "(99) 99999-9999"
                                                : "(99) 9999-9999"
                                        }
                                        maskChar={null}
                                    />
                                </Style.InputsRow>
                            </Style.InputsContent>

                            <Style.TelephoneTypeContent>
                                {loading && newPhoneContact.id === "" ? (
                                    <Loading />
                                ) : (
                                    <Style.TelephoneTrash>
                                        {newPhoneContact.id ? (
                                            <>
                                                <DeleteOutlined title="Deletar" />

                                                {newPhoneContact.utilizadoServicoSms ? (
                                                    <Tooltip title="Número ativo">
                                                        <PhoneFilled className="on" />
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title="Ativar Recebimento de sms">
                                                        <PhoneFilled
                                                            className="off"
                                                            title="Ativar Recebimento de sms"
                                                            onClick={
                                                                permissions &&
                                                                permissions.includes(
                                                                    "main_contact"
                                                                ) &&
                                                                (() =>
                                                                    stepValidation(
                                                                        newPhoneContact.id,
                                                                        newPhoneContact.area,
                                                                        newPhoneContact.numero,
                                                                        phone_sms_atual.masked_phone_number,
                                                                        "Alterar telefone principal"
                                                                    ))
                                                            }
                                                        />
                                                    </Tooltip>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <SaveOutlined
                                                    onClick={() => handleSavePhone()}
                                                    title="Salvar"
                                                />
                                                <DeleteOutlined
                                                    title="Deletar"
                                                    onClick={() => handleRemovePhone()}
                                                />
                                            </>
                                        )}
                                    </Style.TelephoneTrash>
                                )}
                            </Style.TelephoneTypeContent>
                        </Style.Inputs>
                    ) : (
                        ""
                    )}
                    <Style.BtnPhone>
                        <Style.AddButton
                            type="button"
                            onClick={hasPermissionPhone && handleAddPhones}
                        >
                            {hasPermissionPhone
                                ? "Adicionar contato"
                                : "Usuário sem permissão para adicionar telefones"}
                        </Style.AddButton>

                        <div className="bg-btn" />
                    </Style.BtnPhone>

                </div>
              )}
            </div>
          </div>
        </Style.ModalTitular>
      </Modal>


      <Modal
        title="Deletar telefone"
        visible={modalDeletePhone}
        onCancel={closeModalDeletePhone}
      >
        <Style.TelephoneContainerDelete>
          <span>Tem certeza que deseja deletar o telefone?</span>
          <Style.TelephoneDelete>
            ({auxPhoneSelected.area}) {auxPhoneSelected.numero}
          </Style.TelephoneDelete>
          <Style.ModalButtonContainerTitular>
            {loading ? (
              <Loading />
            ) : (
              <>
                <button type="button" onClick={closeModalDeletePhone}>
                  Cancelar
                </button>
                <button type="button" onClick={() => deletePhone()}>
                  Confirmar
                </button>
              </>
            )}
          </Style.ModalButtonContainerTitular>
        </Style.TelephoneContainerDelete>
      </Modal>

      <ModalBiometricRequest
        statusOpen={modalActiveBiometricsFace}
        closeModal={closeModalActiveBiometricsFace}
        cpfClient={client.client.cpf}
        clientId={client.client.id}
        bodyActiveFunction={bodyActiveSMSPhone}
        activefunction={activeSMSPhone}
        requiredBy={descriptionFunc}
      />
      <ModalActiveFaturaDigitalSms
        statusOpen={modalActiveInvoiceSms}
        closeModal={() => closeModalActiveInvoiceSms()}
      />

      <ModalChangeFaturaDigitalSms
        statusOpen={modalConfirmActiveInvoiceSms}
        closeModal={() => closeModalChangeInvoiceSms()}
        clientInfo={infoAuxClient}
        numberFormat={numberFormat}
        oldNumberFormat={oldNumberFormat}
        phones={phones}
        handleInvoiceSms={handleInvoiceSms}
      />
      <ModalCancelInvoiceSms
        statusOpen={modalCancelInvoiceSms}
        closeModal={() => closeModalCancelInvoiceSms()}
        clientInfo={infoAuxClient}
        numberFormat={numberFormat}
      />

      <ModalLockAccount
        open={modalLockAccountOpen}
        closeModal={closeModalLockAccount}
        clientID={client.client.id}
        setIsBlockedUser={setIsBlockedUser}
      />
      <ModalUnlockAccount
        open={modalUnlockAccountOpen}
        closeModal={closeModalUnlockAccount}
        clientID={client.client.id}
        setIsBlockedUser={setIsBlockedUser}
      />
      <ModalSituacao
        statusOpen={statusModalDesactiveFatura}
        closeModal={() => handleCloseModalDesactiveFaturaDigital()}
        handleSituacao={() => handleCancelFaturaDigital()}
      />

      <ModalActiveFaturaDigital
        statusOpen={statusModalActiveFatura}
        closeModal={() => handleCloseModalActiveFaturaDigital()}
      />
    </>
  );
}

export default TabTitular;
