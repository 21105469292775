import { decrypt, encodeBase64 } from "./crypto";

const getCompanyName = () => {

  const encodedCompanyKey = encodeBase64('@SnxPayment:company');
  const encryptedCompanyData = localStorage.getItem(encodedCompanyKey);

  if (!encryptedCompanyData) return '';
  const decryptedCompanyData = decrypt(encryptedCompanyData);
  try {
    const company = JSON.parse(decryptedCompanyData);
    return company.name.toLowerCase();
  } catch (error) {
    console.error('Failed to parse company data:', error);
    return '';
  }
}

export { getCompanyName };
