import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import {
  Dropdown,
  Menu,
  Tooltip,
  Modal,
  DatePicker,
  ConfigProvider,
  Popover,
  Select,
  Checkbox,
} from "antd";
import datePickerLocale from "antd/lib/locale/pt_BR";
import { format } from "date-fns";
import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import useInView from "react-cool-inview";
import { AiOutlineClose, AiOutlineFilePdf } from "react-icons/ai";
import { BiMenu } from "react-icons/bi";
import { BsCheckCircleFill } from "react-icons/bs";
import { CiCreditCard1 } from "react-icons/ci";
import {
  FaAngleLeft,
  FaAngleRight,
  FaFilter,
  FaRegBuilding,
  FaRegCalendarAlt,
  FaRegFilePdf,
  FaSortDown,
} from "react-icons/fa";
import { FiAlertTriangle, FiEye } from "react-icons/fi";
import { MdOutlineDownload, MdOutlineSend } from "react-icons/md";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import ReactInputMask from "react-input-mask";
import Slider from "react-slick";
import * as Yup from "yup";

import FileIcon from "../../../assets/FileIcon";
import Can from "../../../components/Can";
import LoadingElevaty from "../../../components/LoadingElevaty";
import ModalHistoricoFaturas from "../../../components/ModalHistoricoFaturas";
import { usePermissions } from "../../../Context/permissionContext";
import { useAuth } from "../../../hooks/AuthProvider";
import { useClient } from "../../../hooks/ClientProvider";
import useFutureInvoices from "../../../hooks/useFutureInvoices";
import useInvoicev2 from "../../../hooks/useInvoicev2";
import useToggle from "../../../hooks/useToggle";
import api from "../../../services/api";
import { getCompanyName } from "../../../utils/companyName";
import { formatPhone } from "../../../utils/formatPhone";
import { notifyError, notifySucces } from "../../../utils/notifications";
import { obterNomeDoMes } from "../../../utils/obterNomeDoMes";
import InvoiceInstallmentAnticipationModal from "../../home/Invoice/modals/installmentAnticipation";
import InstallmentSimulationModal from "../../home/Invoice/modals/InstallmentSimulation";
import InstallmentsViewModal from "../../home/Invoice/modals/InstallmentsView";
import ModalChangeInvoiceDueDate from "../../home/modals/modalChangeInvoiceDueDate";
import * as Style from "./style";

const { Item } = Menu;
const { RangePicker } = DatePicker;
const { Option } = Select;

function FaturaV2() {
  const { permissions } = usePermissions();
  const { client } = useClient();
  const { user } = useAuth();
  const [isChangeInvoiceDueDateVisible, setIsChangeInvoiceDueDateVisible] =
    useState(false);
  const [dateCiclos, setDateCiclos] = useState([]);
  const [cicloValue] = useState("0");
  const [filterRangeActive, setFilterRangeActive] = useState(false);

  const [modalHistoricoFaturas, setModalHistoricoFaturas] = useState(false);

  const [lastYear, setLastYear] = useState(() => {
    const lastYearDate = new Date();
    lastYearDate.setMonth(lastYearDate.getMonth() + 2);
    lastYearDate.setDate(0);
    lastYearDate.setMonth(lastYearDate.getMonth() - 12);
    return lastYearDate;
  });
  const [today, setToday] = useState(() => {
    const todayDate = new Date();
    todayDate.setMonth(todayDate.getMonth() + 2);
    todayDate.setDate(0);
    return todayDate;
  });
  const [isModalFaturaOpen, setIsModalFaturaOpen] = useState(false);
  const [isModalLancamentosOpen, setIsModalLancamentosOpen] = useState(false);
  const [typeFature, setTypeFature] = useState("email");
  const [typeSendFature, setTypeSendFature] = useState("fatura");
  const [loadingFature, setLoadingFature] = useState(true);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [codBarras, setCodBarras] = useState(false);
  const [faturaSelected, setFaturaSelected] = useState(null);
  const [valueEmailDownload, setValueEmailDownload] = useState("");
  const [disabledInputEmail, setDisabledInputEmail] = useState(false);
  const [valueFormInputSMS, setValueFormInputSMS] = useState("");
  const [disabledInputSMS, setDisabledInputSMS] = useState(false);
  const [disabledInput, setDisabledInput] = useState(false);
  const [disabledInputSelectSMS, setDisabledInputSelectSMS] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [paymentDate, setPaymentDate] = useState();
  const [cuttingDay, setCuttingDay] = useState();
  const [infosFullFatura, setInfosFullFatura] = useState();
  const [rangeDate, setRangeDate] = useState(false);
  const [indexCarouselInitial, setIndexCarouselInitial] = useState();
  const [phones, setPhones] = useState([]);
  const [loadingInvoicePdf, setLoadingInvoicePdf] = useState(false);
  const [loadingContingencyInvoice, setLoadingContingencyInvoice] = useState(false);
  const [carouselInvoices, setCarouselInvoices] = useState();

  const showChangeInvoiceDueDate = useCallback(() => {
    setIsChangeInvoiceDueDateVisible(true);
  }, []);

  const { invoices, loadingInvoice } = useInvoicev2(
    client?.client?.id,
    format(lastYear, "yyyy-MM-dd"),
    format(today, "yyyy-MM-dd")
  );

   const lastInvoiceHasAssociatedPayments = useCallback(function() {
      if (invoices.length > 0) {
        const lastInvoiceData = invoices[invoices.length - 1]
        const associatedPaymentTotalValue = parseFloat(lastInvoiceData?.valorPagamentosEfetuados?.replace("R$ ", ""));

        if (associatedPaymentTotalValue === 0 || isNaN(associatedPaymentTotalValue) || associatedPaymentTotalValue === undefined) {
          return false
        }
        return true
      }
    }, [invoices])


  const { futureInvoices, loading } = useFutureInvoices(client?.client?.id);

  const removeMonetaryFormation = (string) =>
    Number(string?.replace("R$ ", ""));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isInvoiceNull = useMemo(() => {
    if (invoices === undefined) {
      return true;
    }
    if (invoices.length === 0) {
      return true;
    }

    if (invoices?.length > 0) {
      return (
        removeMonetaryFormation(invoices?.[invoices.length - 1]?.devedor) === 0
      );
    }
  }, [invoices]);

  const [
    isModalInstallmentSimulationVisible,
    setIsModalInstallmentSimulationVisibility,
  ] = useToggle();

  const [isModalDebitBalanceVisible, toggleModalDebitBalanceVisibility] =
    useToggle();

  const [isModalInstallmentsViewVisible, setIsModalInstallmentsViewVisibility] =
    useToggle();

  const isDebitInstallmentDisabled = useMemo(() => {
    const { devedorTotal } = client.client;
    return devedorTotal <= 0;
  }, [client]);

  const menuInsurance = useMemo(
    () => (
      <Menu>
        {permissions?.includes("change_InvoiceDueDate") && (
          <Item key="0" onClick={showChangeInvoiceDueDate}>
            <span>Alterar data de vencimento</span>
          </Item>
        )}

        {permissions?.includes("invoice_installment") && (
          <Item
            key="1"
            onClick={setIsModalInstallmentSimulationVisibility}
            disabled={ isInvoiceNull || lastInvoiceHasAssociatedPayments()}
          >
            <Tooltip
              title={
              isInvoiceNull ? "Usuário não possui saldo devedor para a última fatura" :
              ( lastInvoiceHasAssociatedPayments() ? "Usuário possui pagamento associado à fatura ativa" :
                "Simular parcelamento da fatura")
              }
            >
              <span>Simular parcelamento da fatura</span>
            </Tooltip>
          </Item>
        )}

        {permissions?.includes("debtor_installment") && (
          <Item
            key="2"
            onClick={toggleModalDebitBalanceVisibility}
            disabled={isDebitInstallmentDisabled}
          >
            <Tooltip
              title={
                isDebitInstallmentDisabled &&
                "Cliente não possui devedor futuro"
              }
            >
              <span>Simular parcelamento de saldo devedor total</span>
            </Tooltip>
          </Item>
        )}
        {permissions?.includes("permission_view_invoice_installments") && (
          <Item key="3" onClick={setIsModalInstallmentsViewVisibility}>
            <span>Visualizar parcelamento da fatura / saldo devedor</span>
          </Item>
        )}
        {permissions?.includes("active_installment") && (
          <InvoiceInstallmentAnticipationModal key={4} />
        )}

        <Item key="5" onClick={() => setModalHistoricoFaturas(true)}>
          <span>Histórico de faturas</span>
        </Item>
      </Menu>
    ),
    [permissions, showChangeInvoiceDueDate, isInvoiceNull]
  );

  const fetchCycles = useCallback(async () => {
    try {
      const resp = await api.get("ciclos_details");
      const { data } = resp;
      const filteredDates = data.filter((c) => c.ativo === "S");
      const sortedArray = filteredDates.sort(
        (a, b) => a.diaVencimento - b.diaVencimento
      );

      setDateCiclos(sortedArray);
    } catch (error) {
      console.error("Falha ao listar ciclos disponiveis para cliente");
    }
  }, []);

  const { observe } = useInView({
    threshold: 0.1,
    onEnter: ({ unobserve }) => {
      fetchCycles().then(unobserve);
    },
  });

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        <FaAngleRight />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        <FaAngleLeft />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: indexCarouselInitial ?? 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getDataInvoicesDetailV2 = async (invoiceID) => {
    try {
      const response = await api.get("client_detailed_invoice", {
        client_id: client.client.id,
        invoice_id: invoiceID,
      });

      let paymentData = "";

      const formatedDate = response.data.lancamentos.map((item) => {
        const date = moment.parseZone(item.dataLancamento).format("DD/MM/YYYY");
        const datetransaction = moment
          .parseZone(item.dataTransacao)
          .format("DD/MM/YYYY");
        item.dataLancamento = date;
        item.dataTransacao = datetransaction;
        item.valor = `R$ ${Number(item.valor).toFixed(2)}`;
        if (item.tipo === "PAGAMENTO") {
          paymentData = date;
        }
        return item;
      });

      response.data.lancamentos = formatedDate;

      setInfosFullFatura(response.data);

      setTimeout(() => {
        setLoadingTable(false);
      }, 1000);
      return response.data;
    } catch (e) {
      notifyError("Erro em faturas");
    }
  };

  const showContingencyPdf = useCallback(async (row) => {
    try {
      setLoadingContingencyInvoice(true);
      const data = await api.get(
        "contingency_invoice",
        { invoiceId: row, raw_pdf: "true", clientId: client.client.id, },
        { "Content-Type": "application/pdf" },
        true
      );
      const fileURL = URL.createObjectURL(await data.blob());
      const pdfWindow = window.open(fileURL, "_blank");

      setTimeout(() => {
        pdfWindow.document.title = "Fatura.pdf";
      }, 750);
    } catch (error) {
      const stringError = error.message;

      if (stringError.includes("detail")) {
        const splitArray = stringError.split("detail:");
        const detailText = splitArray[1].split(",")[0].trim();
        return detailText
          ? notifyError(detailText)
          : notifyError("Falha ao buscar PDF da fatura");
      }
      const splitArray = stringError.split(",");
      const basicError = splitArray[0].trim();
      return basicError
        ? notifyError(basicError)
        : notifyError("Falha ao buscar PDF da fatura");
    } finally {
      setLoadingContingencyInvoice(false);
    }
  }, []);

  const sendPdfComplete = async (row) => {

    try {
      const data = await api.get("billet_pdf_complete", {
        invoice_id: row,
      });

    } catch (error) {
      console.log(error);
    }
  };

  const showBilletPdf = useCallback(async (row) => {
    try {
      setLoadingContingencyInvoice(true);
      const data = await api.get(
        "billet_pdf",
        { invoice_id: row, raw_pdf: "true" },
        { "Content-Type": "application/pdf" },
        true
      );

      const fileURL = URL.createObjectURL(await data.blob());
      const pdfWindow = window.open(fileURL);
      setTimeout(() => {
        pdfWindow.document.title = "Fatura.pdf";
      }, 750);
    } catch (error) {
      console.error(error.message);
      showContingencyPdf(row);
    } finally {
      setLoadingContingencyInvoice(false);
    }
  }, []);

  const handleSelectFatura = useCallback((value) => {
    if (value?.future) {
      setFaturaSelected(value);
      setLoadingTable(true);
      setInfosFullFatura(value);

      setTimeout(() => {
        setLoadingTable(false);
      }, 1000);
    } else {
      setFaturaSelected(value);
      setLoadingTable(true);
      getDataInvoicesDetailV2(value.idFatura);
    }
  }, []);

  const changeRangeFatura = (e) => {
    setLastYear(moment(e[0].utc().format("YYYY-MM-DD HH:mm:ss")).toDate());
    setToday(moment(e[1].utc().format("YYYY-MM-DD HH:mm:ss")).toDate());
    setFilterRangeActive(true);
  };

  const getPhonesUser = () => {
    api
      .get("client_contacts", { client_id: client.client.id })
      .then((response) => {
        const numberSms = response.data.filter((cel) => cel.tipo === "CELULAR");
        const formattedPhones = Array.isArray(numberSms)
          ? numberSms.map((number) => formatPhone(number?.area, number?.numero))
          : numberSms;
        setPhones(formattedPhones);
      });
  };

  const getClosedInvoice = useCallback(
    async (row) => {
      setIsModalFaturaOpen(true);
      setTypeFature("email");
      getPhonesUser();
      try {
        const {
          data: {
            linhaDigitavel,
            linhaDigitavelFormatada,
            hashFatura,
            lancamentos,
            dataVencimento,
          },
        } = await api.get("client_closed_invoice", {
          client_id: client.client.id,
          billet_id: row.idFatura,
        });
        setSelectedInvoice({
          ...row,
          hashFatura,
          lancamentos,
          dataVencimento,
        });
        setCodBarras(linhaDigitavel ?? linhaDigitavelFormatada);
        setLoadingFature(false);
      } catch (error) {
        const textoErro = "Falha ao carregar linha digitável";

        setIsModalFaturaOpen(false);
        const stringError = error.message;
        // Verifica se a string contém a palavra "detail"
        if (stringError.includes("detail")) {
          const splitArray = stringError.split("detail:");
          const detailText = splitArray[1].split(",")[0].trim();
          if (detailText) {
            notifyError(detailText);
            throw new Error(textoErro);
          } else {
            notifyError(textoErro);
          }
        }
        throw new Error(notifyError(error.message));
      }
    },
    [client]
  );

  const handleChangeModalEmailInput = (event, setFieldValue) => {
    setDisabledInput(true);
    const { value } = event.target;
    setFieldValue("customEmail", value);
    setValueEmailDownload(value);
  };

  const handleChangeModalSMSlInput = (event, setFieldValue) => {
    setDisabledInputSelectSMS(true);
    const { value } = event.target;
    setFieldValue("customPhone", value);
    setValueFormInputSMS(value);
  };

  const handleSelectMail = (event, setFieldValue) => {
    // eslint-disable-next-line eqeqeq
    if (event == 0) {
      setFieldValue("email", "");
    } else {
      setFieldValue("email", event);
    }
  };

  const handleSelectSMS = (event, setFieldValue) => {
    // eslint-disable-next-line eqeqeq
    if (event == 0) {
      setFieldValue("phone", "");
    } else {
      setFieldValue("phone", event);
    }
  };

  const handleChangeSendToUnregisteredMail = (checked, setFieldValue) => {
    setFieldValue("sendToUnregisteredMail", checked);
    setDisabledInput(checked);
    setDisabledInputEmail(!checked);
  };

  const handleChangeSendToUnregisteredSMS = (checked, setFieldValue) => {
    setFieldValue("sendToUnregisteredPhone", checked);
    setDisabledInputSelectSMS(checked);
    setDisabledInputSMS(!checked);
  };

  useEffect(() => {
    if (invoices) {
      const filteredData = invoices.filter((item) => item.status === "ATUAL");

      if (filterRangeActive) {
        setCarouselInvoices(invoices);

        const lastIndex = invoices.length - 1;

        setIndexCarouselInitial(lastIndex - 2);

        if (invoices[0]?.idFatura) {
          setFaturaSelected(invoices[0]);
          getDataInvoicesDetailV2(invoices[0].idFatura);
        } else {
          setFaturaSelected(false);
          getDataInvoicesDetailV2(null);
          setInfosFullFatura(null);
        }

        setTimeout(() => {
          setLoadingTable(false);
        }, 1000);
        return;
      }

      if (futureInvoices) {
        const editFutureInvoices = futureInvoices.map((item, index) => {
          const formatedDate = moment.parseZone(item.dataVencimento);
          const formatedDateCorte = moment.parseZone(item.dataCorte);
          const splitDate = formatedDate.format("DD/MM/YYYY").split("/");

          const formatedLancamentos = item?.lancamentos.map((item) => {
            const date = moment
              .parseZone(item.dataLancamento)
              .format("DD/MM/YYYY");
            const datetransaction = moment
              .parseZone(item.dataTransacao)
              .format("DD/MM/YYYY");

            return {
              tipo: item.tipo,
              descricaoLancamento: item.descricaoLancamento,
              dataLancamento: date,
              dataTransacao: datetransaction,
              valor: `R$ ${Number(item.valor).toFixed(2)}`,
            };
          });

          return {
            idFatura: `${index}-future`,
            dataVencimento: formatedDate.format("DD/MM/YYYY"),
            dataCorte: formatedDateCorte.format("DD/MM/YYYY"),
            anoVencimento: splitDate[2].slice(-2),
            MesVencimento: obterNomeDoMes(splitDate[1]),
            DataVencimentoOnly: splitDate[0],
            valorTotal: `R$ ${Number(item.valor).toFixed(2)}`,
            lancamentos: [...formatedLancamentos],
            future: true,
          };
        });

        setCarouselInvoices([...invoices, ...editFutureInvoices]);

        const lastIndex = invoices.length - 1;

        setIndexCarouselInitial(lastIndex - 2);

        if (filteredData[0]?.idFatura) {
          setFaturaSelected(filteredData[0]);
          getDataInvoicesDetailV2(filteredData[0].idFatura);
        }

        setTimeout(() => {
          setLoadingTable(false);
        }, 1000);

        return false;
      }

      if (filteredData[0]?.idFatura) {
        setFaturaSelected(filteredData[0]);
        getDataInvoicesDetailV2(filteredData[0].idFatura);
      }
    }
  }, [invoices, futureInvoices, filterRangeActive]);

  // ENVIO FATURA E-MAIL

  const closeModalSendFaturas = () => {
    setIsModalFaturaOpen(false);
    setLoadingFature(true);
    setTypeFature("email");
    setValueEmailDownload("");
    setDisabledInput(false);
    setDisabledInputEmail(false);
  };

  const companyId = user?.company_id;

  const sendMail = useCallback(async (email, faturaDetails, codigoBarras) => {
    const identity = localStorage.getItem("@SnxPayment:identity");
    const companyName = getCompanyName();
    setLoadingFature(true);
    try {
      await api.post("send_mail_barCode", {
        firstName: client.client.name,
        nameCard: client.client.name,
        cpfCnpj: client.client.cpf,
        cardNumber: client.client.numeroCartaoTruncado,
        clientId: client.client.id,
        expiryDate: faturaDetails?.dataVencimento,
        totalValue: faturaDetails?.valorTotal ?? faturaDetails?.valorTotalFaturaAtual,
        minimumValue: faturaDetails?.valorMinimo ?? faturaDetails?.valorPagamentoMinimoFaturaAtual,
        linhaDigitavel: codigoBarras || "",
        hashFatura: faturaDetails?.hashFatura,
        invoiceId: faturaDetails?.idFatura,
        identity,
        companyId,
        company_name: companyName[0].toUpperCase() + companyName.slice(1),
        ticket: client.client.ticket.protocol,
        email,
        lancamentos: faturaDetails?.lancamentos,
        productCode: client.product.codigoProduto,
        productName: client.product.desc_prod,
        status: faturaDetails?.status,
      });
      notifySucces(`Sucesso ao enviar email para ${email}`);
      closeModalSendFaturas();
    } catch (error) {
      notifyError(`Falha ao enviar email para ${email}`);
      console.error(`Falha ao enviar email para ${email}`, error.message);
      closeModalSendFaturas();
    }
  }, []);

  const handleSendEmailFatura = useCallback(
    (valueEmail) => {
      const { email, customEmail, sendToUnregisteredMail } = valueEmail;

      const mail = sendToUnregisteredMail ? customEmail : email;

      if (typeSendFature === "fatura") {
        sendMail(mail, selectedInvoice, codBarras);
      } else {
        alert("FATURA E BOLETO");
      }
    },
    [selectedInvoice, typeSendFature, codBarras]
  );

  const showSummarizedInvoicePdf  = useCallback(async (row) => {
    setLoadingInvoicePdf(true);
    try {
      const data = await api.get(
        "summarized_invoice",
        { invoice_id: row.idFatura, raw_pdf: "true", client_id: client.client.id, },
        { "Content-Type": "application/pdf" },
        true
      );
      const fileURL = URL.createObjectURL(await data.blob());
      const pdfWindow = window.open(fileURL,'_blank');

      setTimeout(() => {
        pdfWindow.document.title = "Fatura.pdf";
      }, 750);
    } catch (error) {
      const stringError = error.message;

      if (stringError.includes("detail")) {
        const splitArray = stringError.split("detail:");
        const detailText = splitArray[1].split(",")[0].trim();
        return detailText ? notifyError(detailText) : notifyError("Falha ao buscar PDF da fatura");
      }
        const splitArray = stringError.split(",");
        const basicError = splitArray[0].trim();
        return basicError ? notifyError(basicError) : notifyError("Falha ao buscar PDF da fatura");
    } finally {
      setLoadingInvoicePdf(false);
    }
  }, []);

  async function sendSms(phoneValue, faturaDetails, codigoBarras) {
    setLoadingFature(true);

    try {
      const lastCard = client.client.numeroCartaoTruncado.slice(-4);
      // eslint-disable-next-line no-restricted-globals
      if (!codigoBarras) {
        throw new Error("Falha ao enviar SMS, não possui linha digitável");
      }

      await api.post("send_sms_barCode", {
        descProd: client.product.desc_prod,
        numberCard: lastCard,
        expiryDate: faturaDetails?.dataVencimento,
        totalValue: faturaDetails?.valorTotal,
        linhaDigitavel: codigoBarras,
        clientId: client.client.id,
        productCode: client.product.codigoProduto,
        phone: phoneValue.replace(/[^\d]/g, ""),
        companyId,
        invoiceId: faturaDetails?.idFatura,
      });
      notifySucces(`Sucesso! Sms enviado para ${phoneValue}`);
      closeModalSendFaturas();
    } catch (error) {
      notifyError(`Falha ao enviar sms para ${phoneValue}`);
      closeModalSendFaturas();
    }
  }

  const handleSendSMSFatura = useCallback(
    (valueEmail) => {
      const { phone, customPhone, sendToUnregisteredPhone } = valueEmail;

      const formattedPhone = sendToUnregisteredPhone ? customPhone : phone;

      if (typeSendFature === "fatura") {
        sendSms(formattedPhone, selectedInvoice, codBarras);
      } else {
        alert("FATURA E BOLETO");
      }
    },
    [selectedInvoice, typeSendFature, codBarras]
  );

  return (
    <Style.WrapperFaturaV2 ref={observe}>
      <div className="fatura-v2__header">
        <div className="fatura-v2__header--left">
          <p>Faturas</p>
        </div>
        <div className="fatura-v2__header--right">
          {rangeDate ? (
            <ConfigProvider locale={datePickerLocale}>
              <RangePicker
                defaultValue={[moment(lastYear), moment(today)]}
                format="DD/MM/YYYY"
                onChange={changeRangeFatura}
              />
              <button
                type="button"
                className="filter close"
                onClick={() => setRangeDate(false)}
              >
                <AiOutlineClose />
              </button>
            </ConfigProvider>
          ) : (
            <button
              type="button"
              className="filter"
              onClick={() => setRangeDate(true)}
            >
              <FaFilter />
            </button>
          )}

          <Dropdown overlay={menuInsurance} trigger={["click"]}>
            <button type="button" className="btn-configs">
              <BiMenu />
              <p>Ações</p>
            </button>
          </Dropdown>
        </div>
      </div>

      {loadingInvoice ? (
        <div className="faturas-v2__loading">
          <LoadingElevaty />
        </div>
      ) : (
        <>
          <div className="faturas-v2__table--calendar">
            {carouselInvoices ? (
              <Slider {...settings}>
                {carouselInvoices.map((item) => (
                  <div
                    className={`faturas-v2__table--calendar--item ${
                      item.idFatura === faturaSelected?.idFatura ? "active" : ""
                    }`}
                    key={item.idFatura}
                    onClick={() => handleSelectFatura(item)}
                  >
                    {item.idFatura === faturaSelected?.idFatura ? (
                      <FileIcon color="#FFB32C" />
                    ) : (
                      <FileIcon
                        color={
                          item.statusPagamento === "INTEGRAL"
                            ? "#309200"
                            : item.statusPagamento === "PARCELADO" ||
                              item.statusPagamento === "MINIMO" ||
                              item.statusPagamento === "ABAIXO_DO_MINIMO"
                            ? "#FF4D4F"
                            : "#8C8C8C"
                        }
                      />
                    )}

                    <p>
                      <strong>
                        {item.MesVencimento} {item.anoVencimento}
                      </strong>
                    </p>
                    <p>{item.valorTotal}</p>

                    <div className="arrow-down">
                      <FaSortDown />
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              ""
            )}
          </div>

          {faturaSelected ? (
            <div className="fatura-v2__last-ticket">
              <div className="fatura-v2__last-ticket--left">
                <p>
                  Venc. {faturaSelected?.DataVencimentoOnly}{" "}
                  {faturaSelected?.MesVencimento}
                </p>
                {infosFullFatura?.melhorDiaCompra ? (
                  <p>Melhor dia de compra {infosFullFatura?.melhorDiaCompra}</p>
                ) : (
                  ""
                )}
              </div>

              <div className="fatura-v2__last-ticket--center">
                <p>
                  <strong>{faturaSelected?.valorTotal}</strong>
                </p>
                {faturaSelected.valorMinimo ? (
                  <p>Min: {faturaSelected?.valorMinimo}</p>
                ) : (
                  ""
                )}
              </div>

              {faturaSelected?.idFatura.indexOf("future") !== -1 ? null : (
                <div className="fatura-v2__last-ticket--right">
                  <div className="item-send">
                    <Popover
                      placement="bottom"
                      content="Enviar linha digitável"
                    >
                      <MdOutlineSend
                        onClick={() => getClosedInvoice(faturaSelected)}
                      />
                    </Popover>
                    <Can permission="permission_view_summarized_invoices">
                      {faturaSelected?.status === "ATUAL" && (
                      <Popover
                        placement="bottom"
                        content="Baixar fatura resumida"
                      >

                        {loadingInvoicePdf ? (
                            <LoadingOutlined />
                          ) : (
                            <AiOutlineFilePdf  onClick={() => showSummarizedInvoicePdf(faturaSelected)} />
                        )}

                      </Popover>
                      )}
                    </Can>
                  </div>
                  {/* <div className="item-pdf">
                    <Popover placement="bottom" content="Enviar PDF">
                      <FaRegFilePdf
                        onClick={() =>
                          sendPdfComplete(faturaSelected?.idFatura)
                        }
                      />
                    </Popover>
                  </div> */}
                  <div className="item-baixar">
                    <button
                      type="button"
                      onClick={() => showBilletPdf(faturaSelected?.idFatura)}
                    >
                      {loadingContingencyInvoice === true ? (
                        <span>
                          <LoadingOutlined />
                        </span>
                      ) : (
                        <span>
                          <MdOutlineDownload className="download-invoices" />
                          <p>Baixar</p>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : null}

          {loadingTable ? (
            <div className="faturas-v2__loading">
              <LoadingElevaty />
            </div>
          ) : (
            <div className="fatura-v2__table">
              <div className="fatura-v2__table--header">
                <div className="fatura-v2__table--header__data">
                  <p>Data</p>
                </div>
                <div className="fatura-v2__table--header__local">
                  <p>Local</p>
                </div>
                <div className="fatura-v2__table--header__cartao">
                  <p>Cartão truncado</p>
                </div>
                <div className="fatura-v2__table--header__real">
                  <p>Valor em Real</p>
                </div>
              </div>

              <span>
                {infosFullFatura ? (
                  <>
                    {infosFullFatura?.lancamentos
                      ?.slice(0, 8)
                      ?.map((item, index) => (
                        <div
                          className={`fatura-v2__table--item ${item.tipo} ${
                            index === 0 ? "first" : ""
                          }`}
                          key={Math.random()}
                        >
                          <div className="item-data">
                            <FaRegCalendarAlt />

                            <p>{item.dataTransacao}</p>
                          </div>
                          <div className="item-info">
                            {item.tipo === "PAGAMENTO" ? (
                              <RiMoneyDollarCircleLine />
                            ) : (
                              ""
                            )}
                            {item.tipo === "VENDA" ? <FaRegBuilding /> : ""}
                            {item.tipo === "AJUSTE" ? <FiAlertTriangle /> : ""}
                            <p>{item.descricaoLancamento}</p>
                          </div>
                          <div className="item-cartao-truncado">
                            {item.numeroCartaoTruncado &&
                                <>
                                  <CiCreditCard1 fontSize={16}/>
                                  {item.numeroCartaoTruncado}
                                </>
                            }
                          </div>
                          <div className="item-dinheiro">
                            <p>{item.valor}</p>
                          </div>
                        </div>
                      ))}
                  </>
                ) : (
                  ""
                )}
              </span>

              <div className="fatura-v2__table--footer">
                {/* <div className="pagination">
                  <p>1 - 4 de 6 resultados</p>
                  <button type="button" className="arrow" disabled>
                    <FaAngleLeft />
                  </button>
                  <button type="button" className="arrow">
                    <FaAngleRight />
                  </button>
                </div> */}

                <div className="invoices-modal">
                  {infosFullFatura?.lancamentos ? (
                    <p>
                      TOTAL DE {infosFullFatura?.lancamentos.length} LANÇAMENTOS
                      EM {faturaSelected?.MesVencimento}{" "}
                      {faturaSelected?.anoVencimento}
                    </p>
                  ) : null}

                  {infosFullFatura?.lancamentos.length > 8 ? (
                    <button
                      type="button"
                      onClick={() => setIsModalLancamentosOpen(true)}
                    >
                      <FiEye />
                      <p>Ver todos os lançamentos</p>
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {/* Modal Alterar data de vencimento */}
      <ModalChangeInvoiceDueDate
        open={isChangeInvoiceDueDateVisible}
        dateCiclos={dateCiclos}
        cicloValue={cicloValue}
        closeModal={() => {
          setIsChangeInvoiceDueDateVisible(false);
        }}
      />

      {/* Modal simular parcelamento da fatura */}
      <InstallmentSimulationModal
        visible={isModalInstallmentSimulationVisible}
        key={isModalInstallmentSimulationVisible}
        onCloseClick={setIsModalInstallmentSimulationVisibility}
        invoices={invoices}
      />

      {/* Modal Simular parcelamento de saldo devedor */}
      <InstallmentSimulationModal
        devedorTotal
        invoices={invoices}
        key={isModalDebitBalanceVisible} // Force re-render (clean state) on state transition.
        title="Parcelamento de saldo devedor"
        visible={isModalDebitBalanceVisible}
        onCloseClick={toggleModalDebitBalanceVisibility}
      />

      {/* Visualizar parcelamento da fatura / saldo devedor */}
      <InstallmentsViewModal
        visible={isModalInstallmentsViewVisible}
        key={isModalInstallmentsViewVisible} // Force re-render (clean state) on state transition.
        onCloseClick={setIsModalInstallmentsViewVisibility}
      />

      {/* Modal enviar fatura */}
      <Modal
        title="Enviar"
        visible={isModalFaturaOpen}
        onCancel={closeModalSendFaturas}
        width={572}
      >
        <Style.ModalFaturaSend>
          {loadingFature ? (
            <div className="modal-fatura__loading">
              <LoadingElevaty />
            </div>
          ) : (
            <>
              <div className="item-select__user">
                <p className="title">Cliente:</p>

                <div className="item-select__options">
                  <Style.DocButton
                    selected={typeFature === "email"}
                    onClick={() => {
                      setTypeFature("email");
                      setValueEmailDownload("");
                      setDisabledInput(false);
                      setDisabledInputEmail(false);
                    }}
                  >
                    <p>E-mail</p>
                  </Style.DocButton>
                  <Style.DocButton
                    selected={typeFature === "sms"}
                    onClick={() => {
                      setTypeFature("sms");
                      setValueFormInputSMS("");
                      setDisabledInputSelectSMS(false);
                      setDisabledInputSMS(false);
                    }}
                  >
                    <p>SMS</p>
                  </Style.DocButton>
                </div>
              </div>

              <div className="item-select__user">
                <div className="input-search">
                  {typeFature === "email" ? (
                    <Formik
                      initialValues={{
                        email: client?.client?.email,
                        sendToUnregisteredMail: false,
                        customEmail: "",
                      }}
                      validationSchema={Yup.object({
                        email: Yup.string()
                          .email("Deve ser um e-mail válido")
                          .nullable(),
                        customEmail: Yup.string().email(
                          "Deve ser um e-mail válido"
                        ),
                        sendToUnregisteredMail: Yup.boolean(),
                      }).test({
                        name: "has-email",
                        test: (value, ctx) => {
                          if (
                            value.sendToUnregisteredMail &&
                            !value.customEmail
                          ) {
                            return ctx.createError({
                              path: "email",
                              message: "Campo Obrigatório",
                            });
                          }

                          if (!value.sendToUnregisteredMail && !value.email) {
                            return ctx.createError({
                              path: "customEmail",
                              message: "Campo Obrigatório",
                            });
                          }
                        },
                      })}
                      onSubmit={handleSendEmailFatura}
                    >
                      {({ setFieldValue, values }) => (
                        <Form>
                          <div className="item-input">
                            <Field name="email">
                              {({ field, meta }) => (
                                <>
                                  <p>Selecione o email: </p>
                                  <Select
                                    value={field?.value || "0"}
                                    disabled={disabledInput}
                                    className={
                                      meta.touched && meta.error
                                        ? "error-id"
                                        : null
                                    }
                                    onChange={(e) =>
                                      handleSelectMail(e, setFieldValue)
                                    }
                                  >
                                    <Option value="0">Selecione</Option>
                                    <Option
                                      value={`${client?.client?.email}`}
                                    >{`${client?.client?.email}`}</Option>
                                  </Select>
                                </>
                              )}
                            </Field>
                          </div>
                          {permissions &&
                            permissions.includes(
                              "sending_unregistered_contacts"
                            ) && (
                              <div className="item-input">
                                <Field name="sendToUnregisteredMail">
                                  {({ field }) => (
                                    <Checkbox
                                      checked={field?.value ?? false}
                                      onChange={(e) =>
                                        handleChangeSendToUnregisteredMail(
                                          e.target.checked,
                                          setFieldValue
                                        )
                                      }
                                    >
                                      Enviar para outro email
                                    </Checkbox>
                                  )}
                                </Field>
                              </div>
                            )}
                          {permissions &&
                            permissions.includes(
                              "sending_unregistered_contacts"
                            ) &&
                            values.sendToUnregisteredMail && (
                              <div className="item-input">
                                <p>Digite o Email</p>
                                <Field name="customEmail">
                                  {({ field, meta }) => (
                                    <input
                                      type="text"
                                      className={
                                        meta.touched && meta.error
                                          ? "error-id"
                                          : null
                                      }
                                      placeholder={
                                        typeFature === "email"
                                          ? "Digite o e-mail que será enviado"
                                          : "Selecione ou digite um telefone de contato"
                                      }
                                      {...field}
                                      disabled={disabledInputEmail}
                                      value={valueEmailDownload}
                                      onChange={(e) =>
                                        handleChangeModalEmailInput(
                                          e,
                                          setFieldValue
                                        )
                                      }
                                    />
                                  )}
                                </Field>
                              </div>
                            )}

                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-message"
                          />
                          <ErrorMessage
                            name="customEmail"
                            component="div"
                            className="error-message"
                          />

                          {/* <div className="item-select-type">
                            <div
                              className={`item-fatura ${
                                typeSendFature === "fatura" ? "active" : ""
                              }`}
                              onClick={() => setTypeSendFature("fatura")}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M6 2H14L20 8V20C20 21.1 19.1 22 18 22H5.99C4.89 22 4 21.1 4 20L4.01 4C4.01 2.9 4.9 2 6 2ZM6 4V20H18V9H13V4H6Z"
                                  fill={
                                    typeSendFature === "fatura"
                                      ? "#FFC53D"
                                      : "#434343"
                                  }
                                />
                              </svg>
                              <p>Fatura</p>

                              <div className="icon-check">
                                <BsCheckCircleFill color="#FFC53D" />
                              </div>
                            </div>
                            <div
                              className={`item-fatura ${
                                typeSendFature === "faturaandboleto"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                setTypeSendFature("faturaandboleto")
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M2 19.5V4.5H2.70312V19.5H2ZM3.0491 19.4893V4.5H3.40625V19.4893H3.0491ZM4.10938 19.4893V4.5H4.45535V19.4893H4.10938ZM5.8616 19.4893V4.5H6.20758V19.4893H5.8616ZM7.26785 19.4893V4.5H7.9598V19.4893H7.26785ZM9.02008 19.4893V4.5H9.36605V19.4893H9.02008ZM9.7232 19.4893V4.5H10.0692V19.4893H9.7232ZM10.4263 19.4893V4.5H10.7723V19.4893H10.4263ZM11.8214 19.4893V4.5H12.5246V19.4893H11.8214ZM13.5737 19.4893V4.5H14.2768V19.4893H13.5737ZM14.9799 19.4893V4.5H15.683V19.4893H14.9799ZM16.3862 19.4893V4.5H17.0893V19.4893H16.3862ZM17.4353 19.4893V4.5H18.1384V19.4893H17.4353ZM19.1987 19.4893V4.5H20.2478V19.4893H19.1987ZM20.5938 19.4893V4.5H20.9509V19.4893H20.5938ZM21.2969 19.5V4.5H22V19.5H21.2969Z"
                                  fill={
                                    typeSendFature === "faturaandboleto"
                                      ? "#FFC53D"
                                      : "#434343"
                                  }
                                />
                              </svg>
                              <p>Fatura + Boleto</p>
                              <div className="icon-check">
                                <BsCheckCircleFill color="#FFC53D" />
                              </div>
                            </div>
                          </div> */}

                          <div className="item-submit">
                            <button type="submit">
                              <MdOutlineSend /> <p>Enviar</p>
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  ) : null}
                  {typeFature === "sms" ? (
                    <Formik
                      key={false}
                      initialValues={{
                        phone: phones.length === 1 ? phones[0] : "",
                        sendToUnregisteredPhone: false,
                        customPhone: "",
                      }}
                      validationSchema={Yup.object({
                        phone: Yup.string().nullable(),
                        sendToUnregisteredPhone: Yup.boolean(),
                        customPhone: Yup.string(),
                      }).test({
                        name: "has-phone",
                        test: (values, ctx) => {
                          if (
                            !values.sendToUnregisteredPhone &&
                            !values.phone
                          ) {
                            return ctx.createError({
                              path: "phone",
                              message: "Insira um número válido",
                            });
                          }

                          if (
                            !values.sendToUnregisteredPhone &&
                            values.phone &&
                            values.phone.length < 14
                          ) {
                            return ctx.createError({
                              path: "phone",
                              message: "Número inválido",
                            });
                          }

                          if (
                            values.sendToUnregisteredPhone &&
                            !values.customPhone
                          ) {
                            return ctx.createError({
                              path: "customPhone",
                              message: "Insira um número válido",
                            });
                          }

                          if (
                            values.sendToUnregisteredPhone &&
                            values.customPhone &&
                            values.customPhone.length < 14
                          ) {
                            return ctx.createError({
                              path: "customPhone",
                              message: "Número inválido",
                            });
                          }
                        },
                      })}
                      onSubmit={handleSendSMSFatura}
                    >
                      {({ setFieldValue, values }) => (
                        <Form>
                          <div className="item-input">
                            <Field name="phone">
                              {({ field, meta }) => (
                                <>
                                  <p>Selecione o número: </p>
                                  <Select
                                    value={field?.value || "0"}
                                    disabled={disabledInputSelectSMS}
                                    className={
                                      meta.touched && meta.error
                                        ? "error-id"
                                        : null
                                    }
                                    onChange={(e) =>
                                      handleSelectSMS(e, setFieldValue)
                                    }
                                  >
                                    <Option value="0">Selecione</Option>
                                    {phones.map((_phone) => (
                                      <Option key={_phone} value={_phone}>
                                        {_phone}
                                      </Option>
                                    ))}
                                  </Select>
                                </>
                              )}
                            </Field>
                          </div>
                          {permissions &&
                            permissions.includes(
                              "sending_unregistered_contacts"
                            ) && (
                              <div className="item-input">
                                <Field name="sendToUnregisteredPhone">
                                  {({ field }) => (
                                    <Checkbox
                                      checked={field?.value ?? false}
                                      onChange={(e) =>
                                        handleChangeSendToUnregisteredSMS(
                                          e.target.checked,
                                          setFieldValue
                                        )
                                      }
                                    >
                                      Enviar para outro número
                                    </Checkbox>
                                  )}
                                </Field>
                              </div>
                            )}

                          {permissions &&
                            permissions.includes(
                              "sending_unregistered_contacts"
                            ) &&
                            values.sendToUnregisteredPhone && (
                              <div className="item-input">
                                <p>Digite o número</p>
                                <Field name="customPhone">
                                  {({ field, meta }) => (
                                    <ReactInputMask
                                      type="text"
                                      className={
                                        meta.touched && meta.error
                                          ? "error-id"
                                          : null
                                      }
                                      placeholder={
                                        typeFature === "email"
                                          ? "Digite o e-mail que será enviado"
                                          : "Selecione ou digite um telefone de contato"
                                      }
                                      mask="(99) 99999-9999"
                                      maskChar=""
                                      disabled={disabledInputSMS}
                                      {...field}
                                      onChange={(e) =>
                                        handleChangeModalSMSlInput(
                                          e,
                                          setFieldValue
                                        )
                                      }
                                      value={valueFormInputSMS}
                                    />
                                  )}
                                </Field>
                              </div>
                            )}

                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="error-message"
                          />
                          <ErrorMessage
                            name="customPhone"
                            component="div"
                            className="error-message"
                          />
                          {/* <div className="item-select-type">
                            <div
                              className={`item-fatura ${
                                typeSendFature === "fatura" ? "active" : ""
                              }`}
                              onClick={() => setTypeSendFature("fatura")}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M6 2H14L20 8V20C20 21.1 19.1 22 18 22H5.99C4.89 22 4 21.1 4 20L4.01 4C4.01 2.9 4.9 2 6 2ZM6 4V20H18V9H13V4H6Z"
                                  fill={
                                    typeSendFature === "fatura"
                                      ? "#FFC53D"
                                      : "#434343"
                                  }
                                />
                              </svg>
                              <p>Fatura</p>

                              <div className="icon-check">
                                <BsCheckCircleFill color="#FFC53D" />
                              </div>
                            </div>
                            <div
                              className={`item-fatura ${
                                typeSendFature === "faturaandboleto"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                setTypeSendFature("faturaandboleto")
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M2 19.5V4.5H2.70312V19.5H2ZM3.0491 19.4893V4.5H3.40625V19.4893H3.0491ZM4.10938 19.4893V4.5H4.45535V19.4893H4.10938ZM5.8616 19.4893V4.5H6.20758V19.4893H5.8616ZM7.26785 19.4893V4.5H7.9598V19.4893H7.26785ZM9.02008 19.4893V4.5H9.36605V19.4893H9.02008ZM9.7232 19.4893V4.5H10.0692V19.4893H9.7232ZM10.4263 19.4893V4.5H10.7723V19.4893H10.4263ZM11.8214 19.4893V4.5H12.5246V19.4893H11.8214ZM13.5737 19.4893V4.5H14.2768V19.4893H13.5737ZM14.9799 19.4893V4.5H15.683V19.4893H14.9799ZM16.3862 19.4893V4.5H17.0893V19.4893H16.3862ZM17.4353 19.4893V4.5H18.1384V19.4893H17.4353ZM19.1987 19.4893V4.5H20.2478V19.4893H19.1987ZM20.5938 19.4893V4.5H20.9509V19.4893H20.5938ZM21.2969 19.5V4.5H22V19.5H21.2969Z"
                                  fill={
                                    typeSendFature === "faturaandboleto"
                                      ? "#FFC53D"
                                      : "#434343"
                                  }
                                />
                              </svg>
                              <p>Fatura + Boleto</p>
                              <div className="icon-check">
                                <BsCheckCircleFill color="#FFC53D" />
                              </div>
                            </div>
                          </div> */}

                          <div className="item-submit">
                            <button type="submit">
                              <MdOutlineSend /> <p>Enviar</p>
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  ) : null}
                </div>
              </div>
            </>
          )}
        </Style.ModalFaturaSend>
      </Modal>

      {/* Modal ver todos lançamentos */}
      <Modal
        title={`Lançamentos de ${faturaSelected?.MesVencimento}/${faturaSelected?.anoVencimento}`}
        visible={isModalLancamentosOpen}
        onCancel={() => setIsModalLancamentosOpen(false)}
        width={795}
      >
        <Style.WrapperFaturaV2 table>
          <div className="fatura-v2__table">
            <div className="fatura-v2__table--header">
              <div className="fatura-v2__table--header__data">
                <p>Data</p>
              </div>
              <div className="fatura-v2__table--header__local">
                <p>Local</p>
              </div>
              <div className="fatura-v2__table--header__cartao">
                <p>Cartão truncado</p>
              </div>
              <div className="fatura-v2__table--header__real">
                <p>Valor em Real</p>
              </div>
            </div>

            {infosFullFatura?.lancamentos.map((item, index) => (
              <div
                className={`fatura-v2__table--item ${item.tipo} ${
                  index === 0 ? "first" : ""
                }`}
                key={Math.random()}
              >
                <div className="item-data">
                  <FaRegCalendarAlt />

                  <p>{item.dataTransacao}</p>
                </div>
                <div className="item-info">
                  {item.tipo === "PAGAMENTO" ? <RiMoneyDollarCircleLine /> : ""}
                  {item.tipo === "VENDA" ? <FaRegBuilding /> : ""}
                  {item.tipo === "AJUSTE" ? <FiAlertTriangle /> : ""}
                  <p>{item.descricaoLancamento}</p>
                </div>
                <div className="item-cartao-truncado">
                  {item.numeroCartaoTruncado &&
                      <>
                        <CiCreditCard1 fontSize={16}/>
                        {item.numeroCartaoTruncado}
                      </>
                  }
                </div>
                <div className="item-dinheiro">
                  <p>{item.valor}</p>
                </div>
              </div>
            ))}
          </div>
        </Style.WrapperFaturaV2>
      </Modal>

      {/* Modal Histórico de faturas */}
      <ModalHistoricoFaturas
        isOpen={modalHistoricoFaturas}
        onClose={setModalHistoricoFaturas}
      />
    </Style.WrapperFaturaV2>
  );
}

export default memo(FaturaV2);
