import React, { memo, useCallback, useEffect, useRef, useState } from "react";

import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Tabs, Dropdown, Tooltip } from "antd";
import { FaPlus } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import cardIcon from '../../../assets/card-icon.png';
import magnifyingGlassIcon from '../../../assets/lupa-faqs.png';
import Card from "../../../components/Card";
import CreditCard from "../../../components/creditCard";
import AddCardModal from "../../../components/creditCard/modals/addCard";
import { CreditCardV2 } from "../../../components/CreditCardV2/CreditCardV2";
import { StatusCard } from "../../../components/CreditCardV2/StatusCard";
import LoadingElevaty from "../../../components/LoadingElevaty";
import { usePermissions } from "../../../Context/permissionContext";
import { useAuth } from "../../../hooks/AuthProvider";
import { useClient } from "../../../hooks/ClientProvider";
import useDependentCard from "../../../hooks/useDependentCard";
import useHolderCard from "../../../hooks/useHolderCard";
import api from "../../../services/api";
import { hasCryptKeyCard } from "../../../utils/crypto";
import { getPlaces } from '../../../utils/getStores';
import { StyledTabs } from "../style";
import * as S from "../style";
import CardMenu from "./CardMenu";
import CardsInTransition from "./CardsInTransition";
import ConfirmAction from "./modals/confirmAction";
import { TabsCard } from "./TabsCard/TabsCard";

const { TabPane } = Tabs;
const ThumbnailsMenu = ({ sliderRefHistory, cards, currentSlide, onClick, title, showAddButton, onAddButtonTooltip, onAddClick, showArrows }) => {
  const firstThreeCards = cards;
  const thumbnailsRef = useRef(null);

  const moveLeft = () => {
    sliderRefHistory.current.slickPrev();
  };

  const moveRight = () => {
    sliderRefHistory.current.slickNext();
  };

  useEffect(() => {
    if (!thumbnailsRef.current || isNaN(currentSlide)) {
      return;
    }

    const leftPosition = currentSlide > 0 ? currentSlide * 48 : 0;
    thumbnailsRef.current.scrollTo({left: leftPosition, behavior: "smooth"});
  }, [currentSlide])

  return (
    <div className="thumbnails-menu-container">
      <h2>{title}</h2>

      <div className="thumbnails-container" ref={thumbnailsRef}>

        {showAddButton && (
          <Tooltip title={onAddButtonTooltip}>
            <button className="plus-rounded" type="button" onClick={onAddClick}>
              <FaPlus />
            </button>
          </Tooltip>
        )}
        {showArrows && (
          <span  type="button" className="thumbnail-navigate" onClick={moveLeft}>
            <CaretLeftOutlined />
          </span>
        )}

        {firstThreeCards.map((card, index) => (
          <div
            key={card.id}
            className={`thumbnail ${index === currentSlide ? 'active' : ''}`}
            onClick={() => onClick(index)}
          >
            <img src={cardIcon} alt="Thumbnail" className="thumbnail-icon" />
          </div>
        ))}

        {showArrows && (
          <span  type="button" className="thumbnail-navigate" onClick={moveRight}>
            <CaretRightOutlined />
          </span>
        )}
      </div>
    </div>
  );
};

function CardsComponent() {
  const { user } = useAuth();
  const { permissions } = usePermissions();
  const { client } = useClient();
  const [lojas, setLojas] = useState([]);
  const isProdutoBandeirado = client?.client.isProdutoBandeirado;
  const [dataPrintPdf] = useState([]);
  const [decryptCardNumber, setDecryptCardNumber] = useState("");
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isGenerateModalVisible, setIsGenerateModalVisible] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlideDependent, setCurrentSlideDependent] = useState(0);
  const [currentSlideHistory, setCurrentSlideHistory] = useState(0);
  const sliderRef = useRef();
  const sliderRefDependent = useRef();
  const sliderRefHistory = useRef();

  const {
    loadingHolderCard,
    holderCards,
    invalidCardsHistory,
    unBloqCard,
    bloqCard,
    cancelCard,
    getDataFormatedCards,
    getDataHistory
  } = useHolderCard(
    client?.client?.id,
    client?.product?.codigoProduto,
    client?.client?.cpf
  );

  const {
    dependantCards,
  } = useDependentCard(
    client?.client?.adicional,
    client?.product?.codigoProduto
  );
  const encryptedCardNumber = client.information.numeroCartaoCriptografado;
  const descryptCard = useCallback(async () => {
    try {
      const resp = await api.post("decrypt", {
        encryptedCardNumber,
      });
      await setDecryptCardNumber(resp.data);
    } catch (error) {
      console.error("Cartao nao descriptografado!");
      setDecryptCardNumber(null);
    }
  }, [encryptedCardNumber]);

  const getLojas = useCallback(async () => {
    try {
      const places = await getPlaces(user);
      setLojas(places.filter((a) => a.id >= 1));
    } catch (error) {
      console.error("Falha ao listar lojas");
    }
  }, []);

  const handleAfterChangeDependent = (index) => {
    setCurrentSlideDependent(index);
  };

  const handleAfterChangeHistory = (index) => {
    setCurrentSlideHistory(index);
  };

  const settingsHolder = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: false,
    centerMode: true,
    centerPadding: '10px',
    afterChange: setCurrentSlide,
  };

  const settingsDependent = {
    ...settingsHolder,
    afterChange: handleAfterChangeDependent,
  };

  const settingsHistory = {
    ...settingsHolder,
    afterChange: handleAfterChangeHistory,
  };

  const titleHolder = holderCards.length > 1 ? 'Cartões' : 'Cartão';
  const titleDependent = dependantCards.length > 1 ? 'Cartões Adicionais' : 'Cartão Adicional';

  useEffect(async () => {
    const hasCryptKey = await hasCryptKeyCard();
    if(hasCryptKey) descryptCard();

    getLojas();
  }, [descryptCard, getLojas]);

  const toggleAddModal = useCallback(() => {
    setIsAddModalVisible((state) => !state);
  }, []);

  const handleGenerateCardHolder = useCallback(async () => {
    setIsGenerateModalVisible(true);
  }, []);

  function orderCardByStatus(a, b) {
    if (a.status === "ATIVO") {
        return -1;
    }
    if (b.status === "ATIVO") {
        return 1;
    }
    return 0;
  }

  return (
    <>
      <S.WrapperCards>
        {loadingHolderCard ? (
          <S.WrapperNewLoading className="ant-card-bordered" >
            <LoadingElevaty />
          </S.WrapperNewLoading>
        ) : (
          <Card
            modalTitle="Cartões"
            loading={loadingHolderCard}
          >
            <TabsCard
              tabs={[
                {
                  title: "TItular",
                  content: (
                    <S.CreditCardWrapper>
                     {holderCards.length === 0 ? (
  <div className="adicional__card">
    <div className="adicional__card--header">
      {permissions && permissions.includes("add") && (
        <>
          <div>
            <h2>Cartões</h2>
          </div>
          <div>
            <Tooltip title="Adicionar Cartão Titular">
              <button
                className="plus-rounded"
                type="button"
                onClick={handleGenerateCardHolder}
              >
                <FaPlus />
              </button>
            </Tooltip>
          </div>
        </>
      )}
    </div>
    <div className="adicional__card--bar" />
    <div className="adicional__card--container">
      <div className="adicional__card--content">
        <div>
          <img
            src={magnifyingGlassIcon}
            alt="Lupa"
            className="magnifier-icon"
          />
        </div>
        <p>Não possui cartão cadastrado </p>
        {permissions && permissions.includes("add") && (
          <button
            type="button"
            aria-label="btn-add-new"
            onClick={handleGenerateCardHolder}
          >
            <span>
              <FaPlus /> Adicionar Titular
            </span>
          </button>
        )}
      </div>
    </div>
  </div>
) : holderCards.length > 1 ? (
  <>
    <ThumbnailsMenu
      cards={holderCards}
      currentSlide={currentSlide}
      onClick={(index) => {
        sliderRef.current.slickGoTo(index);
      }}
      title={titleHolder}
      showAddButton={
        permissions &&
        permissions.includes("view_button_generate_card_holder")
      }
      onAddButtonTooltip="Adicionar Cartão Titular"
      onAddClick={handleGenerateCardHolder}
    />
    <div className="underline-bar" />
    <Slider {...settingsHolder} ref={sliderRef} className="card-slides">
      {holderCards.sort(orderCardByStatus).map((card) => (
        <>
          <CreditCardV2
            newClass="v2"
            key={card?.id}
            card={card}
            isDependent={false}
            reloadCards={getDataFormatedCards}
            decryptCardNumber={decryptCardNumber}
          />
          <div className="centralizeStatus">
            <StatusCard card={card} />
          </div>
          <CardMenu
            productID={client?.product?.codigoProduto}
            clientID={client ? client.client.id : null}
            isDependent={false}
            isBandeirado={client ? isProdutoBandeirado : false}
            reloadCards={getDataFormatedCards}
            bloqCard={bloqCard}
            card={card}
            decryptCardNumber={decryptCardNumber}
            onExpandContentHeight={() => {
              // Access inner slider forceUpdate method in order to re-render the slick-list container's height
              sliderRef.current?.innerSlider?.forceUpdate();
            }}
          />
        </>
      ))}
    </Slider>
  </>
) : (
  holderCards.map((card) => (
    <>
      <ThumbnailsMenu
        cards={holderCards}
        currentSlide={currentSlide}
        onClick={(index) => {
          sliderRef.current.slickGoTo(index);
        }}
        title={titleHolder}
        showAddButton={
          permissions &&
          permissions.includes("view_button_generate_card_holder")
        }
        onAddButtonTooltip="Adicionar Cartão Titular"
        onAddClick={handleGenerateCardHolder}
      />
      <div className="underline-bar" />
      <>
        <CreditCardV2
          newClass="v2"
          key={card?.id}
          card={card}
          isDependent={false}
          reloadCards={getDataFormatedCards}
          decryptCardNumber={decryptCardNumber}
        />
        <div className="centralizeStatus">
          <StatusCard card={card} />
        </div>
        <CardMenu
          productID={client?.product?.codigoProduto}
          clientID={client ? client.client.id : null}
          isDependent={false}
          isBandeirado={client ? isProdutoBandeirado : false}
          reloadCards={getDataFormatedCards}
          bloqCard={bloqCard}
          card={card}
          decryptCardNumber={decryptCardNumber}
        />
      </>
    </>
  ))
)}

                      {client?.client?.canMakeTransitionToPf && (
                        <CardsInTransition reloadCards={getDataFormatedCards} />
                      )}
                    </S.CreditCardWrapper>
                  ),
                },
                {
                  title: "Adicional",
                  content: (
                    <S.CreditCardWrapper>
                      {dependantCards.length === 0 ? (
                        <div className="adicional__card">
                          <div className="adicional__card--header">
                            {permissions && permissions.includes("add") && (
                              <>
                                <div>
                                  <h2>Cartões</h2>
                                </div>
                                <div>
                                  <Tooltip title="Adicionar Cartão Adicional">
                                    <button
                                      className="plus-rounded"
                                      type="button"
                                      onClick={toggleAddModal}
                                    >
                                      <FaPlus />
                                    </button>
                                  </Tooltip>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="adicional__card--bar" />
                          <div className="adicional__card--container">
                            <div className="adicional__card--content">
                              <div>
                                <img
                                  src={magnifyingGlassIcon}
                                  alt="Lupa"
                                  className="magnifier-icon"
                                />
                              </div>
                              <p>Não possui cartão cadastrado </p>
                              {permissions && permissions.includes("add") && (
                                <button
                                  type="button"
                                  aria-label="btn-add-new"
                                  onClick={toggleAddModal}
                                >
                                  <span>
                                    <FaPlus /> Adicionar adicional
                                  </span>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          {dependantCards.length > 1 ? (
                            <>
                              <ThumbnailsMenu
                                cards={dependantCards}
                                currentSlide={currentSlideDependent}
                                onClick={(index) => {
                                  sliderRefDependent.current.slickGoTo(index);
                                }}
                                title={titleDependent}
                                showAddButton={
                                  permissions && permissions.includes("add")
                                }
                                onAddButtonTooltip="Adicionar Cartão Adicional"
                                onAddClick={toggleAddModal}
                              />
                              <div className="underline-bar" />
                              <Slider
                                {...settingsDependent}
                                ref={sliderRefDependent}
                                className="card-slides"
                              >
                                {dependantCards.map((card) => (
                                  <>
                                    <CreditCardV2
                                      newClass="v2"
                                      key={card?.id}
                                      card={card}
                                      isDependent
                                      reloadCards={getDataFormatedCards}
                                      decryptCardNumber={decryptCardNumber}
                                    />
                                    <div className="centralizeStatus">
                                      <StatusCard card={card} />
                                    </div>
                                    <CardMenu
                                      productID={client?.product?.codigoProduto}
                                      clientID={
                                        client ? client.client.id : null
                                      }
                                      isDependent
                                      isBandeirado={
                                        client ? isProdutoBandeirado : false
                                      }
                                      bloqCard={bloqCard}
                                      reloadCards={getDataFormatedCards}
                                      card={card}
                                      decryptCardNumber={decryptCardNumber}
                                      onExpandContentHeight={() => {
                                        // Access inner slider forceUpdate method in order to re-render the slick-list container's height
                                        sliderRefDependent.current?.innerSlider?.forceUpdate()
                                      }}
                                    />
                                  </>
                                ))}
                              </Slider>
                            </>
                          ) : (
                            dependantCards.map((card) => (
                              <>
                                <ThumbnailsMenu
                                  cards={dependantCards}
                                  currentSlide={currentSlideDependent}
                                  onClick={(index) => {
                                    sliderRefDependent.current.slickGoTo(index);
                                  }}
                                  title={titleDependent}
                                  showAddButton={
                                    permissions && permissions.includes("add")
                                  }
                                  onAddButtonTooltip="Adicionar Cartão Adicional"
                                  onAddClick={toggleAddModal}
                                />
                                <div className="underline-bar" />
                                <CreditCardV2
                                  newClass="v2"
                                  key={card?.id}
                                  card={card}
                                  isDependent
                                  reloadCards={getDataFormatedCards}
                                  decryptCardNumber={decryptCardNumber}
                                />
                                <div className="centralizeStatus">
                                  <StatusCard card={card} />
                                </div>
                                <CardMenu
                                  productID={client?.product?.codigoProduto}
                                  clientID={client ? client.client.id : null}
                                  isDependent
                                  isBandeirado={
                                    client ? isProdutoBandeirado : false
                                  }
                                  bloqCard={bloqCard}
                                  reloadCards={getDataFormatedCards}
                                  card={card}
                                  decryptCardNumber={decryptCardNumber}
                                />
                              </>
                            ))
                          )}
                        </>
                      )}
                    </S.CreditCardWrapper>
                  ),
                },
                invalidCardsHistory?.length > 0
                  ? {
                      title: "Histórico",
                      content: (
                        <S.CreditCardWrapper>
                          <ThumbnailsMenu
                            cards={invalidCardsHistory}
                            currentSlide={currentSlideHistory}
                            onClick={(index) => {
                              sliderRefHistory.current.slickGoTo(index);
                            }}
                            title="Histórico"
                            sliderRefHistory={sliderRefHistory}
                          />
                          <div className="underline-bar" />
                          <Slider
                            {...settingsHistory}
                            ref={sliderRefHistory}
                            className="card-slides"
                          >
                            {invalidCardsHistory.map((card) => (
                              <div key={card?.id}>
                                <CreditCardV2
                                  card={card}
                                  isDependent={false}
                                  reloadCards={getDataFormatedCards}
                                  isHistoryCard
                                />
                                <div className="centralizeStatus">
                                  <StatusCard card={card} />
                                </div>
                              </div>
                            ))}
                          </Slider>
                        </S.CreditCardWrapper>
                      ),
                    }
                  : null,
              ].filter(Boolean)}
            />

          </Card>
        )}
      </S.WrapperCards>

      {isAddModalVisible && <AddCardModal closeModal={toggleAddModal} />}

      {isGenerateModalVisible && (
        <ConfirmAction
          isModalVisible={isGenerateModalVisible}
          closeModal={() => {
            setIsGenerateModalVisible(false);
          }}
          getDataFormatedCards={getDataFormatedCards}
          clientId={client?.client?.id}
          reloadCards={getDataFormatedCards}
        />
      )}
    </>
  );
}

export const Cards = memo(CardsComponent);
