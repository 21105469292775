import React, { useCallback, useState } from "react";

import { SendOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { usePermissions } from "../../../Context/permissionContext";
import { useClient } from "../../../hooks/ClientProvider";
import { ModalSendMailOrSms } from "../../../pages/home/modals/ModalSendMailOrSms/ModalSendMailOrSms";
import api from "../../../services/api";
import { getCompanyName } from "../../../utils/companyName";
import { formatPhone } from "../../../utils/formatPhone";
import { notifyError, notifySucces } from "../../../utils/notifications";

export function SendTicketModalWrapper({ ticket }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientPhones, setClientPhones] = useState([]);

  const { client } = useClient();
  const { permissions } = usePermissions();

  const hasPermissionToSendTicketByEmail =
    permissions && permissions.includes("send_ticket_by_email");

  const handleOpenModal = useCallback(async () => {
    try {
      setLoading(true);
      setIsModalOpen(true);
      const clientPhonesResponse = await api.get("client_contacts", {
        client_id: client?.client?.id,
      });
      const filteredClientPhones = Array.isArray(clientPhonesResponse.data)
        ? clientPhonesResponse.data.filter(
            (number) => number.tipo === "CELULAR"
          )
        : [];
      const formattedPhones = filteredClientPhones.map((number) =>
        formatPhone(number?.area, number?.numero)
      );
      setClientPhones(formattedPhones);
    } catch (error) {
      const message =
        error?.message ?? "Houve um erro ao carregar os telefones.";
      notifyError(message);
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [client]);

  const handleSubmitMail = useCallback(
    async (mail) => {
      try {
        const companyName = getCompanyName();
        let clientName = client.client.name;
        clientName = clientName.trim()
          .split(" ")
          .map(
            (subname) =>
              subname[0].toUpperCase() + subname.slice(1).toLowerCase()
          )
          .join(" ");
        setLoading(true);
        await api.post("send_email_protocol", {
          ticket,
          email: mail,
          client_name: clientName,
          company_name: companyName[0].toUpperCase() + companyName.slice(1),
          client_id: client.client.id,
          product_code: client.product.codigoProduto,
          product_description: client.product.desc_prod,
        });
        setIsModalOpen(false);
        notifySucces(`Sucesso ao enviar email para ${mail}`);
      } catch (error) {
        notifyError(`Falha ao enviar email para ${mail}`);
      } finally {
        setLoading(false);
      }
    },
    [ticket, client]
  );

  const handleSubmitSms = useCallback(
    async (phone) => {
      try {
        setLoading(true);
        if (!phone || isNaN(Number(phone.replace(/[^\d]/g, "")))) {
          throw new Error("Número de telefone inválido.");
        }

        const phoneNumber = phone.replace(/[^\d]/g, "");

        await api.post("send_sms_protocol", {
          phone: phoneNumber,
          ticket,
          client_id: client.client.id,
          product_code: client.product.codigoProduto,
          product_description: client.product.desc_prod,
        });
        setIsModalOpen(false);
        notifySucces(`Sms enviado para ${phone}`);
      } catch (error) {
        notifyError(`Falha ao enviar sms para ${phone}`);
      } finally {
        setLoading(false);
      }
    },
    [ticket, client]
  );

  return (
    <>
      <Button type="button" onClick={handleOpenModal}>
        <SendOutlined />
      </Button>
      <ModalSendMailOrSms
        isMailEnabled={hasPermissionToSendTicketByEmail}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        phones={clientPhones}
        isLoading={loading}
        clientMail={client?.client?.email}
        onSubmitMail={handleSubmitMail}
        onSubmitSms={handleSubmitSms}
      />
    </>
  );
}
