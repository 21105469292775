import { decrypt, encodeBase64 } from "./crypto";

const getHasCobransaas = () => {
  const encodedCompanyKey = encodeBase64('@SnxPayment:company');
  const encryptedCompanyData = localStorage.getItem(encodedCompanyKey);

  if (!encryptedCompanyData) return false;
  const decryptedCompanyData = decrypt(encryptedCompanyData);
  try {
    const company = JSON.parse(decryptedCompanyData);
    return !!company.has_cobransaas;
  } catch (error) {
    console.error('Failed to parse company data:', error);
    return false;
  }
}

export { getHasCobransaas };
