import React, { ChangeEventHandler, FormEventHandler, memo } from 'react';

import { LockOutlined } from '@ant-design/icons';
import { Button, Input, Modal } from 'antd';

import { decrypt, encodeBase64 } from '../../../../../utils/crypto';
import classes from './setPassword.module.scss';

interface Company {
  card_password_length?: number;
}

interface SetPasswordModalProps {
  closeModal: () => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
  loading: boolean;
  valuePassword: string;
  valueConfirm: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onChangeConfirm: ChangeEventHandler<HTMLInputElement>;
}
function SetPassword({
  closeModal,
  loading,
  valuePassword,
  valueConfirm,
  onChange,
  onChangeConfirm,
  onSubmit,
}: SetPasswordModalProps) {

  const encodedClientKey = encodeBase64('@SnxPayment:company');
  const cipherText = localStorage.getItem(encodedClientKey);
  let company: Company = {};

  if (cipherText) {
    const decryptedText = decrypt(cipherText);
    company = JSON.parse(decryptedText || '{}');
  }
  const cardPasswordLength = company.card_password_length || 4;


  return (
    <Modal title="Alterar Senha" visible onCancel={closeModal}>
      <form className={classes.setPassword} onSubmit={onSubmit}>
        <p>
          {`
            Deseja realmente alterar a senha do cartão? A nova senha deve conter ${cardPasswordLength}
            dígitos numéricos
          `}
        </p>

        <Input
          prefix={<LockOutlined />}
          placeholder="Nova senha"
          value={valuePassword}
          name='password'
          disabled={loading}
          type="password"
          maxLength={cardPasswordLength}
          onChange={onChange}
        />
        <p />

        <Input
          prefix={<LockOutlined />}
          placeholder="Confirme a nova senha"
          style={{ width: '100%' }}
          name='passwordConfirm'
          type="password"
          value={valueConfirm}
          onChange={onChangeConfirm}
          maxLength={cardPasswordLength}
        />

        <div className={classes.setPassword__actions}>
          <Button disabled={loading} onClick={closeModal}>
            Cancelar
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={
              valuePassword.length < cardPasswordLength ||
              valuePassword !== valueConfirm
            }
          >
            Alterar Senha
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default memo(SetPassword);