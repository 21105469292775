import React, { useCallback, useEffect, useState } from "react";

import { Button, Modal, Table } from "antd";

import LoadingElevaty from "../../../components/LoadingElevaty";
import TableRadio from "../../../components/Table";
import { useClient } from "../../../hooks/ClientProvider";
import api from "../../../services/api";
import { notifyError, notifySucces } from "../../../utils/notifications";
import * as S from "./styles";

function ModalListAnnuities({
  closeModal,
  open,
  reloadPremiumAnnuity,
  listAnnuities,
}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [annuity, setAnnuity] = useState([]);
  const [loading, setLoading] = useState(false);
  const { client } = useClient();

  const columnPremiumAnnuity = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Descricao",
      dataIndex: "descricao",
      key: "descricao",
    },
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
      render: (valor) => <span>R$ {String(valor).replace(".", ",")}</span>,
    },
    Table.SELECTION_COLUMN,
  ];

  useEffect(async () => {
    const { data } = await api.get(`client/${client?.client?.id}/annuity`);
    if (data.isBonificada) {
      setSelectedRowKeys([data.id]);
    }
  }, []);

  const handleEngage = useCallback(async () => {
    try {
      setLoading(true);
      const { data: phones } = await api.get("client_contacts", {
        client_id: client.client.id,
      });

      if (phones.length === 0) {
        notifyError("Erro! Nenhum telefone cadastrado");
        return;
      }
      const hasBonusPhone = phones.find(
        (phone) => phone.utilizadoTaxaBonificada
      );

      if (!hasBonusPhone && annuity?.isBonificada) {
        notifyError(
          "Erro! O cliente não possui nenhum celular com disponibilidade para recebimento de bônus. Tente novamente!"
        );
        return;
      }

      await api.patch(`client/${client.client.id}/annuity`, {
        id_anuidade: selectedRowKeys[0],
      });

      api.post("timeline", {
        description: `Anuidade de id ${annuity.id} foi contratada com sucesso.`,
        client_id: client.client.id,
      });

      notifySucces("Anuidade bonificada foi contratada com sucesso!");
      reloadPremiumAnnuity();
    } catch (err) {
      notifyError("Falha ao contratar anuidade");
    } finally {
      setLoading(false);
      closeModal();
    }
  });

  const onSelectChange = (newSelectedRowKeys, annuitySelected) => {
    setAnnuity(annuitySelected[0]);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Modal
      visible={open}
      onCancel={closeModal}
      width={800}
      title="Contratar anuidade"
    >
      {loading && (
        <S.NewLoadingModals>
          <LoadingElevaty width="40px" height="40px" duration="durFast" />
        </S.NewLoadingModals>
      )}
      <S.ModalContainer>
        <TableRadio
          rowKey="id"
          rowSelection={{ type: "radio", ...rowSelection }}
          rows={listAnnuities}
          columns={columnPremiumAnnuity}
          page={10}
        />
      </S.ModalContainer>
      <S.ModalBodyAnnuity style={{ display: "flex", justifyContent: "end" }}>
        {!loading && <Button onClick={() => closeModal()}>Cancelar</Button>}
        {!loading && <Button onClick={() => handleEngage()}>Contratar</Button>}
      </S.ModalBodyAnnuity>
    </Modal>
  );
}

export default ModalListAnnuities;
