import React, { useState, useEffect, useCallback } from "react";

import { decrypt, encodeBase64 } from "../utils/crypto";

const useCompany = () => {
  const [companyData, setCompanyData] = useState(null);
  const COMPANY_CRIPT_KEY = "@SnxPayment:company";
  const COMPANY_DECRIPTED_KEY_LENGTH = 12;

  const getCompanyData = useCallback(() => {
    const encodedCompanyCriptKey = encodeBase64(COMPANY_CRIPT_KEY);
    const encryptedCompanyData = localStorage.getItem(encodedCompanyCriptKey);

    if (!encryptedCompanyData) return {}
    const stringifiedDecryptedCompanyData = decrypt(encryptedCompanyData);
    try {
      const companyData = JSON.parse(stringifiedDecryptedCompanyData);
      return companyData;
    } catch (error) {
      console.log("Erro ao parsear dados da company: ", error.message)
      return {};
    }

  }, [])

  useEffect(() => {
    const fetchCompanyData = () => {
      const companyData = getCompanyData();
      setCompanyData(companyData);
    }
    fetchCompanyData();
    const handleStorageChange = () => {
      fetchCompanyData()
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    }
  }, []);

  return companyData;
}

export default useCompany;