import React, { useCallback, useEffect, useState } from 'react';

import { Button, Modal, Select } from 'antd';

import Table from '../../../../components/Table';
import api from '../../../../services/api';
import { notifyError, notifySucces } from '../../../../utils/notifications';
import * as S from '../style';
import ButtonActionTable from './ButtonActionTable';

const { Option } = Select;

function ModalClassificationsRelations({
  editModalOpen,
  setEditModalOpen,
  selectedGroupId,
}) {
  const [classifications, setClassifications] = useState([]);
  const [allClassifications, setAllClassifications] = useState([]);
  const [classificationSelected, setClassificationSelected] = useState({});

  const handleClassification = useCallback((valueId, { valueName }) => {
    setClassificationSelected({
      id: valueId,
      name: valueName,
    });
  }, []);

  const fetchClassification = useCallback(async () => {
    try {
      const response = await api.get('classification');
      setAllClassifications(response.data);
    } catch (error) {
      setAllClassifications([]);
      console.error('Nao foi possivel carregar as classificacões');
    }
  }, []);

  useEffect(() => {
    fetchClassification();
  }, [fetchClassification]);

  useEffect(async () => {
    if (editModalOpen) {
      const response = await api.get('role_classification', {
        role_id: selectedGroupId,
      });
      setClassifications(response.data);
    }
  }, [editModalOpen]);

  async function handleClickRelations() {
    if (!classificationSelected.id) {
      notifyError('Selecione uma classificação');
    }

    try {
      await api.post('relate_classifications', {
        classification_id: classificationSelected.id,
        role_id: selectedGroupId,
      });

      const response = await api.get('role_classification', {
        role_id: selectedGroupId,
      });

      setClassifications(response.data);
      notifySucces('Classificacão relacionada com sucesso');
    } catch (error) {
      notifyError('Nao foi possivel Relacionar as classificacões');
    }
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'deleted_at',
      width: 100,
      render: (row) =>
        !row ? (
          <S.StatusActive>Ativo</S.StatusActive>
        ) : (
          <S.StatusInactive>Inativo</S.StatusInactive>
        ),
    },
    {
      title: 'Ações',
      dataIndex: '',
      width: 100,
      render: (row) => {
        row.role_id = selectedGroupId;
        return (
          <ButtonActionTable
            solicitationType={row}
            setClassifications={setClassifications}
          />
        );
      },
    },
  ];

  return (
    <Modal
      visible={editModalOpen}
      title="Classificações do Grupo"
      width={1000}
      height={600}
      closeModal={() => setEditModalOpen(false)}
      onCancel={() => setEditModalOpen(false)}
    >
      <S.ModalContainer>
        <S.ModalRow>
          <S.ContainerSelect>
            <Select
              onChange={handleClassification}
              showSearch
              optionFilterProp="children"
              placeholder="Selecione uma opção"
              notFoundContent="Nenhum registro encontrado para esta busca!"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {allClassifications.map((classification) => (
                <Option
                  key={classification.id}
                  valueId={classification.id}
                  valueName={classification.name}
                >
                  {classification.name}
                </Option>
              ))}
            </Select>
            <Button onClick={handleClickRelations} type="primary">
              Relacionar
            </Button>
          </S.ContainerSelect>
          <S.PageInfoContainer>
            <Table
              columns={columns}
              rows={classifications}
              page={5}
              showPaginationNav
            />
          </S.PageInfoContainer>
        </S.ModalRow>
      </S.ModalContainer>
    </Modal>
  );
}

export default ModalClassificationsRelations;
