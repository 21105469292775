import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Tooltip, Pagination } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { BsCheckCircleFill } from "react-icons/bs";
import { CgCreditCard } from "react-icons/cg";
import {
  FaListOl,
  FaRegCheckCircle,
  FaRegUserCircle,
  FaUser,
} from "react-icons/fa";
import { FiAlertTriangle, FiClock } from "react-icons/fi";
import { IoMdSearch, IoMdClose, IoIosArrowForward } from "react-icons/io";
import { MdInfoOutline, MdKeyboardArrowRight } from "react-icons/md";
import { RiTimerLine } from "react-icons/ri";
import { TbFileInvoice } from "react-icons/tb";
import ReactInputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { usePopup } from "../../Context/hasPopupActiveContext";
import { usePermissions } from "../../Context/permissionContext";
import { useAuth } from "../../hooks/AuthProvider";
import { useClient } from "../../hooks/ClientProvider";
import ResetPasswordModal from "../../pages/solicitation/ResetPasswordModal";
import api from "../../services/api";
import { LogsService } from "../../services/logsService"
import { SolicitationService } from "../../services/solicitationService";
import { capitalize } from "../../utils/capitalize";
import { customerStatus } from "../../utils/customerStatus";
import { formatCPF, formatDateTime } from "../../utils/formatter";
import { setPlaces } from "../../utils/getStores";
import { notifyError, notifyInfo } from "../../utils/notifications";
import { validaCpf, validarCNPJ } from "../../utils/validations";
import Can from "../Can";
import LoadingElevaty from "../LoadingElevaty";
import ModalRequestHome from "../ModalRequestHome";
import PopupService from "../PopupService";
import { TRANSLATE_PRIORITY } from "../Priority";
import { TRANSLATE_STATUS } from "../Status";
import TableRequestsHome from "../TableRequestsHome";
import { InvoiceInfo } from "./InvoiceInfo/InvoiceInfo";
import * as Style from "./style";

function SearchUser() {
  const navigate = useNavigate();
  const { updateClient } = useClient();
  const { user } = useAuth();
  const { hasPopupActive, handleClosePopup } = usePopup();
  const { permissions, fetchPermissions } = usePermissions();
  const isPasswordExpired = JSON.parse(
    localStorage.getItem("@SnxPayment:passwordExpired")
  );

  const [currentClient, setCurrentClient] = useState("titular");
  const [currentDoc, setCurrentDoc] = useState("multSearchValue");
  const [currentMask, setCurrentMask] = useState("");

  const [findUser, setFindUser] = useState(false);
  const [clientProducts, setClientProducts] = useState([]);
  const [cardSelected, setCardSelected] = useState();
  const [loadingSearchUser, setLoadingSearchUser] = useState(false);
  const [showClient, setShowClient] = useState(false);
  const [loadingClient, setLoadingClient] = useState(false);
  const [clientFound, setClientFound] = useState({});
  const [clientInformation, setClientInformation] = useState({});
  const [dataQuestions, setDataQuestions] = useState(null);
  const [modalAlterarSenhaisOpen, setModalAlterarSenhaisOpen] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [clientsByName, setClientsByName] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalByName, setTotalByName] = useState(0);

  const [modalRequestHomeOpen, setModalRequestHomeOpen] = useState(false);

  const [dataSource, setDataSource] = useState({});
  const [loadingRequestTable, setLoadingRequestTable] = useState(true);
  const [dataRequestByUser, setDataRequestByUser] = useState();
  const [dataModalRequest, setDataModalRequest] = useState();

  const handleOpenModalRequestHome = (e) => {
    setDataModalRequest(e);
    setModalRequestHomeOpen(true);
  };

  const [q1, setQ1] = useState("");
  const [q2, setQ2] = useState("");
  const [product, setProduct] = useState({});

  const handleQ1 = useCallback((event) => {
    const { value } = event.target;
    setQ1(value);
  }, []);

  const handleQ2 = useCallback((event) => {
    const { value } = event.target;
    setQ2(value);
  }, []);

  function setPlaceholder() {
    const text = "Digite o CPF, CNPJ ou RG";
    if (currentDoc === "client_id") {
      return "Digite o número do ID do Cliente";
    }
    if (currentDoc === "name") {
      return "Digite o nome do cliente";
    }
    if (user?.allowFetchByCard) {
      return "Digite o CPF, CNPJ, RG ou Nº do Cartão";
    }
    return text;
  }

  function validaRg(rg) {
    const rgDigits = rg.replace(/[^0-9]/g, "");
    const isValid = rgDigits.length >= 3 && rgDigits.length <= 10;
    return isValid;
  }

  const openModalAlterarSenhaisOpen = useCallback(() => {
    setModalAlterarSenhaisOpen(true);
  }, []);

  const closeModalAlterarSenhaisOpen = useCallback(() => {
    setModalAlterarSenhaisOpen(false);
  }, []);

  const handleCleanSearch = () => {
    setFindUser(false);
    setShowClient(false);
    setClientFound({});
    setClientProducts({});
    setCardSelected(null);
  };

  const handleTab = useCallback((doc) => {
    setShowClient(false);
    setCurrentDoc(doc);
  }, []);

  const handleTypeClient = useCallback(
    (value) => {
      setCurrentClient(value);
      handleCleanSearch();
      setCurrentDoc("multSearchValue");
      handleTab("multSearchValue");
      handleCleanSearch("multSearchValue");
    },
    [currentClient]
  );

  const schema = Yup.object().shape({
    doc: Yup.string()
      .required(() => {
        let message = "";
        switch (currentDoc) {
          case "client_id":
            message = "ID do cliente";
            break;
          case "name":
            message = "Nome e Sobrenome";
            break;
          default:
            message =
              currentDoc === "multSearchValue"
                ? "Documento de busca"
                : currentDoc[0].toUpperCase() + currentDoc.slice(1);
        }

        return (message += " obrigatório");
      })
      .test(
        "test_cpf",
        `Informe ${
          currentDoc === "multSearchValue"
            ? "um documento válido"
            : currentDoc === "name"
            ? "Nome e Sobrenome"
            : `um ${currentDoc} válido`
        } `,
        (value) => {
          if (currentDoc === "client_id") return true;
          if (
            currentDoc === "name" &&
            permissions &&
            permissions.includes("permission_search_by_name")
          ) {
            // if (!value) return true;

            // const [name, surname] = value.split(" ");

            // if (!name || !surname) {
            //   return false;
            // }

            return true;
          }

          if (currentDoc === "cpf") {
            const cpfFormated = value
              ? value.split(".").join("").replace("-", "")
              : "";
            return validaCpf(cpfFormated);
          }

          if (currentDoc === "cartao") {
            const cardFormated = value
              ? value.replace(/[./-]/g, "").replace(/\s/g, "")
              : "";
            let cardSize = 16;
            if (cardFormated.length === 19) cardSize = 19;
            return cardFormated.length === cardSize;
          }

          if (currentDoc === "multSearchValue") return false;

          if (currentDoc === "rg") {
            const rgFormated = value ? value.replace(/[^0-9]/g, "") : "";
            return validaRg(rgFormated); // Certifique-se de ter esta função definida em seu código
          }

          const cnpjFormated = value
            ? value.split(".").join("").replace("-", "").replace("/", "")
            : "";
          return validarCNPJ(cnpjFormated);
        }
      ),
  });

  const handleProduct = useCallback(
    async (value) => {
      if (value !== "none") {
        setCardSelected(value);

        const productSelected = value;

        setProduct(productSelected);
        setShowClient(true);
        setLoadingClient(true);

        const clientId = productSelected?.idTitular ?? productSelected?.id;

        await api
          .get("client_id", {
            client_id: clientId,
            product_id: productSelected.codigoProduto,
          })
          .then((res) => {
            if (productSelected.idTitular) {
              res.data.adicional?.map((aditional) => {
                if (aditional.id === productSelected.id) {
                  setDataQuestions(aditional.nascimento);
                }
                return true;
              });
            }
            setClientInformation(res.data.information);
            setClientFound(res.data);
            setTimeout(() => {
              setLoadingClient(false);
            }, 1000);
          })
          .catch((error) => {
            notifyError("Erro ao selecionar produto", error.message);
            console.log(error.response);
          });
      }
    },
    [clientProducts, product]
  );

  const handleSelectCliente = useCallback(async (id) => {
    setClientsByName({});
    setLoadingSearchUser(true);
    await api
      .get(`get_client_by_id`, {
        client_id: id,
      })
      .then((res) => {
        if (!res.data[0]) {
          notifyError("Cliente não encontrado");
          throw new Error("Cliente não encontrado");
        }
        setClientProducts(res.data);
        handleProduct(res.data[0]);
      })
      .catch((error) =>
        notifyError("Conta cancelada, não é possível realizar atendimento")
      )
      .finally(() => {
        setTimeout(() => {
          setLoadingSearchUser(false);
        }, 100);
      });
  }, []);

  const formikRef = React.useRef();

  const handleButtonClick = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  const handleSubmit = useCallback(
    async (data) => {
      try {
        await LogsService.create({
          action: `Usuário de login ${user.login} realizou um pré atendimento buscando o ${currentDoc}: ${data.doc}`,
        });
      } catch (error) {
        console.error("Erro ao criar log:", error);
      }

      if (currentDoc === "name" && data.doc.length <= 3) {
        return notifyError("A busca deve conter mais de três caracteres");
      }
      setCurrentPage(1);
      setLoadingSearchUser(true);
      handleCleanSearch();
      setFindUser(true);
      setClientsByName({});

      if (currentClient === "adicional") {
        if (currentDoc === "cpf") {
          await await api
            .get(`client_${currentDoc}_dependants`, {
              [`${currentDoc}`]: data.doc
                .replace(/[./-]/g, "")
                .replace(/\s/g, ""),
            })
            .then((res) => {
              if (!res.data[0]) {
                throw new Error("Cliente não encontrado");
              }
              setClientProducts(res.data);
              handleProduct(res.data[0]);
            })
            .catch((error) => notifyError(error.message))
            .finally(() => {
              setTimeout(() => {
                setLoadingSearchUser(false);
              }, 100);
            });
        }
        if (currentDoc === "rg") {
          const rgFormated = data.doc.replace(/[^0-9]/g, "");
          await api
            .get(`/api/v1/portadores/rg?numRg=${rgFormated}`)
            .then((res) => {
              if (!res.data[0]) {
                throw new Error("Cliente não encontrado");
              }
              setClientProducts(res.data);
              handleProduct(res.data[0]);
            })
            .catch((error) => notifyError(error.message))
            .finally(() => {
              setTimeout(() => {
                setLoadingSearchUser(false);
              }, 100);
            });
        }
        if (currentDoc === "cartao") {
          await api
            .get(`client_${currentDoc}`, {
              [`${currentDoc}`]: data.doc
                .replace(/[./-]/g, "")
                .replace(/\s/g, ""),
              tipoPortador: currentClient,
            })
            .then(async (res) => {
              if (!res.data[0]) {
                throw new Error("Cliente não encontrado");
              }
              const idDependent = await res.data[0].id;
              res = await api
                .get(`client_cpf_dependants`, {
                  cpf: res.data[0].cpf,
                })
                .then(async (res) => {
                  if (!res.data[0]) {
                    throw new Error("Cliente dependente não encontrado");
                  }

                  const productSelectedCard = res.data.filter(
                    (cp) => cp.id === Number(idDependent)
                  );

                  await setClientProducts(productSelectedCard);
                  await handleProduct(productSelectedCard[0]);
                });
            })
            .catch((error) => notifyError(error.message))
            .finally(() => {
              setTimeout(() => {
                setLoadingSearchUser(false);
              }, 100);
            });
        }
      } else {
        if (currentDoc === "client_id") {
          data.doc = data.doc.replace(/\.+/g, "");
          await api
            .get(`get_client_by_id`, {
              [`${currentDoc}`]: data.doc,
            })
            .then((res) => {
              if (!res.data[0]) {
                notifyError("Cliente não encontrado");
                throw new Error("Cliente não encontrado");
              }
              setClientProducts(res.data);
              handleProduct(res.data[0]);
            })
            .catch((error) => notifyError("Cliente não encontrado"))
            .finally(() => {
              setTimeout(() => {
                setLoadingSearchUser(false);
              }, 100);
            });
        } else if (currentDoc === "name") {
          await api
            .get(`client_${currentDoc}`, {
              [`${currentDoc}`]: data.doc,
              page: currentPage - 1,
            })
            .then((res) => {
              if (res?.data?.content?.length === 0) {
                throw new Error("Cliente não encontrado");
              }
              if (res?.data?.content?.length === 1) {
                handleSelectCliente(res?.data?.content[0].idPortador);
              } else {
                setClientsByName(res.data.content);
                setTotalByName(res.data.totalElements);
                setLoadingSearchUser(false);
              }
            })
            .catch((error) => {
              notifyError("Cliente não encontrado");
              setLoadingSearchUser(false);
            });
        } else {
          await api
            .get(`client_${currentDoc}`, {
              [`${currentDoc}`]: data.doc
                .replace(/[./-]/g, "")
                .replace(/\s/g, ""),
              tipoPortador: currentClient,
            })
            .then((res) => {
              if (!res.data[0]) {
                throw new Error("Cliente não encontrado");
              }
              setClientProducts(res.data);
              handleProduct(res.data[0]);
            })
            .catch((error) => notifyError(error.message))
            .finally(() => {
              setTimeout(() => {
                setLoadingSearchUser(false);
              }, 100);
            });
        }
      }
    },
    [currentDoc, currentClient, currentPage]
  );

  const handlePageSizeChange = async (page) => {
    const { values } = formikRef.current;

    setCurrentPage(page);

    setLoadingSearchUser(true);
    setClientsByName(false);

    await api
      .get(`client_${currentDoc}`, {
        [`${currentDoc}`]: values.doc,
        page: page - 1,
      })
      .then((res) => {
        if (res?.data?.content?.length === 0) {
          throw new Error("Cliente não encontrado");
        }
        if (res?.data?.content?.length === 1) {
          handleSelectCliente(res?.data?.content[0].idPortador);
        } else {
          setClientsByName(res.data.content);
          setTotalByName(res.data.totalElements);
          setLoadingSearchUser(false);
        }
      })
      .catch((error) => {
        notifyError("Cliente não encontrado");
        setLoadingSearchUser(false);
      });
  };

  const handleAttend = useCallback(async () => {
    try {
      const birthdayArr = dataQuestions
        ? dataQuestions.split("-")
        : clientFound.birthday.split("-");

      if (permissions.includes("view_birth_date")) {
        if (typeof Number(q1) === "number" && !Number.isNaN(Number(q1))) {
          if (Number(q1) === 10) {
            if (Number(birthdayArr[1]) !== Number(q1))
              throw new Error("Pergunta 1 não confere");
          } else if (Number(birthdayArr[1].replace("0", "")) !== Number(q1))
            throw new Error("Pergunta 1 não confere");
        }
        if (birthdayArr[0] !== q2) throw new Error("Pergunta 2 não confere");
      }

      if (clientFound.endereco_alternativo) {
        clientFound.endereco_alternativo.bairro = capitalize(
          clientFound.endereco_alternativo.bairro
            ? clientFound.endereco_alternativo.bairro
            : ""
        );
        clientFound.endereco_alternativo.localidade = capitalize(
          clientFound.endereco_alternativo.localidade
            ? clientFound.endereco_alternativo.localidade
            : ""
        );
        clientFound.endereco_alternativo.cidade = capitalize(
          clientFound.endereco_alternativo.cidade
            ? clientFound.endereco_alternativo.cidade
            : ""
        );
        clientFound.endereco_alternativo.logradouro = capitalize(
          clientFound.endereco_alternativo.logradouro
            ? clientFound.endereco_alternativo.logradouro
            : ""
        );
        clientFound.endereco_alternativo.complemento = capitalize(
          clientFound.endereco_alternativo.complemento
            ? clientFound.endereco_alternativo.complemento
            : ""
        );
      }

      if (clientFound.endereco_comercial) {
        clientFound.endereco_comercial.empresa = capitalize(
          clientFound.endereco_comercial.empresa
            ? clientFound.endereco_comercial.empresa
            : ""
        );
        clientFound.endereco_comercial.departamento = capitalize(
          clientFound.endereco_comercial.departamento
            ? clientFound.endereco_comercial.departamento
            : ""
        );
        clientFound.endereco_comercial.bairro = capitalize(
          clientFound.endereco_comercial.bairro
            ? clientFound.endereco_comercial.bairro
            : ""
        );
        clientFound.endereco_comercial.localidade = capitalize(
          clientFound.endereco_comercial.localidade
            ? clientFound.endereco_comercial.localidade
            : ""
        );
        clientFound.endereco_comercial.cidade = capitalize(
          clientFound.endereco_comercial.cidade
            ? clientFound.endereco_comercial.cidade
            : ""
        );
        clientFound.endereco_comercial.logradouro = capitalize(
          clientFound.endereco_comercial.logradouro
            ? clientFound.endereco_comercial.logradouro
            : ""
        );
        clientFound.endereco_comercial.complemento = capitalize(
          clientFound.endereco_comercial.complemento
            ? clientFound.endereco_comercial.complemento
            : ""
        );
      }
      if (clientFound.endereco_residencial) {
        clientFound.endereco_residencial.bairro = capitalize(
          clientFound.endereco_residencial.bairro
            ? clientFound.endereco_residencial.bairro
            : ""
        );
        clientFound.endereco_residencial.localidade = capitalize(
          clientFound.endereco_residencial.localidade
            ? clientFound.endereco_residencial.localidade
            : ""
        );
        clientFound.endereco_residencial.cidade = capitalize(
          clientFound.endereco_residencial.cidade
            ? clientFound.endereco_residencial.cidade
            : ""
        );
        clientFound.endereco_residencial.logradouro = capitalize(
          clientFound.endereco_residencial.logradouro
            ? clientFound.endereco_residencial.logradouro
            : ""
        );
        clientFound.endereco_residencial.complemento = capitalize(
          clientFound.endereco_residencial.complemento
            ? clientFound.endereco_residencial.complemento
            : ""
        );
      }

      updateClient({
        client: clientFound,
        product,
        information: clientInformation,
      });
      setTimeout(() => {
        navigate("/atendimento");
      }, 1000);
    } catch (err) {
      notifyError("Responda as perguntas corretamente", err.message);
    }
  }, [clientFound, navigate, product, q1, q2, updateClient]);

  const getDynamicMask = useCallback(
    (inputValue, event) => {
      if (event?.inputType === "deleteContentBackward") {
        return currentMask;
      }
      let mask = currentMask;
      if (currentDoc === "client_id") {
        mask = "99999999999999999";
      } else if (inputValue.length > 14) {
        if (inputValue.length > 18) {
          mask = "9999 9999 9999 9999 999";
        } else {
          mask = "99.999.999/9999-999";
        }
      }

      setCurrentMask(mask);

      return mask;
    },
    [currentDoc, currentMask]
  );

  const handleTypeOfSearch = (input, hasCardSearch, setFieldValue) => {
    const cleanValue = input.replace(/\D/g, "");
    const validLengths = [11, 14, 19, 20];

    setFieldValue("doc", input);
    setInputValue(input);

    if (input.length === 0) {
      setCurrentMask("");
    }

    if (input.length === 10 && /^\d+$/.test(input)) {
      setCurrentMask("999.999.999-9999");
    }

    if (Number.isNaN(input) === true) {
      setCurrentDoc("clientName");
      handleTab("clientName");
    }

    if (/^[a-zA-Z\s]+$/.test(input)) {
      setCurrentDoc("name");
      setCurrentMask("");
    }

    if (
      !validLengths.includes(cleanValue.length) &&
      currentDoc !== "client_id"
    ) {
      setCurrentDoc("multSearchValue");
      handleTab("multSearchValue");
    } else if (cleanValue.length === 11 && currentDoc !== "client_id") {
      setCurrentDoc("cpf");
      handleTab("cpf");
    } else if (
      cleanValue.length === 14 &&
      currentDoc !== "client_id" &&
      currentClient === "titular"
    ) {
      setCurrentDoc("cnpj");
      handleTab("cnpj");
    }

    if (
      cleanValue.length >= 16 &&
      currentDoc !== "client_id" &&
      hasCardSearch
    ) {
      setCurrentDoc("cartao");
      handleTab("cartao");
    }

    if (
      cleanValue.length >= 3 &&
      cleanValue.length <= 9 &&
      currentDoc !== "client_id"
    ) {
      setCurrentDoc("rg");
      handleTab("rg");
    }
  };

  useEffect(() => {
    if (isPasswordExpired.isPwdExpired) {
      notifyInfo(
        isPasswordExpired.firstLogin
          ? "Redefinição de senha de primeiro acesso"
          : "Senha expirada para o prazo de 90 dias. Por favor insira uma nova senha."
      );
      openModalAlterarSenhaisOpen();
      setEditPassword(true);
    } else {
      setEditPassword(false);
    }
  }, []);

  const getCardStatusStyle = (cardStatus) => {
    switch (cardStatus) {
      case "ATIVO": {
        return "active-card";
      }
      default: {
        return "blocked-card";
      }
    }
  };

  const clientCpf = useMemo(() => updateClient?.client?.cpf, [updateClient]);

  const fetchSolicitations = useCallback(
    async (pagination = {}, filters = {}, sorter = {}) => {
      try {
        const { current, pageSize } = pagination;

        const {
          ticket_protocol,
          client_name,
          status,
          priority,
          type,
          id,
          user_name,
          attendant_name,
        } = filters;

        setLoadingRequestTable(true);
        const response = await SolicitationService.list({
          client_cpf: clientCpf,
          id,
          status,
          priority,
          type,
          user_name,
          attendant_name,
          ticket_protocol: ticket_protocol ? ticket_protocol[0] : undefined,
          client_name: client_name ? client_name[0] : undefined,
          limit: pageSize,
          page: current,
          sorter,
        });
        setDataSource(response);

        const { data } = response;

        if (!current) {
          const filterByUser = data?.filter(
            (item) => item.attendant_user_id === user?.id && item.status === "ACTIVE"
          );

          setDataRequestByUser(filterByUser.slice(-3));
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingRequestTable(false);
      }
    },
    [clientCpf, user]
  );

  const handleSubmitFormModal = async (value) => {
    handleTab("multSearchValue");

    handleTypeOfSearch(
      formatCPF(value),
      user?.allowFetchByCard,
      formikRef.current.setFieldValue
    );

    setTimeout(() => {
      setModalRequestHomeOpen(false);
      formikRef.current.submitForm();
    }, 1000);
  };

  useEffect(() => {
    fetchPermissions();
    setPlaces();
  }, [fetchPermissions]);

  useEffect(() => {
    fetchSolicitations();
  }, [user]);

  return (
    <>
      <PopupService isOpen={hasPopupActive} onClose={handleClosePopup} />
      {modalAlterarSenhaisOpen && (
        <ResetPasswordModal onCancel={closeModalAlterarSenhaisOpen} />
      )}

      {!isPasswordExpired.isPwdExpired && (
        <Style.WrapperSearchUserMeet
          viewSolicitation={!permissions.includes("view_solicitation")}
        >
          <div className="user-meet__header">
            <p>Buscar cliente para atender</p>
          </div>

          <div className="user-meet__content">
            <div className="item-select__user">
              <p className="title">Cliente:</p>

              <div className="item-select__options">
                <Style.DocButton
                  selected={currentClient === "titular"}
                  onClick={() => handleTypeClient("titular")}
                >
                  <p>Titular</p>
                </Style.DocButton>
                <Style.DocButton
                  selected={currentClient === "adicional"}
                  onClick={() => handleTypeClient("adicional")}
                >
                  <p>Adicional</p>
                </Style.DocButton>
              </div>
            </div>
            <div className="item-select__user">
              <p className="title">Tipo:</p>

              <div className="item-select__options scroll">
                <Style.DocButton
                  scroll
                  selected={currentDoc === "multSearchValue"}
                  onClick={() => handleTab("multSearchValue")}
                >
                  <p>Documento</p>
                </Style.DocButton>
                {currentClient === "titular" &&
                  permissions?.includes("personal_data") && (
                    <Style.DocButton
                      scroll
                      selected={currentDoc === "client_id"}
                      onClick={() => handleTab("client_id")}
                    >
                      <p>ID Cliente</p>
                    </Style.DocButton>
                  )}

                {permissions?.includes("permission_search_by_name") && currentClient !== "adicional" && (
                  <Style.DocButton
                    scroll
                    selected={currentDoc === "name"}
                    onClick={() => handleTab("name")}
                  >
                    <p>Nome</p>
                  </Style.DocButton>
                )}
              </div>
            </div>
            <div className="item-select__user">
              <p className="title">
                {currentDoc === "name" ? "Nome:" : "Número:"}
              </p>

              <div className="input-search">
                <Formik
                  initialValues={{ doc: "" }}
                  validationSchema={schema}
                  onSubmit={handleSubmit}
                  autoComplete="off"
                  innerRef={formikRef}
                >
                  {({ setFieldValue }) => (
                    <Form>
                      <div className="item-input">
                        {currentDoc === "name" ? (
                          <>
                            <Field name="doc">
                              {({ field, meta }) => (
                                <input
                                  label="Usuário"
                                  placeholder={setPlaceholder()}
                                  className={
                                    meta.touched && meta.error ? "error" : null
                                  }
                                  {...field}
                                  autoComplete="off"
                                />
                              )}
                            </Field>
                          </>
                        ) : (
                          <>
                            <Field name="doc">
                              {({ field, meta }) => (
                                <ReactInputMask
                                  label="Usuário"
                                  placeholder={setPlaceholder()}
                                  maskChar=""
                                  className={
                                    meta.touched && meta.error ? "error" : null
                                  }
                                  {...field}
                                  mask={getDynamicMask(inputValue, event)}
                                  value={inputValue}
                                  onChange={(event) => {
                                    handleTypeOfSearch(
                                      event.target.value,
                                      user?.allowFetchByCard,
                                      setFieldValue
                                    );
                                  }}
                                  autoComplete="off"
                                />
                              )}
                            </Field>
                          </>
                        )}
                        <ErrorMessage
                          name="doc"
                          className="error-msg"
                          component="div"
                        />
                      </div>

                      <div className="user-login__btn">
                        <button type="submit" disabled={editPassword}>
                          <IoMdSearch />
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Style.WrapperSearchUserMeet>
      )}

      {findUser ? (
        <Style.WrapperInfosUser>
          <div className="infos-search__header">
            <p>Encontrar cliente</p>
            <button type="button" onClick={handleCleanSearch}>
              <Can permission="view_solicitation">Listar solicitações</Can>
              <IoMdClose />
            </button>
          </div>

          <Style.WrapperCardsUserSelect>
            {loadingSearchUser && (
              <div className="user-info__loading">
                <LoadingElevaty />
              </div>
            )}

            {clientsByName.length > 0 ? (
              <>
                <div className="scroll-names-desk">
                  {clientsByName.length > 0 &&
                    clientsByName.map((item, index) => {
                      return (
                        <div
                          className="user-card-select__clientname"
                          key={item.idPortador}
                        >
                          <div className="client-name__name">
                            <FaUser />
                            <p>{item.nomePortador}</p>
                          </div>
                          <div className="client-name__document">
                            <p>
                              <span>ID:</span>
                              {item.idPortador}
                            </p>
                          </div>
                          <div className="client-name__btn">
                            <button
                              type="button"
                              onClick={() =>
                                handleSelectCliente(item.idPortador)
                              }
                            >
                              <IoIosArrowForward /> Selecionar
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <Style.CenterDivPagination>
                  <Pagination
                    current={currentPage}
                    onChange={(page) => {
                      handlePageSizeChange(page);
                    }}
                    pageSize={50}
                    total={totalByName}
                    showSizeChanger={false}
                  />
                </Style.CenterDivPagination>
              </>
            ) : (
              <>
                {clientProducts && clientProducts.length > 0 && (
                  <>
                    <div className="user-card-select__header">
                      <p>Produtos:</p>
                    </div>
                    <div className="user-card-select__grid">
                      {clientProducts.map((card) => (
                        <div
                          className={`user-card-select__item ${
                            cardSelected?.id === card.id ? "active" : ""
                          }`}
                          onClick={() => {
                            handleProduct(card);
                          }}
                          key={card.id}
                        >
                          <div className="icon-check">
                            <BsCheckCircleFill color="#FFC53D" />
                          </div>

                          <div className="user-card-select__item-header">
                            <CgCreditCard /> <p>{card.desc_prod}</p>
                          </div>

                          <div className="user-card-select__item-content">
                            <ul>
                              {card.cartoes.map((cartao) => (
                                <li key={cartao.id}>
                                  {cartao.numeroMascarado} (
                                  {cartao.virtual ? "Virtual" : "Físico"})
                                  <p
                                    className={`${getCardStatusStyle(
                                      cartao.status
                                    )} status`}
                                  >
                                    {cartao.status.replace(/_/g, " ")}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}

                {showClient && (
                  <>
                    {loadingClient ? (
                      <div className="user-info__loading">
                        <LoadingElevaty />
                      </div>
                    ) : (
                      <>
                        {clientFound && (
                          <>
                            <div className="user-info__search">
                              <div className="user-info__search-item">
                                <div className="user-info__search-item__header">
                                  <FaUser />
                                  <p>
                                    {clientFound.name &&
                                      capitalize(clientFound.name)}
                                  </p>

                                  <div className="tags">
                                    <p>#{clientFound?.id}</p>
                                    <span
                                      className={customerStatus(
                                        clientFound.statusCliente
                                      ).toLocaleLowerCase()}
                                    >
                                      {customerStatus(
                                        clientFound.statusCliente
                                      )}
                                    </span>
                                    {clientFound.ultimoMotivoBloqueioCliente && (
                                      <Tooltip
                                        title={
                                          clientFound.ultimoMotivoBloqueioCliente
                                        }
                                      >
                                        <MdInfoOutline />
                                      </Tooltip>
                                    )}
                                  </div>
                                </div>
                                <Can permission="permission_view_data_client_search">
                                  <div className="user-info__search-infos">
                                    {clientFound.cpf && (
                                      <div className="item-user-info">
                                        <p>
                                          <span>CPF</span>:{" "}
                                          {clientFound.cpf.replace(
                                            /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                            "$1.$2.$3-$4"
                                          )}
                                        </p>
                                      </div>
                                    )}
                                    {clientFound.rg && (
                                      <div className="item-user-info">
                                        <p>
                                          <span>RG</span>: {clientFound.rg}
                                        </p>
                                      </div>
                                    )}
                                    {clientFound.email && (
                                      <div className="item-user-info">
                                        <p className="email">
                                          <span>E-mail</span>:{" "}
                                          {clientFound.email}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </Can>
                              </div>
                            </div>

                            <div className="user-info__form">
                              <div className="user-info__form-align">
                                {permissions.includes("view_birth_date") && (
                                  <div className="user-info__form-left">
                                    <div className="user-info__form-input">
                                      <p>
                                        Mês de nascimento do{" "}
                                        <b>{currentClient}</b>:
                                      </p>
                                      <ReactInputMask
                                        placeholder="Ex.: 01"
                                        mask="99"
                                        maskChar=""
                                        name="q1"
                                        autoComplete="off"
                                        onChange={handleQ1}
                                      />
                                    </div>
                                    <div className="user-info__form-input">
                                      <p>
                                        Ano de nascimento do{" "}
                                        <b>{currentClient}</b>:
                                      </p>
                                      <ReactInputMask
                                        placeholder="Ex.: 2022"
                                        mask="9999"
                                        maskChar=""
                                        name="q2"
                                        autoComplete="off"
                                        onChange={handleQ2}
                                      />
                                    </div>
                                  </div>
                                )}

                                <div className="user-info__form-right">
                                  <div className="user-info__btn">
                                    <button
                                      type="submit"
                                      onClick={handleAttend}
                                    >
                                      Atender <IoIosArrowForward />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Style.WrapperCardsUserSelect>
          {!loadingClient &&
            showClient &&
            clientFound &&
            clientFound.fatura_fechada && (
              <InvoiceInfo
                text="Valor da última fatura:"
                dataVencimento={clientFound.fatura_fechada.dataVencimento}
                valorTotal={clientFound.fatura_fechada.valorTotal}
                possuiPagamentoMinimo={
                  clientFound.fatura_fechada.possuiPagamentoMinimo
                }
                valorMinimo={clientFound.fatura_fechada.valorPagamentoMinimo}
              />
            )}
          {!loadingClient &&
            showClient &&
            clientFound &&
            clientFound.information &&
            clientFound.information.faturasFuturas &&
            !!clientFound.information.faturasFuturas.length && (
              <InvoiceInfo
                text="Valor da fatura atual:"
                dataVencimento={
                  clientFound.information.faturasFuturas[0].dataVencimento
                }
                valorTotal={clientFound.information.faturasFuturas[0].valor}
                possuiPagamentoMinimo={false}
              />
            )}
        </Style.WrapperInfosUser>
      ) : (
        <Can permission="view_solicitation">
          <Style.WrapperInfosUser>
            <div className="infos-search__header">
              <p>
                <strong>Meus tickets</strong>{" "}
                {dataRequestByUser?.length ? (
                  <>
                    - {dataRequestByUser?.length}{" "}
                    {dataRequestByUser?.length === 1
                      ? "Ticket em atendimento"
                      : "Tickets em atendimento"}
                  </>
                ) : null}
              </p>
            </div>

            <Style.WrapperItemRequest>
              {loadingRequestTable ? (
                <div className="user-card-select__loading">
                  <LoadingElevaty />
                </div>
              ) : (
                <>
                  {dataRequestByUser?.length ? (
                    <>
                      {dataRequestByUser?.map((item, index) => (
                        <Style.DivItemRequest key={Math.random()}>
                          <div className="divitem-content__left">
                            <p className="divitem-content__protocol">
                              <strong>#{item?.id}</strong> - Protoc.{" "}
                              {item?.ticket_protocol}
                            </p>
                            <p className="divitem-content__title">
                              <strong>{item?.type?.name}</strong>
                            </p>
                            <p className="divitem-content__infos">
                              <span className="divitem-content__user">
                                <FaRegUserCircle />
                                {item?.client_name}
                              </span>
                              <span className="divitem-content__user">
                                <RiTimerLine />
                                {formatDateTime(item?.created_at)}
                              </span>
                              <span className="divitem-content__user">
                                <FaListOl />
                                {TRANSLATE_PRIORITY[item?.priority]?.label}
                              </span>
                              <span className="divitem-content__user">
                                {TRANSLATE_STATUS[item?.status].label ===
                                "Pendente" ? (
                                  <FiClock />
                                ) : null}
                                {TRANSLATE_STATUS[item?.status].label ===
                                "Cancelado" ? (
                                  <FiAlertTriangle />
                                ) : null}
                                {TRANSLATE_STATUS[item?.status].label ===
                                "Ativo" ? (
                                  <FaRegCheckCircle />
                                ) : null}
                                {TRANSLATE_STATUS[item?.status].label ===
                                "Resolvido" ? (
                                  <FaRegCheckCircle />
                                ) : null}
                                {TRANSLATE_STATUS[item?.status].label}
                              </span>
                            </p>
                          </div>
                          <div className="divitem-content__right">
                            <button
                              type="button"
                              onClick={() => handleOpenModalRequestHome(item)}
                            >
                              Ver <MdKeyboardArrowRight />
                            </button>
                          </div>
                        </Style.DivItemRequest>
                      ))}
                    </>
                  ) : (
                    <div className="user-card-select__empty">
                      <span>
                        <FiAlertTriangle />
                        <p>Não há tickets de solicitações</p>
                      </span>
                    </div>
                  )}
                </>
              )}
            </Style.WrapperItemRequest>
          </Style.WrapperInfosUser>
        </Can>
      )}

      {!findUser ? (
         <Can permission="view_solicitation">
          <TableRequestsHome
            loadingRequestTable={loadingRequestTable}
            dataSource={dataSource}
            handleOpenModalRequestHome={handleOpenModalRequestHome}
            fetchSolicitations={fetchSolicitations}
          />
        </Can>
      ) : null}

      {modalRequestHomeOpen ? (
        <ModalRequestHome
          dataModalRequest={dataModalRequest}
          onClose={() => setModalRequestHomeOpen(false)}
          handleSubmitFormModal={handleSubmitFormModal}
          fetchSolicitations={fetchSolicitations}
        />
      ) : null}
    </>
  );
}

export default SearchUser;
