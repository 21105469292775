import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_CONTEXT_KEY;

const encrypt = (text) => {
  return CryptoJS.AES.encrypt(text, SECRET_KEY).toString();
};

const decrypt = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

const encodeBase64 = (string) => {
  const encoded = window.btoa(string);
  return encoded;
};

const decodeBase64 = (encodedString) => window.atob(encodedString);

const getMerchantPars = () => {
  const encryptedCompanyData = localStorage.getItem(encodeBase64('@SnxPayment:company'));
  const aux = decrypt(encryptedCompanyData);
  return JSON.parse(aux);
}

const hasCryptKeyCard = () => {
  const encryptedCompanyData = localStorage.getItem(encodeBase64('@SnxPayment:company'));
  let aux = decrypt(encryptedCompanyData);
  aux = JSON.parse(aux);

  if(aux.crypt_key !== '' && aux.crypt_key !== undefined && aux.crypt_key !== null){
    return true;
  }
  return false;
}

export { encrypt, decrypt, encodeBase64, decodeBase64, getMerchantPars, hasCryptKeyCard};