import React, { useState } from "react";

import { Modal, Select } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import * as S from "../../../components/creditCard/style";
import InputCard from "../../../components/InputCard";
import LoadingElevaty from "../../../components/LoadingElevaty";
import api from "../../../services/api";
import { getCompanyName } from "../../../utils/companyName";
import { notifyError, notifySucces } from "../../../utils/notifications";
import { validaCpf } from "../../../utils/validations";

const { Option } = Select;
function ModalAddDependent({ open, closeModal, client }) {
  const [titular, setTitular] = useState({
    cpf: "",
    dataNascimento: "",
    grauParentesco: "0",
    nome: "",
    nomeMae: "",
    sexo: "0",
  });
  const [contato, setContato] = useState({
    faturaDigital: "",
    receberSMS: "",
    tipo: "",
    ramal: "",
    area: "",
    telefone: "",
  });
  const [companyName, setCompanyName] = useState(getCompanyName());
  const [loading, setLoading] = useState(false);
  function handleChangeTitular(event) {
    const { id, value } = event.target;
    setTitular((prevTitular) => ({ ...prevTitular, [id]: value }));
  }
  function handleChangeContato(event) {
    const { id, value } = event.target;
    setContato((prevTitular) => ({ ...prevTitular, [id]: value }));
  }
  function handleChangeSelectTitular(value, field, setFieldValue) {
    setFieldValue(field, value);
  }
  async function addDependant(value, resetForm) {
    try {
      setLoading(true);
      const formatedDate = value.dataNascimento.split("/");
      const data = {
        cpf: `${value.cpf.split(".").join("").replace("-", "")}`,
        dataNascimento: `${formatedDate[2]}-${formatedDate[1]}-${formatedDate[0]}`,
        grauParentesco: `${value.grauParentesco}`,
        nome: value?.nome,
        nomeMae: value.nomeMae,
        sexo: value?.sexo,
        telefone: {
          faturaDigital: "false",
          receberSMS: "false",
          area: `${value.area}`,
          telefone: `${value.telefone.replace("_", "")}`,
          tipo: "CELULAR",
        },
      };

      await api.post("new_dependant", {
        client_id: client.client.id,
        ...data,
      });
      closeModal();
      notifySucces("Dependente criado com sucesso");
      resetForm();
    } catch (error) {
      const stringError = error.message;

      if (stringError.includes("detail")) {
        const splitArray = stringError.split("detail:");
        const detailText = splitArray[1].split(",")[0].trim();
        return detailText ? notifyError(detailText) : notifyError(stringError);
      }

      notifyError(stringError);
    } finally {
      setLoading(false);
      setTitular({
        nome: "",
        cpf: "",
        dataNascimento: "",
        grauParentesco: "0",
        nomeMae: "",
        sexo: "0",
      });
      setContato({
        faturaDigital: "",
        receberSMS: "",
        tipo: "",
        ramal: "",
        area: "",
        telefone: "",
      });
      resetForm();
    }
  }
  return (
    <Modal title="Adicionar Dependente" visible={open} onCancel={closeModal}>
      {loading && (
        <S.NewLoadingModals>
          <LoadingElevaty width="40px" height="40px" duration="durFast" />
        </S.NewLoadingModals>
      )}
      <h1>Informações Dependente</h1>
      <Formik
        initialValues={{
          nome: "",
          dataNascimento: "",
          cpf: "",
          sexo: "",
          grauParentesco: "",
          nomeMae: "",
          area: "",
          telefone: "",
        }}
        validationSchema={Yup.object({
          nome: Yup.string().required(`Nome obrigatório`).test("lastName", "Insira pelo menos um sobrenome", name => {
            if (!name) {
              return false;
            }
            const formattedName = name.trim().split(" ");
            return formattedName?.length > 1;
          }),
          dataNascimento: Yup.string().required(`Data nascimento obrigatório`)
            .test('dataNascimento', 'A data de nascimento é inválida', (value) => {
              if (!value) return false;
              const dateFormated = value.replace(/_/g, "").replace(/\//g, "-");
              if (dateFormated.length < 10) return false;
              const dateParts = dateFormated.split('-');
              // eslint-disable-next-line radix
              if (parseInt(dateParts[1]) > 12 || parseInt(dateParts[0]) > 31) return false
              const formattedDateStr = dateParts.reverse().join('-');
              const birthDate = new Date(formattedDateStr);
              const currentDate = new Date();
              if (birthDate >= currentDate) {
                return false;
              }
              return true;
            }),
          cpf: Yup.string().required(`Cpf obrigatório`)
            .test('cpf', 'O CPF é inválido', (value) => {
              if (!value) return false;
              const cpfFormated = value
                .split(".")
                .join("")
                .replace("-", "");
              return validaCpf(cpfFormated);
            }),
          sexo: Yup.string().required(`Sexo obrigatório`),
          grauParentesco: Yup.string().required(
            `Grau de parentesco obrigatório`
          ),
          nomeMae: Yup.string().required(`Nome da mãe obrigatório`),
          area: Yup.string().required(`DDD obrigatório`).min(2, 'DDD inválido').max(2, 'DDD inválido').matches(/^[0-9]+$/, "O campo deve conter apenas números"),
          telefone: Yup.string().required(`Telefone obrigatório`).nullable().matches(/^[0-9]+$/, "O campo deve conter apenas números").min(8, 'O telefone é inválido').max(10, 'O telefone é inválido')
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => addDependant(values, resetForm)}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <S.ModalAddWrapper>
              <S.ModalRowWrap>
                <Field name="nome">
                  {({ field, meta }) => (
                    <InputCard
                      addonBefore="Nome"
                      placeholder="Nome"
                      maskChar=""
                      onChange={handleChangeTitular}
                      className={meta.touched && meta.error ? "error-id" : null}
                      requiredInfo
                      {...field}
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="nome"
                  component="div"
                  className="error-message"
                />
              </S.ModalRowWrap>
              <S.ModalRowWrap>
                <S.inputW50>
                  <Field name="dataNascimento">
                    {({ field, meta }) => (
                      <InputCard
                        value={titular.dataNascimento}
                        addonBefore="Nascimento"
                        placeholder="dd/mm/yyyy"
                        mask="99/99/9999"
                        maskChar=""
                        onChange={handleChangeTitular}
                        className={
                          meta.touched && meta.error ? "error-id" : null
                        }
                        requiredInfo
                        {...field}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="dataNascimento"
                    component="div"
                    className="error-message"
                  />
                </S.inputW50>
                <S.inputW50>
                  <Field name="cpf">
                    {({ field, meta }) => (
                      <InputCard
                        value={titular.cpf}
                        addonBefore="CPF"
                        mask="999.999.999-99"
                        maskChar=""
                        placeholder="999.999.999-99"
                        onChange={handleChangeTitular}
                        className={
                          meta.touched && meta.error ? "error-id" : null
                        }
                        requiredInfo
                        {...field}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="cpf"
                    component="div"
                    className="error-message"
                  />
                </S.inputW50>
              </S.ModalRowWrap>
              <S.ModalRowWrap>
                <S.ContainerSelect>
                  <p className="title">
                    <span>*</span>
                    Sexo
                  </p>
                  <Field name="sexo">
                    {({ field, meta }) => (
                      <Select
                        defaultValue={values.sexo}
                        value={values.sexo}
                        maskChar={null}
                        {...field}
                        id="sexo"
                        className={`${meta.touched && meta.error ? "error-id" : null
                          } grau-card`}
                        onChange={(value) => {
                          handleChangeSelectTitular(
                            value,
                            "sexo",
                            setFieldValue
                          );
                        }}
                      >
                        <Option value="">Sexo</Option>
                        <Option value="MASCULINO">Masculino</Option>
                        <Option value="FEMININO">Feminino</Option>
                      </Select>
                    )}
                  </Field>
                  <ErrorMessage
                    name="sexo"
                    component="div"
                    className="error-message"
                  />
                </S.ContainerSelect>
                <S.ContainerSelect>
                  <p className="title">
                    <span>*</span>
                    Grau Parentesco
                  </p>
                  <Field name="grauParentesco">
                    {({ field, meta }) => (
                      <Select
                        value={values.grauParentesco}
                        maskChar={null}
                        {...field}
                        id="grauParentesco"
                        onChange={(value) => {
                          handleChangeSelectTitular(
                            value,
                            "grauParentesco",
                            setFieldValue
                          );
                        }}
                        className={`${meta.touched && meta.error ? "error-id" : null
                          } grau-card`}
                      >
                        <Option value="">Grau de parentesco</Option>
                        <Option value="PAI">Pai</Option>
                        <Option value="MAE">Mãe</Option>
                        <Option value="FILHO">Filho</Option>
                        <Option value="IRMAO">Irmão</Option>
                        <Option value="CONJUGE">Conjuge</Option>
                        {!companyName.includes("lopes") && (
                          <Option value="OUTROS">Outros</Option>
                        )}
                      </Select>
                    )}
                  </Field>
                  <ErrorMessage
                    name="grauParentesco"
                    component="div"
                    className="error-message"
                  />
                </S.ContainerSelect>
              </S.ModalRowWrap>
              <S.ModalRowWrap>
                <Field name="nomeMae">
                  {({ field, meta }) => (
                    <InputCard
                      value={titular.nomeMae}
                      addonBefore="Nome da Mãe"
                      placeholder="Nome da mãe"
                      maskChar=""
                      onChange={handleChangeTitular}
                      className={meta.touched && meta.error ? "error-id" : null}
                      requiredInfo
                      {...field}
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="nomeMae"
                  component="div"
                  className="error-message"
                />
              </S.ModalRowWrap>
              <S.ModalRowWrap>
                <S.inputW50>
                  <Field name="area">
                    {({ field, meta }) => (
                      <InputCard
                        value={contato.area}
                        addonBefore="DDD"
                        placeholder="DDD"
                        mask="99"
                        maskChar=""
                        onChange={handleChangeContato}
                        className={
                          meta.touched && meta.error ? "error-id" : null
                        }
                        requiredInfo
                        {...field}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="area"
                    component="div"
                    className="error-message"
                  />
                </S.inputW50>
                <S.inputW50>
                  <Field name="telefone">
                    {({ field, meta }) => (
                      <InputCard
                        value={contato.telefone}
                        addonBefore="Telefone"
                        mask="999999999"
                        maskChar=""
                        placeholder="telefone"
                        onChange={handleChangeContato}
                        className={
                          meta.touched && meta.error ? "error-id" : null
                        }
                        requiredInfo
                        {...field}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="telefone"
                    component="div"
                    className="error-message"
                  />
                </S.inputW50>
              </S.ModalRowWrap>
            </S.ModalAddWrapper>
            <S.ModalRow>
              <div className="center">
                {!loading && (
                  <div className="btns-send center">
                    <button type="submit">Adicionar</button>
                  </div>
                )}
              </div>
            </S.ModalRow>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
export default ModalAddDependent;
