/* eslint-disable no-unused-vars */
/* eslint-disable no-return-await */
import React, { useEffect, useState } from 'react';

import { Button, Checkbox, Modal, Select } from 'antd';

import AntInput from '../../../components/AntInput';
import LoadingElevaty from '../../../components/LoadingElevaty';
import { usePermissions } from '../../../Context/permissionContext';
import { useAuth } from '../../../hooks/AuthProvider';
import { useClient } from '../../../hooks/ClientProvider';
import api from '../../../services/api';
import { getCompanyName } from "../../../utils/companyName";
import { notifyError, notifySucces } from '../../../utils/notifications';
import * as S from './styles';


const { Option } = Select;

function ModalSendMail({
  open,
  codBarras,
  invoice,
  closeModal,
  emailValue,
  ticket,
  insurance,
}) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(emailValue);
  const [emails, setEmails] = useState('');
  const [mail, setMail] = useState('');
  const [disabledInput, setDisabledInput] = useState(false);
  const [sendToUnregisteredMail, setSendToUnregisteredMail] = useState(false);
  const [companyName] = useState(getCompanyName());
  const { permissions } = usePermissions();
  const { user } = useAuth();

  const { client } = useClient();

  const hasPermissionToSendToUnregistered = permissions &&
  permissions.includes("sending_unregistered_contacts");

  async function handleChangeMail(event) {
    setDisabledInput(true);
    const { value } = event.target;
    await setMail(value);
    if (value.length < 1) setDisabledInput(false);
  }

  const companyId = user?.company_id;

  async function handleSelectMail(valueEmail) {
    await setMail('');
    await setEmail(valueEmail);
  }

  const handleChangeSendToUnregistered = (checked) => {
    setSendToUnregisteredMail(checked);
    if (!checked) {
      setMail('');
      setDisabledInput(false);
    }
  }

  async function handleGoBack() {
    await setMail('');
    await setDisabledInput(false);
    closeModal();
  }

  async function sendMail() {
    const formattedEmail = mail || email;
    try {
      setLoading(true);
      const identity = localStorage.getItem('@SnxPayment:identity');
      await api.post('send_mail_barCode', {
        firstName: client.client.name,
        nameCard: client.client.name,
        cpfCnpj: client.client.cpf,
        cardNumber: client.client.numeroCartaoTruncado,
        clientId: client.client.id,
        expiryDate: invoice.dataVencimento,
        totalValue: parseFloat(invoice.valorTotal.replace('R$ ', '')).toFixed(
          2
        ),
        minimumValue: parseFloat(
          invoice.valorMinimo.replace('R$ ', '')
        ).toFixed(2),
        linhaDigitavel: codBarras || '',
        hashFatura: invoice.hashFatura,
        invoiceId: invoice.idFatura,
        identity,
        companyId,
        email: formattedEmail,
        lancamentos: invoice.lancamentos,
        productCode: client.product.codigoProduto,
        productName: client.product.desc_prod,
        status: invoice.status,
      });
      closeModal();

      notifySucces(`Sucesso ao enviar email para ${formattedEmail}`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      closeModal();
      notifyError(`Falha ao enviar email para ${formattedEmail}`);
      console.error(`Falha ao enviar email para ${formattedEmail}`, error.message);
    }
  }

  async function sendMailTicket() {
    const formattedEmail = mail || email;
    try {
      const companyName = getCompanyName();
      let clientName = client.client.name;
      clientName = clientName
        .split(" ")
        .map(subname => subname[0].toUpperCase() + subname.slice(1).toLowerCase())
        .join(" ");
      setLoading(true);
      await api.post('send_email_protocol', {
        ticket,
        email: formattedEmail,
        client_name: clientName,
        company_name: companyName[0].toUpperCase() + companyName.slice(1),
        client_id: client.client.id,
        product_code: client.product.codigoProduto,
        product_description: client.product.desc_prod,
      });
      closeModal();
      notifySucces(`Sucesso ao enviar email para ${formattedEmail}`);
    } catch (error) {
      notifyError(`Falha ao enviar email para ${formattedEmail}`);
    } finally {
      setLoading(false);
    }
  }

  async function sendMailInsurance() {
    const formattedEmail = mail || email;
    try {
      setLoading(true);
      await api.post('send_insurance_email', {
        codigo_adesao: insurance.codigoAdesao,
        client_id: client.client.id,
        client_name: client.client.name,
        email: formattedEmail,
        product_code: client.product.codigoProduto,
        product_description: client.product.desc_prod,
        companyName,
      });
      closeModal();
      notifySucces('Enviado com sucesso');
      api.post('timeline', {
        description: `Contrato de Seguro Enviado: email (Código Adesão: ${insurance.codigoAdesao})`,
        client_id: client.client.id,
      });
    } catch (error) {
      notifyError('Falha ao enviar email');
    } finally {
      setLoading(false);
    }
  }

  const clientEmail = client?.client?.email;
  useEffect(() => {
    if (clientEmail !== null) {
      setEmails(clientEmail);
      setEmail(clientEmail);
    }
  }, [clientEmail]);

  return (
    <Modal visible={open} title="Enviar Email" onCancel={handleGoBack}>
      {loading && (
        <S.NewLoadingModals>
          <LoadingElevaty width="40px" height="40px" duration="durFast" />
        </S.NewLoadingModals>
      )}
      <S.ModalContainer>
      <div className="send-mail-container">
          <span>Selecione o email </span>
          <Select
            disabled={disabledInput}
            value={email || "0"}
            onChange={handleSelectMail}
            >
            <Option value="0">Selecione</Option>
            <Option value={`${emails}`}>{`${emails}`}</Option>
          </Select>
          {hasPermissionToSendToUnregistered && (
            <>
              <Checkbox value={sendToUnregisteredMail} onChange={(e) => handleChangeSendToUnregistered(e.target.checked)}>Enviar para outro email</Checkbox>
              {sendToUnregisteredMail && (
                <>
                  <span>Digite o Email</span>
                  <AntInput
                  placeholder="exemplo@email.com"
                  value={mail}
                  onChange={handleChangeMail}
                  />
                </>
              )}
            </>
          )}
        </div>
      </S.ModalContainer>
      <S.ModalBody style={{ display: 'flex', justifyContent: 'end' }}>
        {(!loading && insurance && (
          <Button onClick={async () => await sendMailInsurance()}>
            Enviar
          </Button>
        )) ||
          (!loading && !ticket && (
            <Button onClick={async () => await sendMail()}>Enviar</Button>
          )) ||
          (!loading && ticket && (
            <Button onClick={async () => await sendMailTicket()}>Enviar</Button>
          ))}
      </S.ModalBody>
    </Modal>
  );
}

export default ModalSendMail;
