/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import React, { memo, useState, useCallback, useEffect, useRef } from 'react';

import { Tooltip, Dropdown, Menu } from 'antd';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { BiMenu } from 'react-icons/bi';
import { FaInfoCircle, FaPrint, FaRegPlusSquare , FaCreditCard } from "react-icons/fa";
import { GiCardExchange } from "react-icons/gi";
import { GrPowerReset } from 'react-icons/gr';
import { IoMdPaper } from 'react-icons/io';
import { MdOutlineVpnKey, MdOutlineFormatListNumbered, MdOutlineCancel, MdOutlineEdit, MdOutlineLock, MdOutlineLockOpen } from "react-icons/md"

import Can from "../../../../components/Can";
import CancelCardPlModal from "../../../../components/creditCard/modals/CancelPlModal"
import ChangeClientCardModal from "../../../../components/creditCard/modals/ChangeClientCardModal";
import ChangeLimitCardModal from '../../../../components/creditCard/modals/ChangeLimitCardModal';
import HistoryBlockModal from "../../../../components/creditCard/modals/HistoryBlockModal";
import HistoryLimitModal from '../../../../components/creditCard/modals/HistoryLimitModal';
import InformationCardModal from "../../../../components/creditCard/modals/InformationModal";
import ModalCopyCard from "../../../../components/creditCard/modals/ModalCopyCard"
import ModalPrint from '../../../../components/creditCard/modals/ModalPrint';
import RenewPassword from "../../../../components/creditCard/modals/renewPassword"
import { PrintPdf } from '../../../../components/CreditCardV2/printPdf';
import { usePermissions } from '../../../../Context/permissionContext';
import { useAuth } from "../../../../hooks/AuthProvider";
import { useClient } from "../../../../hooks/ClientProvider";
import useCompany from '../../../../hooks/useCompany';
import Api from "../../../../services/api";
import { vipagemService } from '../../../../services/vipagemService';
import { notifyError, notifySucces } from '../../../../utils/notifications';
import SetPassword from "./setPassword/index";
import * as Style from "./style";


pdfMake.vfs = pdfFonts.pdfMake.vfs;


const { Item } = Menu;

function CardMenu(card,
  isDependent,
  copyCard,
  reloadCards,
  decryptCardNumber,
) {

  const companyData = useCompany();
  const { user } = useAuth();
  const { permissions } = usePermissions();
  const { client, refreshClient } = useClient();

  const [isCopyModalVisible, setIsCopyModalVisible] = useState(false);
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);
  const [isAlterPwdModalVisible, setIsAlterPwdModalVisible] = useState(false);
  const [isCancelPlModalVisible, setCancelPlModalVisible] = useState(false);
  const [isHistoryBlockModalVisible, setHistoryBlockModalVisible] = useState(false);
  const [isEditLimitModalVisible, setIsEditLimitModalVisible] = useState(false);
  const [isInformationModalVisible, setIsInformationModalVisible] = useState(false);
  const [isHistoryLimitModalVisible, setIsHistoryLimitModalVisible] = useState(false);
  const [isPrintCardModalVisible, setIsPrintCardModalVisible] = useState(false);
  const [isOtherActionsOpen, setIsOtherActionsOpen] = useState(false);
  const [isChangeClientCardModalVisible, setIsChangeClientCardModalVisible] = useState(false);
  const [dataPrintPdf, setDataPrintPdf] = useState([]);
  const [blockTypes, setBlockTypes] = useState([])
  const historyBlockModalRef = useRef(null);
  const historyLimitModalRef = useRef(null);
  const [secondCopyErrorMessage, setSecondCopyErrorMessage] = useState("");
  const [changeCardErrorMessage, setChangeCardErrorMessage] = useState("");
  const [canSecondCopyExtraValidation, setCanSecondCopyExtraValidation] = useState(false)
  const [canChangeCardExtraValidation, setCanChangeCardExtraValidation] = useState(false)


  function executeExtraValidation(companyName, invalidCardIdStatuses, validationSetter) {
    if(!companyName) return;
    if(companyData) {
      const sanitizedCompanyName = companyName.toLowerCase().trim()
      const applyToRegex = /becker/;
      const shouldHaveExtraValidation = applyToRegex.test(sanitizedCompanyName) || localStorage.getItem("@SnxPayment:isPch");
      if(!shouldHaveExtraValidation) {
        validationSetter(true);
        return;
      }

      const currentCardIdStatus = card?.card?.idStatusCartao;
      if(currentCardIdStatus && invalidCardIdStatuses.includes(currentCardIdStatus)) {
        validationSetter(false);
        return;
      }

      validationSetter(true);
      return;
    }
    validationSetter(false);
  }

  useEffect(() => {
    executeExtraValidation(companyData?.name, [3, 6, 32, 35, 36, 37, 38, 39, 171, 172, 200], setCanSecondCopyExtraValidation)
    executeExtraValidation(companyData?.name, [4, 5, 8, 12, 201], setCanChangeCardExtraValidation);
  }, [companyData]);

  useEffect(() =>{
    if(canSecondCopyExtraValidation) setSecondCopyErrorMessage("");
    else setSecondCopyErrorMessage("Id de cartão inválido para a operação");
  }, [canSecondCopyExtraValidation])

  useEffect(() => {
    if(canChangeCardExtraValidation) setChangeCardErrorMessage("");
    else setChangeCardErrorMessage("Id de cartão inválido para a operação");
  }, [canChangeCardExtraValidation])


  const hasPermissionToChangePassword = permissions && permissions.includes("change_password");
  const hasPermissionToCancelCard = permissions && permissions.includes("cancel_card");
  const hasPermissionToSecondCopy = permissions && permissions.includes("second_via");
  const hasPermissionToViewHistory = permissions && permissions.includes("view_history");
  const hasPermissionToAlterCardPassword = permissions && permissions.includes("set_card_password");
  const hasPermissionToResetCardPassword = permissions && permissions.includes("change_password");
  const hasPermissionToEditLimit = permissions && permissions.includes("change_limit");
  const hasPermissionToViewCardDetails = permissions && permissions.includes("view_detail_card");
  const hasPermissionToPrintCard = permissions && permissions.includes("print_card");
  const hasPermissionToPurchaseTerm = permissions && permissions.includes("purchase_term");
  const hasPermissionToPurchaseTermForBlockedAccounts = permissions && permissions.includes("term_purchase_blocked_accounts");
  const hasPermissionToViping = permissions && permissions.includes("vipagem_bandeirar_conta");
  const hasPermissionToChangeClientCard = permissions && permissions.includes("change_client_card");

  const clientCanViping = !client?.client?.isProdutoBandeirado &&
  client?.client?.canMakeTransitionToPf &&
  client?.client?.transicaoBandeira === "I" &&
  client?.product?.codigoProduto !== -1;

  const clientCanIssuePurchaseTerm = ((client?.client?.statusCliente === "A" && hasPermissionToPurchaseTerm) || hasPermissionToPurchaseTermForBlockedAccounts)

  const produto = client ? client.product : null;
  const productID = client ? client?.product?.codigoProduto : null;
  const isProdutoBandeirado = card ? card?.isBandeirado : "erro";
  const cardData = { card }.card.card;
  const clientId = { client }.client.client.id
  const store = user?.store ? user?.store.split(",") : null;

  const { diaVencimento } = client.information;
  let { limitePrincipal } = client.information;
  limitePrincipal = limitePrincipal.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
  });
  const saldoDisponivel = client.information?.saldoDisponivel.toLocaleString(
    "pt-BR",
    { minimumFractionDigits: 2 }
  );
  const descProduct = client?.product?.desc_prod;
  const clientName = client.client?.name;
  const numeroCartaoTruncado = client.information?.numeroCartaoTruncado;

  const statusEmbossing = card?.card?.statusEmbossing;
  const isCardAlreadyPrinted = statusEmbossing ? statusEmbossing === "EMBOSSADO" : false;

  const toggleRenewPasswordModal = useCallback(() => {
    if (!hasPermissionToChangePassword) {
      return;
    }
    setIsResetModalVisible(prevState => !prevState);
  }, [hasPermissionToChangePassword]);

  const toggleCancelCardPlModal = useCallback((shouldGetData) => {
    if (!hasPermissionToCancelCard) {
      return;
    }
    setCancelPlModalVisible(prevState => !prevState);

    if (shouldGetData) {
      historyBlockModalRef.current?.getData();
    }
  }, [hasPermissionToCancelCard]);

  const toggleModalCopyCard = useCallback(() => {
    if (!hasPermissionToSecondCopy) {
      return;
    }
    setIsCopyModalVisible(prevState => !prevState);
  }, [hasPermissionToSecondCopy]);

  const toggleHistoryBlockModal = useCallback(() => {
    if (!hasPermissionToViewHistory) {
      return;
    }
    setHistoryBlockModalVisible(prevState => !prevState);
  }, [hasPermissionToViewHistory]);

  const toggleAlterPasswordModal = useCallback(() => {
    if (!hasPermissionToAlterCardPassword) {
      return
    }
    setIsAlterPwdModalVisible(prevState => !prevState);
  }, [hasPermissionToAlterCardPassword]);

  const toggleEditLimitModal = useCallback(() => {
    if (!hasPermissionToEditLimit) {
      return
    }
    setIsEditLimitModalVisible(prevState => !prevState);
  }, [hasPermissionToEditLimit]);

  const toggleInformationCardModal = useCallback(() => {
    if (!hasPermissionToViewCardDetails) {
      return;
    }
    setIsInformationModalVisible(prevState => !prevState);
  }, [hasPermissionToViewCardDetails]);

  const toggleHistoryLimitModal = useCallback((shouldGetData) => {
    setIsHistoryLimitModalVisible(prevState => !prevState);

    if (shouldGetData) {
      historyLimitModalRef.current?.getData();
    }
  }, []);

  const togglePrintCardModal = useCallback(() => {
    if (!hasPermissionToPrintCard || isCardAlreadyPrinted) {
      return;
    }
    setIsPrintCardModalVisible(prev => !prev);
  }, [hasPermissionToPrintCard, isCardAlreadyPrinted]);

  const handleToggleOtherActions = () => {
    setIsOtherActionsOpen(prev => !prev);
    card?.onExpandContentHeight?.();
  }

  const showPrintPdf = useCallback(async () => {
    if (!clientCanIssuePurchaseTerm) {
      return;
    }
    const numberCardEncrypt = client?.client?.information?.numeroCartaoCriptografado || client?.information?.numeroCartaoCriptografado;

    Api.post("get_purchase_term", {
      encryptedCardNumber: numberCardEncrypt,
      client_id: client.client.id,
    }).then(async (res) => {
      if(!res?.data?.cardNumber) throw new Error('Erro ao gerar termo de compra')
      const data = new Date();
      const dia = String(data.getDate()).padStart(2, "0");
      const mes = String(data.getMonth() + 1).padStart(2, "0");
      const ano = data.getFullYear();
      const dataAtual = `${dia}/${mes}/${ano}`;

      const classPrint = new PrintPdf([
        {
          nomeEmissor: "LOPES SUPERMERCADOS",
          CNPJ_Emissor: companyData?.cnpj,
          cartaoEmissor: `ADMINISTRADOR ${descProduct}`,
          nomeTitular: card?.card?.nome,
          numeroCartao: res?.data?.cardNumber?.toString().match(/.{4}/g).join(" "),
          opcaoVencimento: diaVencimento,
          Validade_ate: `Válido até: ${dataAtual}`,
          Validade: `São Paulo, ${dataAtual}`,
          LimiteCredito: `R$ ${limitePrincipal}`,
          LimiteRotativoDisponivel: `R$ ${saldoDisponivel}`,
        },
      ]);
      const document = await classPrint.MakeDoc();
      pdfMake.createPdf(document).open({}, window.open("", "_blank"));
    }).catch(function error(e) {
        notifyError(e.message);
    });
  }, [dataPrintPdf, client, card?.card, clientCanIssuePurchaseTerm, diaVencimento, limitePrincipal, saldoDisponivel]);

  const onBandeirarContaClick = async () => {
    if (!hasPermissionToViping) {
      return
    }
    try {
      await vipagemService.bandeirarConta(
        clientId,
        produto?.codigoProduto,
        decryptCardNumber || numeroCartaoTruncado
      );

      notifySucces("Sucesso ao bandeirar conta");

      await refreshClient();
      await reloadCards();
    } catch (e) {
      notifyError("Erro ao bandeirar conta");
    }
  };

  const getBlockTypes = useCallback(async () => {
    try {
      const resp = await Api.get("card_block_type", { isProdutoBandeirado });
      const { data } = resp;
      await setBlockTypes(data);
    } catch (error) {
      console.log("Falha ao listar tipos de bloqueios");
    }
  }, []);

  const printData = useCallback(async () => {
    const data = new Date();
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0");
    const ano = data.getFullYear();
    const dataAtual = `${dia}/${mes}/${ano}`;

    setDataPrintPdf([
      {
        nomeEmissor: "LOPES SUPERMERCADOS",
        CNPJ_Emissor: companyData?.cnpj,
        cartaoEmissor: `ADMINISTRADOR ${descProduct}`,

        nomeTitular: clientName,
        numeroCartao: card?.decryptCardNumber || numeroCartaoTruncado,
        opcaoVencimento: diaVencimento,
        Validade_ate: `Válido até: ${dataAtual}`,
        Validade: `São Paulo, ${dataAtual}`,
        LimiteCredito: `R$ ${limitePrincipal}`,
        LimiteRotativoDisponivel: `R$ ${saldoDisponivel}`,
      },
    ]);
  }, [
    descProduct,
    clientName,
    numeroCartaoTruncado,
    diaVencimento,
    limitePrincipal,
    saldoDisponivel,
    card?.decryptCardNumber,
  ]);

  useEffect(() => {
    try {
      getBlockTypes();

    } catch (erro) {
      console.error("erro ao listar tipos de bloqueio", erro);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        getBlockTypes();
      } catch (error) {
        console.error('Erro ao buscar dados', error);
      }
    };
    if (user && client) {
      fetchData()
    }
  }, [user, client]);

  useEffect(() => {
    printData();
  }, [])

  return (
    <>
      <ModalCopyCard
        isModalVisible={isCopyModalVisible}
        closeModal={toggleModalCopyCard}
        card={cardData}
        isDependent={card?.isDependent}
        isBandeirado={isProdutoBandeirado}
        copyCard={card?.copyCard}
        codProduct={produto.id}
        reloadCards={card?.reloadCards}
        client_id={clientId}
        blockTypes={blockTypes}
      />

      <RenewPassword
        // eslint-disable-next-line react/jsx-boolean-value
        isModalVisible={isResetModalVisible}
        closeModal={() => {
          setIsResetModalVisible(false);
        }}
        card={cardData}
        productID={productID}
        clientID={clientId}
      />
      <SetPassword
        isModalVisible={isAlterPwdModalVisible}
        closeModal={toggleAlterPasswordModal}
        productId={productID}
        cardId={cardData?.id}
        clientId={clientId}
      />

      <CancelCardPlModal
        isModalVisible={isCancelPlModalVisible}
        closeModal={toggleCancelCardPlModal}
        card={cardData}
        cancelTypes={blockTypes}
        productID={productID}
        clientID={clientId}
        isDependent={card?.isDependent}
        isBandeirado={isProdutoBandeirado}
        // eslint-disable-next-line react/destructuring-assignment
        reloadCards={card.reloadCards}
      />

      <HistoryBlockModal
        isModalVisible={isHistoryBlockModalVisible}
        closeModal={toggleHistoryBlockModal}
        card={cardData}
        ref={historyBlockModalRef}
      />

      <ChangeLimitCardModal
        isModalVisible={isEditLimitModalVisible}
        closeModal={toggleEditLimitModal}
        clientID={clientId}
        reloadCards={card?.reloadCards}
      />

      <InformationCardModal
        isModalVisible={isInformationModalVisible}
        closeModal={toggleInformationCardModal}
        card={card?.card}
      />

      <HistoryLimitModal
        isModalVisible={isHistoryLimitModalVisible}
        closeModal={() => toggleHistoryLimitModal(false)}
        ref={historyLimitModalRef}
      />

      <ModalPrint
        isModalVisible={isPrintCardModalVisible}
        closeModal={togglePrintCardModal}
        lojas={store}
        card={card?.card}
        client_id={clientId}
      />

      <ChangeClientCardModal
        isVisible={isChangeClientCardModalVisible}
        onCancel={() => setIsChangeClientCardModalVisible(false)}
        productId={productID}
        client_id={clientId}
        cardNumber={card.card.numeroMascarado}
        cardId={card.card.id}
      />

       <Style.Wrapper>
        <Style.WrapperCardMenu>
                <Style.MenuItem className="border-left" onClick={() => toggleModalCopyCard()} disabled={!hasPermissionToSecondCopy || !canSecondCopyExtraValidation}>
                    <FaRegPlusSquare className='icon' />
                    <Tooltip title={secondCopyErrorMessage || "Solicitar 2º Via"} placement="top">
                      2º Via
                    </Tooltip>
                </Style.MenuItem>
          <Style.MenuItem className="border-left" onClick={toggleRenewPasswordModal} disabled={!hasPermissionToResetCardPassword}>
            <GrPowerReset />
            Resetar senha
          </Style.MenuItem>
          <Tooltip title="Histórico (bloqueios)">
            <Style.MenuItem className="border-left" onClick={() => toggleHistoryBlockModal()} disabled={!hasPermissionToViewHistory}>
              <MdOutlineFormatListNumbered className='icon' />
              Histórico
            </Style.MenuItem>
          </Tooltip>
          <Style.MenuItem className="border-left" onClick={() => toggleCancelCardPlModal(true)} disabled={!hasPermissionToCancelCard}>
            <MdOutlineCancel className='icon' />
            Cancelar
          </Style.MenuItem>
          {isOtherActionsOpen && (
            <Tooltip title="Mais informações">
              <Style.MenuItem onClick={() => toggleInformationCardModal()} disabled={!hasPermissionToViewCardDetails}>
                <FaInfoCircle />
                Informações
              </Style.MenuItem>
          </Tooltip>
          )}
          {isOtherActionsOpen && (
            <Tooltip title="Histórico de limite">
              <Style.MenuItem onClick={() => toggleHistoryLimitModal(true)}>
                <MdOutlineFormatListNumbered />
                Limite
              </Style.MenuItem>
          </Tooltip>
          )}
          {isOtherActionsOpen && (isCardAlreadyPrinted ? (
              <Tooltip title="Este cartão já está impresso">
                <Style.MenuItem disabled>
                  <FaPrint />
                  Imprimir
                </Style.MenuItem>
              </Tooltip>
          ) : (
              <Style.MenuItem onClick={() => togglePrintCardModal()} disabled={!hasPermissionToPrintCard}>
                <FaPrint />
                Imprimir
              </Style.MenuItem>
          ))}
          {isOtherActionsOpen && hasPermissionToEditLimit && (
            <Style.MenuItem onClick={() => toggleEditLimitModal()}>
              <MdOutlineEdit />
              Alterar limite
            </Style.MenuItem>
          )}
          {isOtherActionsOpen && clientCanIssuePurchaseTerm && (
            <Tooltip title="Emitir termo de compra">
              <Style.MenuItem onClick={() => showPrintPdf()} disabled={!clientCanIssuePurchaseTerm}>
                <IoMdPaper />
                Termo de compra
              </Style.MenuItem>
            </Tooltip>
          )}
          {isOtherActionsOpen && clientCanViping && (
            <Tooltip title="Solicitar a transição para bandeira">
              <Style.MenuItem onClick={() => onBandeirarContaClick()} disabled={!hasPermissionToViping}>
                <FaCreditCard />
                Transição para bandeira
              </Style.MenuItem>
            </Tooltip>
          )}
          {isOtherActionsOpen && companyData?.has_change_password && (
            <Tooltip title="Alterar senha do cartão">
              <Style.MenuItem className="border-left" onClick={toggleAlterPasswordModal} disabled={!hasPermissionToAlterCardPassword}>
                <MdOutlineVpnKey className='icon' />
                Alterar senha
              </Style.MenuItem>
            </Tooltip>
          )}
          {isOtherActionsOpen && (
              <Style.MenuItem className="border-left" onClick={() => setIsChangeClientCardModalVisible(prev => !prev)} disabled={!hasPermissionToChangeClientCard || !canChangeCardExtraValidation}>
                <GiCardExchange className='icon' />
                <Tooltip title={changeCardErrorMessage|| "Realizar troca"} placement="top">
                  Realizar Troca
                </Tooltip>
              </Style.MenuItem>
          )}
        </Style.WrapperCardMenu>
        <Style.OtherActionsButton type="button" onClick={handleToggleOtherActions}>
          <BiMenu />
          {isOtherActionsOpen ? "Ver menos ações do cartão" : "Ver mais ações do cartão"}
        </Style.OtherActionsButton>
     </Style.Wrapper>
    </>
  );
}

export default memo(CardMenu);
